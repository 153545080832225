import React, { useState, useEffect,useContext,createContext } from "react";
import "./Header.css";
// import { useTheme } from "../../src/Theme";
import axios from "axios";
import "../Responsive/HeaderResponsive.css";

import { useNavigate } from "react-router-dom";
// import { RiNotification3Line } from "react-icons/ri";
import { FiMinimize, FiMaximize } from "react-icons/fi";
import { FaMoon } from 'react-icons/fa';
import { TbBrightnessDown } from 'react-icons/tb';

// import { IoSettingsOutline } from "react-icons/io5";
import API_URL from "../../src/Config";
// import { IoLogOutOutline } from "react-icons/io5";
import { ThemeContext } from '../../src/ThemeContext';
import { LuLogOut } from "react-icons/lu";

// const Header = (isSidebarExpanded) => {
  function Header({ isSidebarExpanded}) {
  const navigate = useNavigate();
  // const { theme } = useTheme();
  const [greeting, setgretting] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);
 

  const userName = localStorage.getItem("userName");

  function getInitials(userName) {
    const nameParts = userName.trim().split(" ");

    const titles = ["Mr.", "Miss", "Ms.", "Mrs."];

    const initials = nameParts
      .filter((part) => !titles.includes(part))
      .map((part) => part.charAt(0).toUpperCase())
      .slice(0, 2) // Take only the first 2 initials
      .join("");

    return initials;
  }

  const firstLetter = userName ? getInitials(userName) : "";

  console.log(firstLetter);

  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };

    setgretting(getGreeting()); // Set the greeting based on the current hour
  }, []);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen(); // Enter fullscreen
      setIsFullscreen(true);
    } else {
      document.exitFullscreen(); // Exit fullscreen
      setIsFullscreen(false);
    }
  };

  const handleLogout = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      // If authKey is present, make the logout request
      if (authKey) {
        await axios.post(
          `${API_URL}/api/logout`,
          {},
          { headers: { Authorization: authKey } }
        );
      }

      // Clear relevant local storage items
      localStorage.removeItem("authKey");
      localStorage.removeItem("userName");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("itemsPerPage");
      localStorage.removeItem("selectedStatus");
      localStorage.removeItem("selectedScheduleStatus");
      localStorage.removeItem("selectedFilter");
      // localStorage.removeItem('selectedFilter');
      localStorage.removeItem("company_id");
      localStorage.removeItem("email");
      localStorage.removeItem("menuItems");
      localStorage.removeItem("user_role");

      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally, show user feedback here
    }
  };

  return (
    <>
      {/* <div className="header"> */}
      <div
        className={`data-list-content-container page-content header ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
          <div className="headers">
        <div className="header-layout">
          <div className="header-wrapper">
            {/* <div className="heading-start">
              <img src="/new-logo251.png" alt="Logo" className="header-logo" />
            </div> */}
            {/* <div className="heading-centre"></div> */}
            <div className="heading-end">
              <div className="heading-end-first"></div>
              {/* <div className="heading-end-center">
                <div className="notification">
                  <RiNotification3Line className="notification-icon" />
                </div>
                <div className="setting">
                  <IoSettingsOutline className="setting-icon" />
                </div>
              </div> */}
              {/* <p>{`Current Theme: ${theme}`}</p> */}
              <div className="heading-end-last">
                <div className="username">
                  <span>
                    Hello, {greeting} {userName}
                  </span>
                </div>
                <div className="username-letter">
                  <div className="letter-background">
                    <div className="first-letter">{firstLetter}</div>
                  </div>
                </div>
                {/* <button className="logout" onClick={handleLogout}>
                  Logout &nbsp;
                  <IoLogOutOutline style={{ fontSize: "20px", color: "red" }} />
                </button> */}
                <div className="fullscreen-toggle" onClick={toggleFullscreen}>
                  {isFullscreen ? (
                    <FiMinimize className="fullscreen-icon" />
                  ) : (
                    <FiMaximize className="fullscreen-icon" />
                  )}
                </div>
                {/* <div className="theme-switchs" onClick={toggleTheme}>
                  {theme === "dark" ? (
                    <FaMoon className="icon moon" />
                  ) : (
                    <TbBrightnessDown className="icon sun" />
                  )}
                </div> */}
                <div className="log-out" >
                <div onClick={handleLogout} >
                   <LuLogOut className="log-out-icon" />
                </div>
              </div>
              </div>

              {/* {showProfilePopup && (
                <div className="header-profile-popup">
                  <ProfilePopup closePopup={closeProfilePopup} />
                </div>
              )} */}

             
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Header;
