import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form1 from "../ColdWorkPermit/Form1";
import Form2 from "../ColdWorkPermit/Form2";
import Form3 from "../ColdWorkPermit/Form3";

import { Tabs, Tab } from "@mui/material";

function ColdWorkPermitForm({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Extracting tab from URL query
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(
    tabFromUrl || "Work Permit Details" 
  );

  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
          <div className="data-list-content" ref={containerRef}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(event, newValue)}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab label="Work Permit Details" value="Work Permit Details" />
              <Tab label="Checklist Items" value="Checklist Items" />
              <Tab label="Hazards & Precautions" value="Hazards Precautions" />
              
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
              {tabValue === "Work Permit Details" && <Form1 />}
              {tabValue === "Checklist Items" && <Form2 />}
              {tabValue === "Hazards Precautions" && <Form3 />}
            
            </div>
          </div>
      </div>

    </>
  );
}

export default ColdWorkPermitForm;
