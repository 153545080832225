import React from "react";
import "./Template1.css";
import "./Template4.css";
import { useParams } from "react-router-dom";

const Template6 = ({ signatures, formData, workDetails}) => {
  const { formId } = useParams();
  console.log("ID from URL:", formId);
  console.log("formData in Template6:", formData);

  const getSignatureByType = (type) =>
    signatures.find((signature) => signature.type === type) || {};

  const issuer = getSignatureByType("issuer");
  // const receiver = getSignatureByType("receiver");
  const safetyOfficer = getSignatureByType("fire & safety");
  return (
    <>
      <div className="template-container">
        <div className="template-container-content">
          <div className="template-container-header">
            <div className="cold-work-table">
              <table className="cold-work-tables">
                <thead>
                  <tr>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6 "
                    >
                      जारीकर्ता / Issuer
                    </th>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6"
                    >
                      प्राप्तकर्ता / Receiver
                    </th>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6"
                    >
                      सुरक्षा आधिकारी / Safety Officer
                    </th>
                  </tr>
                  <tr>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br />
                      Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                      नाम <br /> Name{" "}
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className="permit-row">
                    <td className="permit-center-align">{signatures.name || "NA"}</td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">{signatures.name || "NA"}</td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                  </tr> */}

                  <tr className="permit-row">
                    <td className="permit-center-align">
                      {issuer.name || "NA"} <br />({issuer.designation || "NA"})
                    </td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">
                    {workDetails.receiver_name || "NA"}
                    </td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">
                      {safetyOfficer.name || "NA"}
                    </td>
                    <td className="permit-center-align"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template6;
