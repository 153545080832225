import React, { useState, useEffect, useRef } from "react";
import "./ColdWorkPermit.css";
import "./ColdWorkPermitForm.css";
import { Link } from "react-router-dom";
import { HiOutlinePlus } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import html2canvas from "html2canvas";
import ColdWorkPermitTemplate from "./ColdWorkPermitTemplate";

function ColdWorkPermitRequestTab({
  isSidebarExpanded,
  designationID,
  status,
  updateNewCounts,
  updateTotalItems,
}) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage, status) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/requester/get_work_permit_requests`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            status: status, // Pass status here
          },
        }
      );
      console.log("Fetched totalItems:", response.data.totalItems);
      setDesignations(response.data.permit_details);
      setTotalItems(response.data.totalItems);
      updateTotalItems(response.data.tabCounts);
      updateNewCounts(response.data.newCounts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage, status);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        if (status) {
          fetchData(page, itemsPerPage, status);
        }
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage, status]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  // const handleDownloadPdf = async (formId) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.get(`${API_URL}/api/get_permit_details`, {
  //       headers: { Authorization: authKey },
  //       params: { work_permit_id: formId },
  //     });

  //     if (response.status === 200) {
  //       const permitDetails = response.data;

  //       // Create a temporary div to render the template
  //       const tempDiv = document.createElement("div");
  //       document.body.appendChild(tempDiv);

  //       ReactDOM.render(
  //         <ColdWorkPermitTemplate
  //           permitDetails={permitDetails}
  //           isSidebarExpanded={isSidebarExpanded}
  //         />,
  //         tempDiv
  //       );

  //       // Generate PDF from the rendered content
  //       const section1 = tempDiv.querySelector("#section1");
  //       const section2 = tempDiv.querySelector("#section2");

  //       if (!section1 || !section2) {
  //         console.error("PDF sections not found.");
  //         return;
  //       }

  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const options = { scale: 2, useCORS: true, backgroundColor: "yellow" };

  //       // Render Section 1
  //       const canvas1 = await html2canvas(section1, options);
  //       const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
  //       const imgWidth1 = 190; // A4 width minus padding
  //       const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

  //       pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

  //       // Add Section 2
  //       pdf.addPage();
  //       const canvas2 = await html2canvas(section2, options);
  //       const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
  //       const imgWidth2 = 190;
  //       const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

  //       pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);
  //       pdf.save(`Cold_Work_Permit_${formId}.pdf`);

  //       // Clean up the temporary div
  //       ReactDOM.unmountComponentAtNode(tempDiv);
  //       document.body.removeChild(tempDiv);
  //     } else {
  //       console.error("Failed to fetch permit details:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error generating PDF:", error.message);
  //   }
  // };

  const handleDownloadPdf = async (formId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_permit_details`, {
        headers: { Authorization: authKey },
        params: { work_permit_id: formId },
      });

      if (response.status === 200) {
        const permitDetails = response.data;

        // Create a temporary div to render the template
        const tempDiv = document.createElement("div");
        document.body.appendChild(tempDiv);

        ReactDOM.render(
          <ColdWorkPermitTemplate
            permitDetails={permitDetails}
            isSidebarExpanded={isSidebarExpanded}
          />,
          tempDiv
        );

        // Generate PDF from the rendered content
        const section1 = tempDiv.querySelector("#section1");
        const section2 = tempDiv.querySelector("#section2");

        if (!section1 || !section2) {
          console.error("PDF sections not found.");
          return;
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const options = {
          scale: 2,
          useCORS: true,
          backgroundColor: "yellow",
          windowWidth: 1550,
        };

        // Render Section 1
        const canvas1 = await html2canvas(section1, options);
        const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
        const imgWidth1 = 190; // A4 width minus padding
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

        pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

        // Add Section 2
        pdf.addPage();
        const canvas2 = await html2canvas(section2, options);
        const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
        const imgWidth2 = 190;
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

        pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);
        pdf.save(`Cold_Work_Permit_${formId}.pdf`);

        // Clean up the temporary div
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      } else {
        console.error("Failed to fetch permit details:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error.message);
    }
  };

  const handleExtension = (work_permit_id, index) => {
    navigate(`/form4/${work_permit_id}`);
  };
  const handleClosePermit = (work_permit_id, index) => {
    navigate(`/form5/${work_permit_id}`);
  };

  const handleEditClick = (work_permit_id, index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
    navigate(`/edit-permit-request-form/${work_permit_id}`);
  };

  const handleEditExtensionClick = (work_permit_id, index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
    navigate(`/edit-extension/${work_permit_id}`);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage, status);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleApproveExtension = (workPermitId) => {
    navigate(`/view-extension/${workPermitId}`);
  };

  const handleSaveClick = async (work_permit_id) => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Create payload with only work_permit_id and status
      const payload = {
        permit_id: work_permit_id,
        status: "Submitted", // Hardcoded to "Submitted" for this case
      };

      // API call
      const response = await axios.put(
        `${API_URL}/api/update_status`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar(`Permit Request sent successfully.`);

        // Refetch data after successful update
        await fetchData(page, itemsPerPage, status);

        // Optionally navigate if needed
        // navigate(`/permit-requests`);
      } else {
        throw new Error(
          response.data.message || "Failed to update permit status."
        );
      }
    } catch (error) {
      console.error("Error updating permit status:", error);
      setSeverity("error");
      showSnackbar("An error occurred while updating permit status.");
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };

  const handleGenratePermit = (id) => {
    console.log("ID:", id); // Log the ID
    navigate(`/cold-work-permit-inspection-view/${id}`); // Pass the ID as a route parameter
  };

  // Button logic

  return (
    <>
      <div className="data-list-content" ref={containerRef}>
        {isChecked ? (
          <div className="action-header">
            <div className="action-header-content">
              <div className="action-header-content-leftside">
                <div className="action-button">
                  <button className="delete" onClick={handleDeletePopup}>
                    Move to archive
                  </button>
                  {isDeletePopup && (
                    <div className="confirm-delete-popup">
                      <div className="confirm-delete-popup-content">
                        <div className="confirm-model">
                          <div className="confirm-model-content">
                            <div className="confirm-model-content-body">
                              <p>Are you sure want to move archive?</p>
                            </div>
                            <div className="confirm-model-content-footer">
                              <button
                                className="confirm-delete-button"
                                // onClick={handleArchivedDesignation}
                              >
                                Yes
                              </button>
                              <button
                                className="confirm-cancel-button"
                                onClick={handleCancel}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="action-header-content-rightside">
                <button
                  className="action-header-close-button"
                  onClick={handleCloseButtonClick}
                >
                  <IoCloseOutline className="action-header-close-icon" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="data-list-header">
            <div className="data-list-header-content">
              <div className="data-list-heading-content-left-side">
                All Work Permit Requests
              </div>

              <div className="data-list-heading-content-right-side">
                {/* <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div> */}

                <Link to="/permit-request-form" className="data-list-new">
                  <Tooltip title="New" arrow>
                    <button className="new-button">
                      <HiOutlinePlus className="new-plus-icon" /> New Permit
                      Request
                    </button>
                  </Tooltip>
                </Link>

                <Tooltip title="Refresh List" arrow>
                  <button className="refresh-button" onClick={handleRefresh}>
                    <TbRefresh className="refresh-icon" />
                  </button>
                </Tooltip>
                <button
                  className="sort-button"
                  onClick={handleSortpopup}
                  ref={buttonRef}
                >
                  <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                </button>
                {/* {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("designation_name")}
                          >
                            Designations{" "}
                            {sortOrders.designation_name === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDesignation}
                        >
                          Archived Designations
                        </button>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        )}
        <div className="data-list-body" onClick={closePopups}>
          <div className="data-list-scroll-bodys">
            <div className="data-list-table-report">
              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Loading...</div>
                </div>
              ) : designations.length > 0 ? (
                <table className="table multilevel-data">
                  <thead>
                    <tr>
                      {/* <th className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th> */}
                      {/* <th className="center-alignment"></th> */}
                      <th className="center-alignment">SR. NO.</th>
                      <th className="center-alignment">REQUEST ID</th>
                      <th>PERMIT TYPE</th>
                      <th>RECEIVER NAME</th>
                      <th>STATUS</th>
                      <th>SECTION</th>
                      <th>UNIT</th>
                      <th>AREA</th>
                      <th className="center-alignment">ACTION</th>

                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody className="data-list-scroll-bodys">
                    {designations.map((designation, index) => (
                      <tr key={designation.id} className="hoverable-row">
                        {/* <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(designation.id)}
                                onChange={() => handleSelectRow(designation.id)}
                              />
                            </td> */}
                        {/* <td className="center-alignment">
                          <div className="icon-container">
                            <span
                              className={
                                designation.is_new_row === "1"
                                  ? "new-badge blinking-new-badge"
                                  : "new-badge"
                              }
                            >   
                            </span>
                          </div>
                        </td> */}

                        <td className="center-alignment">{index + 1}</td>
                        <td
                          className="center-alignment"
                          style={{ color: "red" }}
                        >
                          {designation.request_id || "NA"}
                        </td>
                        <td>{designation.work_permit_name || "NA"}</td>
                        <td>{designation.receiver_name || "NA"}</td>
                        <td
                          style={{
                            color:
                              designation.status === "Draft"
                                ? "gray"
                                : designation.status === "Submitted"
                                ? "orange"
                                : designation.status === "In Review"
                                ? "#8f7a13"
                                : designation.status === "Pending Approval"
                                ? "#8f7a13"
                                : designation.status === "Approved"
                                ? "blue"
                                : designation.status === "Issued"
                                ? "green"
                                : designation.status === "Rejected"
                                ? "red"
                                : "inherit", // Default color if status doesn't match
                            fontWeight: "bold", // Make the text bold
                          }}
                        >
                          {designation.status || "NA"}
                        </td>

                        <td>{designation.section || "NA"}</td>
                        <td>{designation.unit || "NA"}</td>
                        <td>{designation.area || "NA"}</td>
                        {/* <td>{designation.status || "NA"}</td> */}

                        {/* <td>
                          <button
                            className={`request-button ${
                              designation.status !== "Draft" ? "disabled" : ""
                            }`}
                            type="button"
                            onClick={() =>
                              handleSaveClick(designation.work_permit_id)
                            }
                            disabled={
                              isLoading || designation.status !== "Draft"
                            } // Disable if loading or status is not "Draft"
                          >
                            {isLoading ? "Sending Request..." : "Send Request"}
                          </button>
                        </td> */}

                        <td className="center-alignment edit-req-but">
                          <button
                            className={`request-buttons ${
                              designation.status !== "Draft" ? "disabled" : ""
                            }`}
                            type="button"
                            onClick={() =>
                              handleSaveClick(designation.work_permit_id)
                            }
                            disabled={
                              isLoading || designation.status !== "Draft"
                            } // Disable if loading or status is not "Draft"
                          >
                            {isLoading ? "Sending Request..." : "Send Request"}
                          </button>
                          {(designation?.status === "Issued" ||
                          designation?.status === "Closed" ||
                          designation?.status === "Close Requested" ||
                            designation?.status === "Renewed") && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleDownloadPdf(designation.work_permit_id)
                              }
                            >
                              Download
                            </button>
                          )}

                          {(designation?.status === "Issued" ||
                            designation?.status === "Renewed") && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleExtension(designation.work_permit_id)
                              }
                            >
                              Extension Permit
                            </button>
                          )}

                          {designation.status === "Renewed" && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleGenratePermit(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )}

                          {(designation?.status === "Submitted" ||
                            designation?.status === "Draft" ||
                            designation?.status === "In Review") && (
                            <button
                              className="edit-button-inquiry12"
                              onClick={() =>
                                handleEditClick(
                                  designation.work_permit_id,
                                  index
                                )
                              }
                            >
                              <MdOutlineModeEditOutline className="edit-show-icon" />{" "}
                              Edit
                            </button>
                          )}
                          {/* {(designation?.status === "Extension Requested" ) && (
                            <button
                              className="edit-button-inquiry12"
                              onClick={() =>
                                handleEditExtensionClick(
                                  designation.work_permit_id,
                                  index
                                )
                              }
                            >
                              <MdOutlineModeEditOutline className="edit-show-icon" />{" "}
                              Edit
                            </button>
                          )} */}

                          {designation.status === "Extension Requested" && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleApproveExtension(
                                  designation.work_permit_id,
                                  designation.status
                                )
                              }
                            >
                              View Extension Request
                            </button>
                          )}
  {designation.status === "Issued" && (
                          <button
                            className="new-button-download"
                            onClick={() =>
                              handleClosePermit(
                                designation.work_permit_id,
                                designation.status
                              )
                            }
                          >
                            Close Permit
                          </button>
  )}
                        </td>

                        {/* <td>
                          <button
                            className="edit-button-inquiry12"
                            onClick={() =>
                              handleEditClick(designation.work_permit_id, index)
                            }
                          >
                            <MdOutlineModeEditOutline className="edit-show-icon" />{" "}
                            Edit
                          </button>
                        </td> */}

                        {/* {selectedRowIndex === index && (
                          <div className="edit-popup">
                            <div className="edit-popup-contant">
                              <div className="edit-popup-button">
                                <Link
                                  to={`/edit-permit-request-form/${designation.work_permit_id}`}
                                  className="inquiry-new"
                                >
                                  <button className="edit-button-inquiry">
                                    <MdOutlineModeEditOutline /> Edit
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-users-container">
                  <div className="no-users-message">No Data Found</div>
                </div>
              )}
            </div>
            <Pagination
              showPerPage={itemsPerPage}
              total={totalItems}
              onPaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ColdWorkPermitRequestTab;
