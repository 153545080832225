import React, { useState, useRef, useEffect } from "react";
import "./UserForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import useClickOutside from "../useClickOutside";

function LibraryDocumentsForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const popupRef = useRef(null)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const { id } = useParams();
  const [departmentData, setDepartmentData] = useState({
    doc_name: "",
    attachments: null,
  });

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_product_library_type/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        // Access the 'library_name' field in the response
        const responseData = response.data.product_library_type;
        const formattedData = {
          library_name: responseData.library_name,
          library_type_id: responseData.id,
        };

        setDepartmentData(formattedData);
      } catch (error) {
        console.error("Error fetching department data:", error.message);
      }
    };

    fetchDepartmentData();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };


  const handleClosePopup = () => {
    // setIsSortPopup(false);
    setIsPopupOpen(false);
  };

  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);


  const handleCancelClick = () => {
    navigate(`/library-documents/${id}`);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;

    // Validate that required fields are not empty
    Object.keys(departmentData).forEach((key) => {
      if (!departmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });

    if (error) {
      showSnackbar("Required fields are empty");
      return; // Prevent further execution if there are validation errors
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const formData = new FormData();
      formData.append("doc_name", departmentData.doc_name);
      formData.append("library_type_id", departmentData.library_type_id);
      formData.append("library_name", departmentData.library_name);

      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }

      const response = await axios.post(
        `${API_URL}/api/add_product_library`,
        formData, // Send departmentData directly
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Category Document added successfully");
        setTimeout(() => {
          navigate(`/library-documents/${id}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving category document:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };
  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Category Document
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="library_name">
                    Category<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="library_name"
                      value={departmentData.library_name}
                      onChange={handleInputChange}
                      placeholder="Enter category"
                      readOnly
                    />
                    {errors.DepartmentName && (
                      <div className="error-message">
                        {errors.DepartmentName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="doc_name">
                    Document Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="doc_name"
                      value={departmentData.doc_name}
                      onChange={handleInputChange}
                      placeholder="Enter document name"
                    />
                    {errors.doc_name && (
                      <div className="error-message">{errors.doc_name}</div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="attachement">
                    Upload Document{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                    ref={buttonRef}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={popupRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default LibraryDocumentsForm;
