import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";

function ArchivedAllAmcSchedules({ isSidebarExpanded, departmentID }) {

  const [AllamcSchedule, setAllamcSchedule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const containerRef = useRef(null);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_archived_amc`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setAllamcSchedule(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_customers`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setAllamcSchedule(response.data.Customers);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll
        ? AllamcSchedule.map((subDepartment) => subDepartment.id)
        : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (subDepartmentID) => {
    const updatedSelectedRows = selectedRows.includes(subDepartmentID)
      ? selectedRows.filter((id) => id !== subDepartmentID)
      : [...selectedRows, subDepartmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === AllamcSchedule.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleRestore = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/unarchive_amc_config`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Sub departments restore successfully");
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-sub-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleBackCompany = () => {
    navigate("/amc-configuration");
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                  <button onClick={handleRestore} className="delete">Unarchive</button>
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    <Tooltip title="Back" arrow>
                      {" "}
                      <IoMdArrowBack
                        className="back-company"
                        onClick={handleBackCompany}
                      />{" "}
                    </Tooltip>
                    Archived All Amc Schedule
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : AllamcSchedule.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment">SR. NO.</th>
                          <th>DEPARTMENT</th>
                          <th>NAME</th>
                          <th>SERVICE PROVIDER</th>
                          {/* <th>USER</th> */}
                          <th className="center-alignment">STATUS</th>
                          <th className="center-alignment">START DATE</th>
                          {/* <th>SCHEDULE START DATE</th> */}
                          <th className="center-alignment">END DATE</th>
                          <th>PO NUMBER</th>
                          <th>PO DATE</th>
                          {/* <th>DAYS BEFORE</th>
                          <th>REMINDER FREQUENCY</th>
                          <th>NO. OF PREVENTIVE VISITS</th>
                          <th>NO. OF BREAKDOWN VISITS</th>
                          <th>SERVICE DESCRIPTION</th> */}
                          {/* <th>ACTION</th>
                           */}

                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {AllamcSchedule.map((amc, index) => (
                          <tr key={amc.id} className="hoverable-row">
                            <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(amc.id)}
                                onChange={() => handleSelectRow(amc.id)}
                              />
                            </td>

                            <td className="center-alignment">{amc.serial_no}</td>
                            {/* <td>{amc.department_name || "NA"}</td> */}
                            {/* <td
                              className="view-data"
                              onClick={() => togglePopup(amc.id)}
                            >
                              {amc.department_name || "NA"}
                            </td> */}
                            <td className="view-data">{amc.department_name ||"NA"}</td>
                            <td>{amc.amc_name || "NA"}</td>
                            <td>{amc.service_provider_name || "NA"}</td>
                            {/* <td>{amc.user_name || "NA"}</td> */}
                            {/* <td>{amc.amc_status || "NA"}</td> */}
                            <td className="status-schedules center-alignment">
                              <div
                                style={{
                                  backgroundColor:
                                    amc.amc_status === "Active"
                                      ? "#2980B9"
                                      : amc.amc_status === "Expired"
                                      ? "#F81700"
                                      : "transparent",
                                  width: "6vw",
                                }}
                              >
                                {amc.amc_status}
                              </div>
                            </td>
                            <td className="center-alignment">{formatDate(amc.start_date)}</td>
                            {/* <td>{formatDate(amc.schedule_start_date)}</td> */}
                            <td className="center-alignment">{formatDate(amc.end_date)}</td>
                            <td className="ponumber-th">{amc.po_no || "NA"}</td>
                            <td className="center-alignment">{formatDate(amc.po_date)}</td>
                            {/* <td>{amc.days_before || "NA"}</td>
                            <td>{amc.reminder_frequency || "NA"}</td>
                            <td>{amc.preventive_no || "NA"}</td>
                            <td>{amc.breakdown_no || "NA"}</td>
                            <td>{amc.services || "NA"}</td> */}
                            

                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No All Amc Schedule Found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <Pagination
          showPerPage={itemsPerPage}
          total={totalItems}
          onPaginationChange={handlePaginationChange}
        />
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ArchivedAllAmcSchedules;
