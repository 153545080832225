import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import FrequencyConfigView from "../Views/FrequencyConfigView";
import Pagination from "../Component/Pagination";
function ArchivedFrequency({ isSidebarExpanded, departmentID }) {
  const [frequency, setFrequency] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [popupUserID, setPopupUserID] = useState(null);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const containerRef = useRef(null);

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };
  const togglePopup = (frequencyID) => {
    setPopupUserID(frequencyID);
    setIsMainContainerVisible(true);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_archived_frequency_configs`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setFrequency(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
      setLoading(false);
    }
  };
  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_customers`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setFrequency(response.data.Customers);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? frequency.map((subDepartment) => subDepartment.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (subDepartmentID) => {
    const updatedSelectedRows = selectedRows.includes(subDepartmentID)
      ? selectedRows.filter((id) => id !== subDepartmentID)
      : [...selectedRows, subDepartmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === frequency.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleRestore = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/unarchive_all_frequency_config`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Frequency restore successfully");
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-sub-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleBackCompany = () => {
    navigate("/frequency");
  };
  const handleCloseUserView = () => {
    setIsMainContainerVisible(false); // Close the user view
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                  <button onClick={handleRestore} className="delete">Unarchive</button>
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    <Tooltip title="Back" arrow>
                      {" "}
                      <IoMdArrowBack
                        className="back-company"
                        onClick={handleBackCompany}
                      />{" "}
                    </Tooltip>
                    Archived Frequency
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : frequency.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>EQUIPMENT CONFIGURATION</th>
                          <th>OISD NO</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {frequency.map((subDepartment, index) => (
                          <tr key={subDepartment.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(
                                  subDepartment.id
                                )}
                                onChange={() =>
                                  handleSelectRow(subDepartment.id)
                                }
                              />
                            </td>

                            <td>{index + 1}</td>
                            <td
                              className="view-data config"
                              // onClick={() => togglePopup(frequency.id)}
                            >
                              {`${subDepartment.DepartmentName || ""}${
                                subDepartment.DepartmentName &&
                                subDepartment.SubDepartmentName
                                  ? " >> "
                                  : ""
                              }${subDepartment.SubDepartmentName || ""}${
                                (subDepartment.DepartmentName ||
                                  subDepartment.SubDepartmentName) &&
                                subDepartment.EquipmentType
                                  ? " >> "
                                  : ""
                              }${subDepartment.EquipmentType || ""}${
                                (subDepartment.DepartmentName ||
                                  subDepartment.SubDepartmentName ||
                                  subDepartment.EquipmentType) &&
                                subDepartment.EquipmentSubType
                                  ? " >> "
                                  : ""
                              }${subDepartment.EquipmentSubType || ""}${
                                (subDepartment.DepartmentName ||
                                  subDepartment.SubDepartmentName ||
                                  subDepartment.EquipmentType ||
                                  subDepartment.EquipmentSubType) &&
                                subDepartment.VariantName
                                  ? " >> "
                                  : ""
                              }${subDepartment.VariantName || ""}`}
                            </td>
                            <td className="config center-alignment">
                              {subDepartment.Frequencies &&
                              subDepartment.Frequencies.length > 0
                                ? subDepartment.Frequencies[0].oisd_no
                                : "NA"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Archived Frequency Found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <Pagination
          showPerPage={itemsPerPage}
          total={totalItems}
          onPaginationChange={handlePaginationChange}
        />

        {isMainContainerVisible && (
          <div className="main-containers">
            <FrequencyConfigView
              frequencyID={popupUserID}
              onClose={handleCloseUserView}
            />
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ArchivedFrequency;
