import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import API_URL from "../../src/Config";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import Snackbar from "@mui/material/Snackbar"; // Ensure you import Snackbar
import "./UserAccessTable.css";

const MenuItem = ({ item, level, onToggle, expanded, checked, onCheck }) => {
  const hasSubItems = item.sub_menu && item.sub_menu.length > 0;
  const isExpanded = expanded[item.id];
  return (
    <>
      <tr className="table-row">
        <td className="table-cell" style={{ paddingLeft: `${level * 20}px` }}>
          <div className="menu-item">
            <input
              type="checkbox"
              id={item.id}
              checked={checked[item.id] || false}
              onChange={(e) => {
                console.log(item.id, "checking");
                onCheck(item.id, e.target.checked, item); // Pass the item to handle submenus
              }}
              className="checkbox"
            />
            {hasSubItems && (
              <button onClick={() => onToggle(item.id)} className="toggle-btn">
                {isExpanded ? <FiChevronDown /> : <FiChevronRight />}
              </button>
            )}
            <label htmlFor={item.id} className="menu-label">
              {item.name}
            </label>
          </div>
        </td>
      </tr>
      {hasSubItems &&
        isExpanded &&
        item.sub_menu.map((subItem) => (
          <MenuItem
            key={subItem.id}
            item={subItem}
            level={level + 1}
            onToggle={onToggle}
            expanded={expanded}
            checked={checked}
            onCheck={onCheck}
          />
        ))}
    </>
  );
};

function UserAccessTable({ isSidebarExpanded }) {
  const [menuItems, setMenuItems] = useState([]); // All menu items
  const [accessMenuItems, setAccessMenuItems] = useState([]); // Accessible menu items
  const [expanded, setExpanded] = useState({});
  const [checked, setChecked] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { ID } = useParams(); // Role ID
  const navigate = useNavigate();

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_menu_items`, {
        headers: { Authorization: authKey },
      });
      setMenuItems(response.data);
    } catch (error) {
      console.error("Error fetching all menu items:", error.message);
      setError(error.message);
    }
  };

  const fetchAccessRoles = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_menu_items_access/${ID}`,
        {
          headers: { Authorization: authKey },
        }
      );
      setAccessMenuItems(response.data);

      // Pre-check items based on access roles
      const preChecked = {};
      response.data.forEach((item) => {
        preChecked[item.id] = true;
        if (item.sub_menu) {
          item.sub_menu.forEach((subItem) => {
            preChecked[subItem.id] = true;
          });
        }
      });
      setChecked(preChecked);
    } catch (error) {
      console.error("Error fetching access roles:", error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
    if (ID) fetchAccessRoles();
    setLoading(false);
  }, [ID]);

  const handleToggle = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleCheck = (id, isChecked, item) => {
    setChecked((prev) => {
      const updatedChecked = { ...prev, [id]: isChecked };

      // If the item has submenus, update the checked state of all submenus
      if (item.sub_menu) {
        item.sub_menu.forEach((subItem) => {
          updatedChecked[subItem.id] = isChecked;
        });
      }

      return updatedChecked;
    });
  };

  const handleSave = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const selectedItems = Object.keys(checked).filter((key) => checked[key]);

      await axios.put(
        `${API_URL}/api/update_menu_access/${ID}`,
        { menu_item_ids: selectedItems },
        { headers: { Authorization: authKey } }
      );

      setSeverity("success");
      showSnackbar("Roles saved successfully");
      setTimeout(() => {
        navigate("/user");
      }, 1000);
    } catch (error) {
      console.error("Error updating access roles:", error.message);
      setError(error.message);
    }
  };

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              User Access Table
            </div>
          </div>
        </div>

        <div className="data-list-body">
          <div className="data-list-scroll-body">
            <div className="data-list-table">
              <table className="table multilevel-data">
                <thead>
                  <tr>
                    <th className="table-header">Menu Items</th>
                  </tr>
                </thead>
                <tbody className="data-list-scroll-bodys">
                  {menuItems.map((item) => (
                    <MenuItem
                      key={item.id}
                      item={item}
                      level={0}
                      onToggle={handleToggle}
                      expanded={expanded}
                      checked={checked}
                      onCheck={handleCheck}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <footer className="user-access-footer">
          <div className="footer-buttons-container">
            <button onClick={handleSave} className="save-btn">
              Save Roles
            </button>
            <button
              className="cancel-btn"
              // onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </footer>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <div>{snackbarMessage}</div>
        </Snackbar>
      </div>
    </>
  );
}

export default UserAccessTable;
