import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function Form5({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { work_permit_id } = useParams();

  const [workStatus, setWorkStatus] = useState({
    completionStatus: 0, // For "Completed"
    stoppageStatus: 0,   // For "Stopped"
    areaCleanedStatus: 0, // For "Area Clean"
    work_permit_id: "",      // For Work Permit ID
  });
  
  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setWorkStatus((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0, // Send 1 when checked, 0 when unchecked
    }));
  };
  
  
  const handleCancelClick = () => {
    navigate("/permit-requests");
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const payload = {
        work_permit_id: work_permit_id, // Pass the Work Permit ID
        completion_status: workStatus.completionStatus, // Pass "Completed" status (1 or 0)
        stoppage_status: workStatus.stoppageStatus, // Pass "Stopped" status (1 or 0)
        area_cleaned_status: workStatus.areaCleanedStatus, // Pass "Area Clean" status (1 or 0)
      };
  
      const response = await axios.post(
        `${API_URL}/api/request_permit_closure`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 201) {
        setSeverity("success");
        showSnackbar("Form added successfully");
        setTimeout(() => {
          navigate("/permit-requests");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving form:", error.message);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message || "An error occurred while saving the form"
      );
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Close Work Permit
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="work_status">
                    Work Status <span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    {/* <div className="form-check"> */}
                    <input
                      // className="form-check-input"
                      type="checkbox"
                      name="completionStatus"
                      id="workCompleted"
                      checked={workStatus.completionStatus}
                      onChange={handleCheckboxChange}
                    />
                    <label  htmlFor="workCompleted">
                      Completed
                    </label>

                    </div>
                    {/* </div> */}
                    {/* <div className="form-check"> */}
                    <div className="checkbox-input">
                    <input
          className="form-check-input"
          type="checkbox"
          name="stoppageStatus"
          id="workStopped"
          checked={workStatus.stoppageStatus}
          onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="workStopped">
                      Stopped
                    </label>
                    </div>
                    <div className="checkbox-input">
                    <input
          className="form-check-input"
          type="checkbox"
          name="areaCleanedStatus"
          id="areaClean"
          checked={workStatus.areaCleanedStatus}
          onChange={handleCheckboxChange}
        />
                    <label className="form-check-label" htmlFor="areaClean">
                      AreaClean
                    </label>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Form5;
