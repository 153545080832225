import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ColdWorkPermitForm.css";
import "./ColdWorkPermitInspectionView.css";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";

function Form1({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { work_permit_id } = useParams();
  const { id } = useParams();
  console.log("Fetched ID:", work_permit_id);
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [workDetails, setWorkDetails] = useState({});
  const [clearanceRenewal, setClearanceRenewal] = useState({});
  const [permitDetails, setPermitDetails] = useState(null);
  const [checklist, setChecklist] = useState([]);
  const [precautions, setPrecautions] = useState([]);
  const [error, setError] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true); // For better UX

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";

    // Create a Date object from the date string
    const date = new Date(dateString);

    // Convert to UTC and format time
    const time = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });

    // Format date to DD/MM/YYYY using UTC date methods
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
    const year = date.getUTCFullYear();

    // Combine to form the date string in DD/MM/YYYY format
    const dateStr = `${day}/${month}/${year}`;

    return `${dateStr}, ${time}`;
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          work_permit_id: work_permit_id,
        },
      });

      setDesignations(response.data);
      // setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPermitDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_permit_details?work_permit_id=${work_permit_id}`,
          {
            headers: {
              Authorization: authKey, // Use 'Bearer' prefix if required
            },
          }
        );

        if (response.data) {
          // Set all states from the single API response
          setPermitDetails(response.data);
          setChecklist(response.data.checklistItems || []);
          setWorkDetails(response.data.workDetails || {});
          setClearanceRenewal(response.data.workDetails || {});
          setPrecautions(response.data.hazardsPrecautions || [{}]);
        } else {
          console.warn("No permit details found.");
        }
      } catch (err) {
        console.error("Error fetching permit details:", err);
        setError("Failed to load permit details.");
      } finally {
        setLoading(false);
      }
    };

    fetchPermitDetails();
  }, [work_permit_id]);

  const checklistFirst10 = checklist.filter((item) => item.item_number <= 10);
  const scaffoldingChecklist = checklist.filter(
    (item) => item.item_number > 10
  );

  // Render the component conditionally
  if (!permitDetails || !permitDetails.workDetails) {
    return <div>Loading...</div>; // or some other loading state
  }

  const handleSaveClick = async () => {
    try {
      // Retrieve the authKey from localStorage
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key not found");
        return;
      }

      // API call to approve the work permit
      const response = await axios.put(
        `${API_URL}/api/approve_work_permit`,
        { work_permit_id: work_permit_id }, // Send form_id in the request body
        {
          headers: {
            Authorization: authKey, // Pass the Authorization header
          },
        }
      );

      // Check the API response status

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("Work permit approved successfully!");
        setTimeout(() => {
          navigate("/work-permit-inspection");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // // const fetchData = async () => {
  // //   try {
  // //     setLoading(true);
  // //     const authKey = localStorage.getItem("authKey");

  // //     const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
  // //       headers: {
  // //         Authorization: authKey,
  // //       },
  // //       params: {
  // //         work_permit_id: work_permit_id,
  // //       },
  // //     });

  // //     // setDesignations(response.data);
  // //     // setTotalItems(response.data.TotalItems);
  // //     setLoading(false);
  // //   } catch (error) {
  // //     console.error("Error fetching department data:", error.message);
  // //     setLoading(false);
  // //   }
  // };

  const handleExtensionIssuClick = async (renewal_id) => {
    try {
      // Retrieve the authKey from localStorage
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key not found");
        return;
      }

      // API call to approve the work permit
      const response = await axios.put(
        `${API_URL}/api/renew_permit_extension`,
        { renewal_id }, // Send form_id in the request body
        {
          headers: {
            Authorization: authKey, // Pass the Authorization header
          },
        }
      );

      // Check the API response status

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("Work permit approved successfully!");
        setTimeout(() => {
          navigate("/work-permit-inspection");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleExtensionSaveClick = async (renewal_id) => {
    try {
      // Retrieve the authKey from localStorage
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key not found");
        return;
      }

      // API call to approve the work permit
      const response = await axios.put(
        `${API_URL}/api/renew_permit_extension`,
        { renewal_id }, // Send form_id in the request body
        {
          headers: {
            Authorization: authKey, // Pass the Authorization header
          },
        }
      );

      // Check the API response status

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("Work permit approved successfully!");
        setTimeout(() => {
          navigate("/work-permit-inspection");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleExtensionIssuClick = async (renewal_id) => {
  //   try {
  //     // Retrieve the authKey from localStorage
  //     const authKey = localStorage.getItem("authKey");

  //     if (!authKey) {
  //       console.error("Authorization key not found");
  //       showSnackbar("Authorization error. Please log in again.", "error");
  //       return;
  //     }

  //     // Show loading state
  //     setIsLoading(true);

  //     // API call to approve the work permit extension
  //     const response = await axios.put(
  //       `${API_URL}/api/renew_permit_extension`,
  //       { renewal_id }, // Send renewal_id in the request body
  //       {
  //         headers: {
  //           Authorization: authKey, // Proper Authorization header format
  //         },
  //       }
  //     );

  //     // Check the API response status
  //     if (response.status === 200) {
  //       console.log(response.data.message);
  //       setSeverity("success");
  //       showSnackbar("Work permit extension approved successfully!", "success");

  //     } else {
  //       showSnackbar(
  //         "Failed to approve the extension. Please try again.",
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error saving permit extension:", error.message);
  //     showSnackbar(
  //       "An error occurred while saving the permit extension.",
  //       "error"
  //     );
  //   } finally {
  //     setIsLoading(false); // Ensure loading state is turned off after the request
  //   }
  // };

  // const handleExtensionSaveClick = async (renewal_id) => {
  //   try {
  //     // Retrieve the authKey from localStorage
  //     const authKey = localStorage.getItem("authKey");

  //     if (!authKey) {
  //       console.error("Authorization key not found");
  //       showSnackbar("Authorization error. Please log in again.", "error");
  //       return;
  //     }

  //     // Show loading state
  //     setIsLoading(true);

  //     // API call to approve the work permit extension
  //     const response = await axios.put(
  //       `${API_URL}/api/approve_permit_extension`,
  //       { renewal_id }, // Send renewal_id in the request body
  //       {
  //         headers: {
  //           Authorization: authKey, // Proper Authorization header format
  //         },
  //       }
  //     );

  //     // Check the API response status
  //     if (response.status === 200) {
  //       console.log(response.data.message);
  //       setSeverity("success");
  //       showSnackbar("Work permit extension approved successfully!", "success");
  //       setTimeout(() => {
  //         navigate("/work-permit-inspection"); // Navigate to the next page after success
  //       }, 1000);
  //     } else {
  //       showSnackbar(
  //         "Failed to approve the extension. Please try again.",
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error saving permit extension:", error.message);
  //     showSnackbar(
  //       "An error occurred while saving the permit extension.",
  //       "error"
  //     );
  //   } finally {
  //     setIsLoading(false); // Ensure loading state is turned off after the request
  //   }
  // };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={() => navigate("/work-permit-inspection")}
                  />
                </Tooltip>
                Cold Work Permit Request Inspection
              </div>
              <div className="data-form-heading-content-right-side">
                {/* <button
                  className={`data-form-save-button ${
                    workDetails.status === "Approved" ? "disabled" : ""
                  }`}
                  type="button"
                  onClick={handleSaveClick}
                  disabled={isLoading || workDetails.status === "Approved"}
                >
                  {workDetails.status === "Approved"
                    ? "Approved"
                    : isLoading
                    ? "Processing..."
                    : "Approve"}
                </button> */}

                <button
                  className={`data-form-save-button ${
                    workDetails.status === "Approved" ||
                    workDetails.status === "Renewed" ||
                    workDetails.status === "Close Under Review" ||
                    workDetails.status === "Extension Under Review"
                      ? "disabled"
                      : ""
                  }`}
                  type="button"
                  onClick={handleSaveClick}
                  disabled={
                    isLoading ||
                    workDetails.status === "Approved" ||
                    workDetails.status === "Close Under Review" ||
                    workDetails.status === "Renewed" ||
                    workDetails.status === "Extension Under Review"
                  }
                >
                  {workDetails.status === "Approved" ||
                  workDetails.status === "Renewed" ||
                  workDetails.status === "Close Under Review" ||
                  workDetails.status === "Extension Under Review"
                    ? "Approved"
                    : isLoading
                    ? "Processing..."
                    : "Approve"}
                </button>

                {/* {workDetails.status === "Extension Under Review" && (
                  <button
                    onClick={() =>
                      handleExtensionSaveClick(workDetails.work_permit_id)
                    }
                    className={`data-form-save-button ${
                      workDetails.status === "Extension Under Review"
                        ? "disabled"
                        : ""
                    }`}
                    type="button"
                    disabled={
                      isLoading || workDetails.status === "Extension Under Review"
                    }
                  >
                    {workDetails.status === "Extension Under Review"
                      ? "Extension Approve"
                      : isLoading
                      ? "Processing..."
                      : "Extension Approved"}
                  </button>
                )} */}
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="inspection-body">
                  <div className="inspection-body-container1">
                    <div className="inspection-box">
                      <div className="inspection-box-header">
                        <div>Issued to</div>
                      </div>
                      <div className="inspection-box-body">
                        <div className="box-contain">
                          <div>1. Department</div>
                          <div>{workDetails.department_name || "NA"}</div>
                        </div>
                        <div className="box-contain">
                          <div>2. Section</div>
                          <div>{workDetails.section || "NA"}</div>
                        </div>
                        <div className="box-contain">
                          <div>3. Contractor</div>
                          <div>{workDetails.contractor || "NA"}</div>
                        </div>
                      </div>
                    </div>
                    <div className="inspection-box">
                      <div className="inspection-box-header">
                        <div>Exact Location of work</div>
                      </div>
                      <div className="inspection-box-body">
                        <div className="box-contain">
                          <div>1. Area</div>
                          <div>{workDetails.area || "NA"}</div>
                        </div>
                        <div className="box-contain">
                          <div>2. Unit</div>
                          <div>{workDetails.unit || "NA"}</div>
                        </div>
                        <div className="box-contain">
                          <div>3. Equipement No</div>
                          <div>{workDetails.TagNos || "NA"}</div>
                        </div>
                      </div>
                    </div>
                    <div className="inspection-box">
                      <div className="inspection-box-header">
                        <div>Date & Time</div>
                      </div>
                      <div className="inspection-box-body">
                        <div className="box-contain">
                          <div>1. Start</div>
                          <div>
                            {formatDateTime(workDetails.valid_from || "NA")}
                          </div>
                        </div>
                        <div className="box-contain">
                          <div>2. End</div>
                          <div>
                            {formatDateTime(workDetails.valid_to || "NA")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inspection-box">
                      <div className="inspection-box-header">
                        <div>Description Of work</div>
                      </div>
                      <div className="inspection-box-body">
                        <div className="box-contain">
                          <div>{workDetails.work_description || "NA"}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inspection-body-container2">
                    <div className="inspection-box-body2">
                      <div>Check List</div>

                      <div className="inspection-box-header2">
                        <div>
                          THE FOLLOWING ITEMS SHALL BE CHECKED BEFORE USING THE
                          PERMIT
                        </div>
                      </div>

                      {checklistFirst10.map((item) => (
                        <div className="inspection-box">
                          <div key={item.id} className="inspection-box-header">
                            <div>
                              {item.item_number}. {item.item_desc} -{" "}
                            </div>
                            <div className="checklist-options radio-check-option">
                              <label>
                                <input
                                  type="radio"
                                  name={`status_${item.id}`}
                                  value="1"
                                  checked={item.work_status === 1}
                                  disabled
                                />
                                Done
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name={`status_${item.id}`}
                                  value="0"
                                  checked={item.work_status === 0}
                                  disabled
                                />
                                Not Required
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="inspection-box-header2">
                        <div>Scaffolding Erection / Working at height</div>
                      </div>
                      {scaffoldingChecklist.map((item) => (
                        <div className="inspection-box">
                          <div key={item.id} className="inspection-box-header">
                            <div>
                              {item.item_number}. {item.item_desc} -{" "}
                            </div>
                            <div className="checklist-options radio-check-option">
                              <label>
                                <input
                                  type="radio"
                                  name={`status_${item.id}`}
                                  value="1"
                                  checked={item.work_status === 1}
                                  disabled
                                  className="custom-radio"
                                />
                                Done
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name={`status_${item.id}`}
                                  value="0"
                                  checked={item.work_status === 0}
                                  disabled
                                  className="custom-radio"
                                />
                                Not Required
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="inspection-box-body3">
                      <div>Precautions</div>

                      <div className="inspection-box-header3">
                        <div className="inspaction-description">
                          Additional Precaution, if any:
                        </div>

                        {/* <div className="lable">Additional Precaution, if any</div> */}
                        {precautions.length > 0 ? (
                          precautions.map((item) => (
                            <div key={item.id} className="value">
                              {item.precaution_description || "NA."}
                            </div>
                          ))
                        ) : (
                          <div>NA.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <table className="table multilevel-data">
                  <thead>
                    <tr>
                      <th className="center-alignment">SR. NO.</th>
                      <th>START DATE</th>
                      <th>END DATE</th>
                      <th>EXTENSION REASON</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody className="data-list-scroll-bodys">
                    {designations && designations.length > 0 ? (
                      designations.map((designation, index) => (
                        <tr key={designation.id} className="hoverable-row">
                          <td className="center-alignment">{index + 1}</td>
                          <td>{formatDateTime(designation.date_from || "NA")}</td>
                          <td>{formatDateTime(designation.date_to || "NA")}</td>
                          <td>{designation.extension_reason || "NA"}</td>
                          {/* <td>
            <button
              onClick={() => handleExtensionIssuClick(designation.id)}
            >
              Approve
            </button>
          </td> */}
                          <td>
                            <div className="new-permit">
                              {/* {workDetails.status ===
                                "Extension Under Review" && (
                                <button
                                  onClick={() =>
                                    handleExtensionSaveClick(designation.id)
                                  }
                                  className={`data-form-save-button ${
                                    workDetails.status ===
                                    "Extension Under Review"
                                      ? "disabled"
                                      : ""
                                  }`}
                                  type="button"
                                >
                                  {workDetails.status ===
                                  "Extension Under Review"
                                    ? "Extension Approve"
                                    : isLoading
                                    ? "Processing..."
                                    : "Extension Approved"}
                                </button>
                              )} */}
 {workDetails.status === "Renewed" && (
<button
className="new-button-download"
              onClick={() => handleExtensionIssuClick(designation.id)}
            >
              Approve
            </button>
 )}

                              <button
                                className={`data-form-save-button ${
                                  workDetails.status ===
                                    "Renewed" ||
                                  workDetails.status === "Close Under Review"
                                    ? "disabled"
                                    : ""
                                }`}
                                type="button"
                                onClick={() =>
                                  handleExtensionSaveClick(designation.id)
                                }
                                disabled={
                                  isLoading ||
                                  workDetails.status === "Renewed" ||
                                  workDetails.status === "Close Under Review" 
                                  
                                }
                              >
                                {workDetails.status ===
                                  "Renewed" ||
                                workDetails.status === "Close Under Review"
                                  ? "Extension Approved"
                                  : isLoading
                                  ? "Processing..."
                                  : "Extension Approve"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="center-alignment">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Form1;
