import React, { useState, useEffect, useRef } from "react";
// import "./UserView.css";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import "../Responsive/TableResponsive.css";
import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { useLocation } from "react-router-dom";
import { format } from 'date-fns';
// import { utcToZonedTime } from 'date-fns-tz';

function ColdWorkPermitDetail({ isSidebarExpanded }) {
  const [users, setUsers] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const navigate = useNavigate();
  const { work_permit_id } = useParams();
  console.log("Received ID:", work_permit_id);
  const containerRef = useRef(null);
  const [customerData, setCustomerData] = useState(null);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  console.log("Status:", status);
  console.log("Received ID:", work_permit_id);
  const handleBackView = () => {
    navigate("/cold-work-permit");
  };
  const handleClose = () => {
    navigate("/cold-work-permit");
  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
  
    return dateObj.toLocaleString("GMT", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // AM/PM format
    });
  }
  
  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    
    // Create a Date object from the date string
    const date = new Date(dateString);
  
    // Convert to UTC and format time
    const time = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
  
    // Format date to DD/MM/YYYY using UTC date methods
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = date.getUTCFullYear();
  
    // Combine to form the date string in DD/MM/YYYY format
    const dateStr = `${day}/${month}/${year}`;
  
    return `${dateStr}, ${time}`;
  };
  


  const fetchData = async (currentPage, itemsPerPage, status) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/issuer/get_work_permit_requests`,
        {
          headers: { Authorization: authKey },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            status: status,
          },
        }
      );

      setUsers(response.data.permit_details);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    if (status) {
      fetchData(page, itemsPerPage, status); // Ensure all required arguments are passed
    }
  }, [status]);

  const fetchCustomerData = async (work_permit_id) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_permit_work_details/${work_permit_id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.data.permit_details) {
        setCustomerData(response.data.permit_details); // Use permit_details directly
      } else {
        setCustomerData(null); // Handle empty permit_details array
      }

      setSelectedUserID(work_permit_id);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setCustomerData(null);
    }
  };

  const togglePopup = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_permit_work_details/${work_permit_id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      // Ensure we fetch the first permit_details item
      if (response.data.permit_details) {
        setCustomerData(response.data.permit_details);
      } else {
        setCustomerData(null); // Handle empty permit_details array
      }

      setSelectedUserID(work_permit_id);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setCustomerData(null);
    }
  };

  useEffect(() => {
    togglePopup();
  }, []);

  return (
    <>
      <div
        className={`main-container page-content${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div
          className={`data-lists-content-container page-contents ${
            isSidebarExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="data-list-content" ref={containerRef}>
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Work Permit Request
                </div>
                <div className="data-list-heading-content-right-side">
                  <Link to="/userform" className="data-list-new">
                    <Tooltip title="New User" arrow>
                      <button className="new-button">
                        <HiOutlinePlus className="new-plus-icon" />
                      </button>
                    </Tooltip>
                  </Link>
                </div>
              </div>
            </div>

            <div className="data-list-body">
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>SR. NO.</th>
                        <th>RECEVIER NAME</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {users.map((customer, index) => (
                        <tr key={customer.ID} className="hoverable-row">
                          <td>{index + 1}</td>

                          <td
                            onClick={() =>
                              fetchCustomerData(customer.work_permit_id)
                            }
                            className={
                              selectedUserID === customer.work_permit_id
                                ? "selected-user"
                                : ""
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {customer.receiver_name || "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-view-popup">
          <div className="data-popup-content">
            <div className="data-view">
              <div className="data-view-contant">
                <div className="data-view-header">
                  <div className="data-view-header-left-side">
                    {customerData && (
                      <div className="user-header-title">
                        <div>
                          <Tooltip title="Back" arrow>
                            {" "}
                            <IoMdArrowBack
                              className="back-company"
                              onClick={handleBackView}
                            />{" "}
                          </Tooltip>
                          {customerData.section || "NA"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="user-view-header-right-side">
                    <div className="header-title-edit">
                      <div className="view-close-button" onClick={handleClose}>
                        <IoCloseOutline />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="user-view-body">
                  <div className="rows">
                    {customerData && (
                      <div className="user-view-body-container">
                        <div className="user-view-body-container-content">
                          <div className="text-medium-header">
                            Cold Work Permit Details
                          </div>
                          <div className="text-medium-body">
                            <div className="row">
                              <label htmlFor="">Valid From</label>
                              <div className="answer">
                            
                                    {formatDateTime(customerData.valid_from)}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Valid To</label>
                              <div className="answer">
                                    {formatDateTime(customerData.valid_to)}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Section</label>
                              <div className="answer">
                                {customerData.section || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Contractor</label>
                              <div className="answer">
                                {customerData.contractor || "NA"}
                              </div>
                            </div>

                            <div className="row">
                              <label htmlFor="">Department</label>
                              <div className="answer">
                                {customerData.department_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Area</label>
                              <div className="answer">
                                {customerData.area || "NA"}
                              </div>
                            </div>            
                            <div className="row">
                              <label htmlFor="">Unit</label>
                              <div className="answer">
                                {customerData.unit || "NA"}
                              </div>
                            </div>
                            <div className="row">
  <label htmlFor="">Tag No</label>
  <div className="answer">
    {customerData.equipment_details && customerData.equipment_details.length > 0 ? (
      customerData.equipment_details.map((equipment, index) => (
        <div key={index}>
          <div>{equipment.TagNo || "NA"}</div>
        </div>
      ))
    ) : (
      "NA"
    )}
  </div>
</div>

                            <div className="row">
                              <label htmlFor="">Description</label>
                              <div className="answer">
                                {customerData.work_description || "NA"}
                              </div>
                            </div>
                            {/* Equipment Details */}
                          
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ColdWorkPermitDetail;
