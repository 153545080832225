import React, { useState, useEffect } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function Form2({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("Fetched ID:", id);

  const checklistItems = [
    "1) Equipment/work area inspected",
    "2) Surrounding area checked cleaned and covered",
    "3) Equipment blinded/connected/ closed/isolated/wedge opened",
    "4) Equipment properly drained and depressurized",
    "5) Equipment Electrically isolated and tagged vide permit no. ............",
    "6) Equipment water flushed",
    "7) Equipment properly steamed/purged",
    "8) Proper ventilation and lighting provided",
    "9) Area cordoned off & caution boards/Tags provided",
    "10) HCs= ....%LEL  Toxic gases= ....%ppm",
  ];

  const secondaryChecklistItems = [
    "1) Scaffolding Tag Provided",
    "2) Scaffolding Tag signed by contractor, supervisor and maintenance supervisor.",
    "3) Full body harness with shock absorber used.",
    "4) Scaffolding area barricaded",
    "5) IS/EN certified safety harness used.",
    "6) Safety belt life line anchoring to the rigid structure to be ensured",
    "7) In the absence of fixed means of access, provisions of lifelines with fall arresting for both vertical and horizontal movement is to be ensured.",
  ];

  const [checklist, setChecklist] = useState(
    checklistItems.map((item) => ({ label: item, status: null }))
  );
  const [secondaryChecklist, setSecondaryChecklist] = useState(
    secondaryChecklistItems.map((item) => ({ label: item, status: null }))
  );

  const handleStatusChange = (index, status, checklistType) => {
    const updatedChecklist =
      checklistType === "primary" ? [...checklist] : [...secondaryChecklist];

    // Map the selected status to 1 or 0
    const mappedStatus = status === "done" ? 1 : 0;

    updatedChecklist[index].status = mappedStatus;

    checklistType === "primary"
      ? setChecklist(updatedChecklist)
      : setSecondaryChecklist(updatedChecklist);
  };

  const handleCancelClick = () => {
    // navigate("/cold-work-permit?tab=Form1");
    navigate(`/cold-work-permit-form/${id}?tab=Work Permit Details`);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  
  useEffect(() => {
    const fetchPermitDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        
        const response = await axios.get(
          `${API_URL}/api/get_permit_checklist_items?work_permit_id=${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
  
        const checklistItemsFromAPI = response.data.checklist_items || [];
  
        // Map the API data into the checklist state
        const updatedChecklist = checklistItems.map((item, index) => {
          const matchingItem = checklistItemsFromAPI.find(
            (apiItem) => apiItem.item_number === index + 1
          );
          return {
            label: item,
            status: matchingItem ? matchingItem.status : 0, // Default to 0 for "Not Required"
            item_id: matchingItem ? matchingItem.id : null,
          };
        });
  
        const updatedSecondaryChecklist = secondaryChecklistItems.map(
          (item, index) => {
            const matchingItem = checklistItemsFromAPI.find(
              (apiItem) =>
                apiItem.item_number === index + 1 + checklistItems.length
            );
            return {
              label: item,
              status: matchingItem ? matchingItem.status : 0, // Default to 0 for "Not Required"
              item_id: matchingItem ? matchingItem.id : null,
            };
          }
        );
  
        setChecklist(updatedChecklist);
        setSecondaryChecklist(updatedSecondaryChecklist);
      } catch (error) {
        console.error("Error fetching permit details:", error);
      }
    };
  
    fetchPermitDetails();
  }, [id]);
  
  
  
  const handleSaveClick = async () => {
    const errors = {};
    let error = false;

    // Clean the checklist items by removing numbering from both checklist and secondaryChecklist
    const cleanChecklist = checklist.map((item, index) => ({
      item_id: item.item_id, // Include item_id if exists (for update)
      item_number: index + 1, // assuming item_number is sequential
      item_desc: item.label.replace(/^\d+\)\s*/, ""), // Remove the number and closing parenthesis
      status: item.status || 0, // default to 0 if no status selected
    }));

    const cleanSecondaryChecklist = secondaryChecklist.map((item, index) => ({
      item_id: item.item_id, // Include item_id if exists (for update)
      item_number: index + 1 + checklist.length, // continuing the item_number
      item_desc: item.label.replace(/^\d+\)\s*/, ""), // Remove the number and closing parenthesis
      status: item.status || 0,
    }));

    // Combine both cleaned checklists
    const items = [...cleanChecklist, ...cleanSecondaryChecklist];

    const formData = {
      work_permit_id: id, // Assuming the `id` variable is the form ID from URL params
      items: items,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Check if any item has an item_id (indicating it's an update)
      const hasItemId = items.some((item) => item.item_id);

      let response;

      if (hasItemId) {
        // Call update API if item_id exists in any item
        response = await axios.post(
          `${API_URL}/api/update_permit_checklist_items`, // Update API endpoint
          formData, // Send formatted form data
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
      } else {
        // Call add API if no item_id exists (add new items)
        response = await axios.post(
          `${API_URL}/api/add_permit_checklist_items`, // Add API endpoint
          formData, // Send formatted form data
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
      }

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar(
          hasItemId
            ? "Permit checklist items updated successfully"
            : "Permit checklist items added successfully"
        );
        setTimeout(() => {
          if (id) {
            // // Navigate with the correct tab (encoded for URL)
            // const tabName = "Hazards & Precautions"; 
            // const encodedTabName = encodeURIComponent(tabName); // Encode the tab name to handle spaces and special characters
            // navigate(`/cold-work-permit-form/${id}?tab=${encodedTabName}`);
            navigate(
              `/cold-work-permit-form/${id}?tab=Hazards Precautions`
            );
          } else {
            console.error("ID is not available");
          }
        }, 1000);
        
      }
    } catch (error) {
      console.error("Error saving checklist:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save checklist items");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
   
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              New Cold Work Permit
            </div>
            <div className="data-form-heading-content-right-side"></div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          <form className="form-detail">
            <div className="new-data-form">
            
              <div className="">
              <h3 className="line2">THE FOLLOWING ITEMS SHALL BE CHECKED BEFORE USING THE PERMIT</h3>
              </div>
              <div className="checklist-container">
                {checklist.map((item, index) => (
                  <div key={index} className="checklist-item">
                    <label>{item.label}</label>
                    <div className="checklist-options">
                      <label>
                        <input
                          type="radio"
                          name={`status-${index}`}
                          value="done"
                          checked={item.status === 1} // Check if status is 1 (Done)
                          onChange={() =>
                            handleStatusChange(index, "done", "primary")
                          }
                        />
                        Done
                      </label>
                      <label>
                        <input
                          type="radio"
                          name={`status-${index}`}
                          value="not-required"
                          checked={item.status === 0} // Check if status is 0 (Not Required)
                          onChange={() =>
                            handleStatusChange(index, "not-required", "primary")
                          }
                        />
                        Not Required
                      </label>
                    </div>
                  </div>
                ))}

                <div>
                  <h3>Scaffolding Erection / Working at height</h3>
                </div>

                {secondaryChecklist.map((item, index) => (
                  <div key={index} className="checklist-item">
                    <label>{item.label}</label>
                    <div className="checklist-options">
                      <label>
                        <input
                          type="radio"
                          name={`status-secondary-${index}`}
                          value="done"
                          checked={item.status === 1} // Check if status is 1
                          onChange={() =>
                            handleStatusChange(index, "done", "secondary")
                          }
                        />
                        Done
                      </label>
                      <label>
                        <input
                          type="radio"
                          name={`status-secondary-${index}`}
                          value="not-required"
                          checked={item.status === 0} // Check if status is 0
                          onChange={() =>
                            handleStatusChange(
                              index,
                              "not-required",
                              "secondary"
                            )
                          }
                        />
                        Not Required
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <div className="button-group">
            {/* <button
              className="data-form-save-button"
              type="save"
              onClick={handleSaveClick}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button> */}
            <button
              className="data-form-cancel-button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button className="data-form-next-button" onClick={handleSaveClick}>
              Next
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Form2;
