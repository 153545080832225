import React, { useState, useEffect, useRef } from "react";
// import "./User.css";
import "./ColdWorkPermitForm.css";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";

function AllColdPermitRequest({
  isSidebarExpanded,
  customerID,
  status,
  updateNewCounts,
  updateTotalItems,
}) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...designations].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setDesignations(sortedBreakdown);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage, status) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/issuer/get_work_permit_requests`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            status: status, // Pass status here
          },
        }
      );

      setDesignations(response.data.permit_details);
      setTotalItems(response.data.totalItems);
      updateTotalItems(response.data.tabCounts);
      updateNewCounts(response.data.newCounts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage, status);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        // Fetch data when not searching and status is present
        if (status) {
          fetchData(page, itemsPerPage, status);
        }
      } else {
        // Handle search scenario
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage, status]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? designations.map((designation) => designation.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleFillForm = async (work_permit_id) => {
    // First, make the API call to update the status
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_status`,
        { permit_id: work_permit_id, status: "In Review" },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      console.log(response.data);

      // If successful, navigate to the desired page
      navigate(`/cold-work-permit-form/${work_permit_id}`);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };
  const handleEdit = async (work_permit_id) => {
    navigate(`/cold-work-permit-form/${work_permit_id}`, {
      state: { issuer: "issuer" },
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedDesignation = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = designations.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setDesignations(updatedDesignations);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDesignation = () => {
    navigate("/archived-designations");
  };

  // const handleView = (workPermitId, status) => {
  //   navigate(`/cold-work-permit-detail/${workPermitId}?status=${status}`);
  // };

  const handleView = (workPermitId) => {
    navigate(`/cold-work-permit-inspection-view/${workPermitId}`);
  };
  const handleApproveExtension = (workPermitId) => {
    navigate(`/view-extension/${workPermitId}`);
  };
  const handleRefresh = () => {
    if (searchInput) {
    } else {
      fetchData(page, itemsPerPage, status);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleIssues = async (work_permit_id) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_status`,
        { permit_id: work_permit_id, status: "Issued" },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      if (response.status === 200) {
        // setSeverity("success");
        showSnackbar("Work permit issued successfully!");
        await fetchData(page, itemsPerPage, status);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
    }
  };
  const handleCloseRequest = async (work_permit_id) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/issuer/approve_close_permit_request`,
        { work_permit_id: work_permit_id, },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      if (response.status === 200) {
        // setSeverity("success");
        showSnackbar("Work permit issued successfully!");
        await fetchData(page, itemsPerPage, status);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
    }
  };
  return (
    <>
      <div className="data-list-content" ref={containerRef}>
        {isChecked ? (
          <div className="action-header">
            <div className="action-header-content">
              <div className="action-header-content-leftside">
                <div className="action-button">
                  <button className="delete" onClick={handleDeletePopup}>
                    Move to archive
                  </button>
                  {isDeletePopup && (
                    <div className="confirm-delete-popup">
                      <div className="confirm-delete-popup-content">
                        <div className="confirm-model">
                          <div className="confirm-model-content">
                            <div className="confirm-model-content-body">
                              <p>Are you sure want to move archive?</p>
                            </div>
                            <div className="confirm-model-content-footer">
                              <button
                                className="confirm-delete-button"
                                onClick={handleArchivedDesignation}
                              >
                                Yes
                              </button>
                              <button
                                className="confirm-cancel-button"
                                onClick={handleCancel}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="action-header-content-rightside">
                <button
                  className="action-header-close-button"
                  onClick={handleCloseButtonClick}
                >
                  <IoCloseOutline className="action-header-close-icon" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="data-list-header">
            <div className="data-list-header-content">
              <div className="data-list-heading-content-left-side">
                All Work Permit Requests
              </div>

              <div className="data-list-heading-content-right-side">
                {/* <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div> */}

                {/* <Link
                      to="/cold-work-permit-config"
                      className="data-list-new"
                    >
                      <Tooltip title="New designation" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link> */}

                <Tooltip title="Refresh List" arrow>
                  <button className="refresh-button" onClick={handleRefresh}>
                    <TbRefresh className="refresh-icon" />
                  </button>
                </Tooltip>
                <button
                  className="sort-button"
                  onClick={handleSortpopup}
                  ref={buttonRef}
                >
                  <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                </button>
                {/* {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("designation_name")}
                          >
                            Designations{" "}
                            {sortOrders.designation_name === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDesignation}
                        >
                          Archived Designations
                        </button>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        )}
        <div className="data-list-body" onClick={closePopups}>
          <div className="data-list-scroll-bodys">
            <div className="data-list-table-report">
              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Loading...</div>
                </div>
              ) : designations.length > 0 ? (
                <table className="table multilevel-data">
                  <thead>
                    <tr>
                      <th className="center-alignment"></th>
                      <th className="center-alignment">SR. NO.</th>
                      <th className="center-alignment">REQUEST ID</th>
                      <th>PERMIT TYPE</th>
                      <th>RECEIVER NAME</th>
                      <th>STATUS</th>
                      <th>SECTION</th>
                      <th>UNIT</th>
                      <th>AREA</th>
                      <th className="center-alignment">ACTION</th>
                    </tr>
                  </thead>
                  <tbody className="data-list-scroll-bodys">
                    {designations.map((designation, index) => (
                      <tr key={designation.id} className="hoverable-row">
                        <td className="center-alignment">
                          <div className="icon-container">
                            <span
                              className={
                                designation.is_new_row === "1"
                                  ? "new-badge blinking-new-badge"
                                  : "new-badge"
                              }
                            ></span>
                          </div>
                        </td>

                        <td className="center-alignment">{index + 1}</td>
                        <td
                          className="center-alignment"
                          style={{ color: "red" }}
                        >
                          {designation.request_id || "NA"}
                        </td>
                        <td>{designation.work_permit_name || "NA"}</td>
                        <td>{designation.receiver_name || "NA"}</td>
                        <td
                          style={{
                            color:
                              designation.status === "Draft"
                                ? "gray"
                                : designation.status === "Submitted"
                                ? "orange"
                                : designation.status === "In Review"
                                ? "#8f7a13"
                                : designation.status === "Pending Approval"
                                ? "#8f7a13"
                                : designation.status === "Approved"
                                ? "blue"
                                : designation.status === "Issued"
                                ? "green"
                                : designation.status === "Rejected"
                                ? "red"
                                : "inherit",
                            fontWeight: "600",
                          }}
                        >
                          {designation.status || "NA"}
                        </td>
                        <td>{designation.section || "NA"}</td>
                        <td>{designation.unit || "NA"}</td>
                        <td>{designation.area || "NA"}</td>

                        <td>
                          <div className="permit-buttons1 center-alignment">
                            {/* <div className="edit-permit center-alignment"> */}
                            {designation.status === "Submitted" && (
                              <button
                                className="edit-header-button permit-buttons"
                                onClick={() =>
                                  handleFillForm(designation.work_permit_id)
                                }
                              >
                                Approve & Fill Application Form
                              </button>
                            )}
                            {designation.status === "Pending Approval" && (
                              <button
                                className="request-buttons permit-buttons"
                                onClick={() =>
                                  handleEdit(designation.work_permit_id)
                                }
                              >
                                Edit Application Form
                              </button>
                            )}
                            {designation.status === "In Review" && (
                              <button
                                className="request-buttons permit-buttons"
                                onClick={() =>
                                  handleEdit(designation.work_permit_id)
                                }
                              >
                                Resume Application Form
                              </button>
                            )}
                            {designation.status === "Approved" && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleIssues(designation.work_permit_id)
                                }
                              >
                                Issue
                              </button>
                            )}
                               {designation.status === "Close Requested" && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleCloseRequest(designation.work_permit_id)
                                }
                              >
                                Close Request
                              </button>
                            )}
                            {/* </div> */}
                            <div className="new-permit">
                              {(designation.status === "Renewed" || designation.status === "Close Under Review" ) && (
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleView(
                                      designation.work_permit_id,
                                      designation.status
                                    )
                                  }
                                >
                                  View
                                </button>
                              )}
                            </div>
                            <div className="new-permit">
                              {designation.status === "Extension Requested" && (
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleApproveExtension(
                                      designation.work_permit_id,
                                      designation.status
                                    )
                                  }
                                >
                                  View All Extension Request
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-users-container">
                  <div className="no-users-message">No Data Found</div>
                </div>
              )}
            </div>
            <Pagination
              showPerPage={itemsPerPage}
              total={totalItems}
              onPaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AllColdPermitRequest;
