import React from "react";
import "./Template1.css";
import "./Template4.css";

// const Template8 = () => {
  const Template8 = ({ clearanceRenewal = [], workDetails = {} }) => {
    const totalRows = 5;
  
    // Ensure clearanceRenewal is an array before using slice
    const dataRows = Array.isArray(clearanceRenewal) ? clearanceRenewal.slice(0, totalRows) : [];
    const emptyRows = Array.from(
      { length: Math.max(totalRows - dataRows.length, 0) },
      () => ({}) // Fill remaining rows with empty objects
    );
  
    // Combine filled and empty rows
    const rows = [...dataRows, ...emptyRows];

  return (
    <>
      <div className="template-container">
        <div className="template-container-content">
          <div className="template-container-header">
            <div className="template-heading">
              क्लीयरेंस नवीकरण /Clearance renewal{" "}
            </div>
            <div className="cold-remarks"></div>
            <div className="cold-work-table">
              <table className="cold-work-table">
                <thead>
                  <tr>
                    <th
                      rowSpan="2"
                      className="permit-template-clearance renewal-permit1"
                    >
                      दिनांक <br /> Date
                    </th>
                    <th colspan="2" className="permit-template-clearance ">
                      समय/Time{" "}
                    </th>
                    <th className="permit-template-clearance renewal-permit01">
                      विस्तार <br /> /Extension
                    </th>
                    <th
                      rowSpan="2"
                      className="permit-template-clearance renewal-permit01"
                    >
                      अतिरिक्त <br /> सावधानिया यदि <br /> कोई, अन्याथा <br />{" "}
                      उल्लेख कुध नहीं / <br />
                      Additional <br /> precautions if <br /> any, Otherwise{" "}
                      <br /> mention "NIL"
                    </th>
                    <th
                      rowSpan="2"
                      className="permit-template-clearance renewal-permit2"
                    >
                      जारीकर्ता का नाम, पद <br />
                      और हस्ताक्षर <br />
                      Issuer Name, <br />
                      Designation & <br /> Signature
                    </th>

                    <th
                      rowSpan="2"
                      className="permit-template-clearance renewal-permit2"
                    >
                      प्राप्तकर्ता नाम एवं <br /> हस्ताक्षर <br /> Receivers
                      Name, <br /> Designation & <br /> Signature
                    </th>
                    <th
                      rowSpan="2"
                      className="permit-template-clearance renewal-permit2"
                    >
                      सुरक्षा आधिकारी <br /> नाम और हस्ताक्षर <br /> Safety
                      Officer <br /> Name & <br />
                      Signature
                    </th>
                  </tr>
                  <tr>
                    <th className="permit-template-clearance renewal-permit1">
                      से <br />
                      From
                    </th>
                    <th className="permit-template-clearance renewal-permit1">
                      तक <br />
                      to
                    </th>
                    <th className="permit-template-clearance">
                      तक बढ़ा दिया <br /> गया है। <br /> Extended up <br /> to
                      __Hrs.
                    </th>
                  </tr>
                </thead>
                {/* <tbody>
                    <tr className="permit-row-end">
                      <td>{clearanceRenewal.date_from}</td>
                      <td>{clearanceRenewal.date_to}</td>
                      <td>{clearanceRenewal.date_to}</td>
                      <td>{clearanceRenewal.date_to}</td>
                      <td>{clearanceRenewal.additional_instruction}</td>
                      <td>{clearanceRenewal.issuer_name}({clearanceRenewal.issuer_designation})</td>
                      <td>{workDetails.receiver_name}</td>
                      <td>{clearanceRenewal.safety_officer_name}</td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody> */}

                {/* <tbody>
                  {clearanceRenewal.map((item, index) => (
                    <tr className="permit-row-end" key={item.id || index}>
                      <td>
                        {new Date(item.date_from).toLocaleString() || "N/A"}
                      </td>
                      <td>
                        {new Date(item.date_to).toLocaleString() || "N/A"}
                      </td>
                      <td>
                        {new Date(item.date_to).toLocaleString() || "N/A"}
                      </td>
                      <td>
                        {item.extended_hours
                          ? `${item.extended_hours} Hrs`
                          : "N/A"}
                      </td>
                      <td>{item.additional_instruction || "NIL"}</td>
                      <td>
                        {item.issuer_name} ({item.issuer_designation})
                      </td>
                      <td>
                        {item.receiver_name
                          ? `${item.receiver_name} (${
                              item.receiver_designation || "N/A"
                            })`
                          : "N/A"}
                      </td>
                      <td>{item.safety_officer_name || "N/A"}</td>
                    </tr>
                  ))}
                </tbody> */}
                 <tbody>
                  {rows.map((row, index) => (
                    <tr className="permit-row-end" key={index}>
                      <td>{row.date_from || ""}</td>
                      <td>{row.date_to || ""}</td>
                      <td>{row.date_to || ""}</td>
                      <td>{row.extended_hours || ""}</td>
                      <td>{row.additional_instruction || ""}</td>
                      <td>
                        {row.issuer_name
                          ? `${row.issuer_name} (${row.issuer_designation})`
                          : ""}
                      </td>
                      <td>
                        {row.issuer_name
                          ? `${workDetails.receiver_name || "N/A"}`
                          : ""}
                      </td>
                      <td>{row.safety_officer_name || ""}</td>
                     
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template8;
