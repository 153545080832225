import React from "react";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";
import Template8 from "./Template8";
import Template9 from "./Template9";

const ColdWorkPermitTemplate = ({ permitDetails, isSidebarExpanded }) => {

  const {
    checklistItems = [{}],
    workDetails = {},
    form_data: formData = {},
    hazardsPrecautions = [{}],
    signatures = [{}],
    clearanceRenewal= [{}],
    permitClosure= {},
    generalInstructions = [],
  } = permitDetails || {};

  console.log("permitDetails in ColdWorkPermitTemplate:", permitDetails);
  console.log("Checklist in ColdWorkPermitTemplate:", checklistItems); // Updated here

  console.log("formData in ColdWorkPermitTemplate:", formData);

  return (
    // <div className="template-container">
    <div className="cold-work-template">
      <div id="section1">
        <Template1 />
        <Template2
          workDetails={workDetails}
          isSidebarExpanded={isSidebarExpanded}
        />
        <Template3 />
        <Template4
          checklist={checklistItems} // Passing checklistItems correctly
          isSidebarExpanded={isSidebarExpanded}
        />
        <Template5
          hazardsPrecautions={hazardsPrecautions}
          isSidebarExpanded={isSidebarExpanded}
        />
        <Template6
          signatures={signatures} formData={formData} workDetails={workDetails}
          isSidebarExpanded={isSidebarExpanded}
        />
      </div>
      <div id="section2">
        <Template7
          hazardsPrecautions={hazardsPrecautions}
          isSidebarExpanded={isSidebarExpanded}
        />
        <Template8  clearanceRenewal={clearanceRenewal} workDetails={workDetails}  isSidebarExpanded={isSidebarExpanded} />
        <Template9 permitClosure={permitClosure} workDetails={workDetails}/>
      </div>
    </div>
    // </div>
  );
};

export default ColdWorkPermitTemplate;
