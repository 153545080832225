import React, { useState, useRef, useEffect } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import useClickOutside from "../useClickOutside";

function SafetyInductionForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEC, setSelectedEC] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [ecData, setEcData] = useState([]); // Store the API response data
  const [ecOptions, setEcOptions] = useState([]); // Dropdown options
  const [validationErrors, setValidationErrors] = useState({
    category: "",
    ec: "",
    employee_name: "",
    safety_induction_report: "",
  });
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const containerRef = useRef(null);
  const fetchECNo = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_ec_no`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setEcData(data);

        // Map the API response to the options for the dropdown
        const options = data.map((item) => ({
          value: item.id,
          label: item.ec_no,
          person_name: item.employee_name,
        }));
        setEcOptions(options);
      } else {
        showSnackbar("Failed to fetch EC Numbers.", "error");
      }
    } catch (error) {
      showSnackbar("An error occurred while fetching EC Numbers.", "error");
      console.error("Error fetching EC Numbers:", error);
    }
  };

  useEffect(() => {
    fetchECNo();
  }, []);

  const handleClosePopup = () => {
    // setIsSortPopup(false);
    setIsPopupOpen(false);
  };

  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);

  const handleUploadButtonClick = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  // Options for the category dropdown
  const categoryOptions = [
    { value: "Employee", label: "Employee" },
    { value: "Visitor", label: "Visitor" },
    { value: "TemporaryWorker", label: "Temporary Worker" },
  ];

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);

    // Reset fields when category changes
    if (selectedOption && selectedOption.value === "Employee") {
      setSelectedEC(null);
      setSelectedEmployee(null);
      setEmployeeName(""); // Reset employee name
    } else {
      setEmployeeName(""); // Reset employee name for non-employee categories
    }
  };

  const handleECChange = (selectedOption) => {
    setSelectedEC(selectedOption); // Set the EC No selection
    const selectedEmployee = ecData.find(
      (ec) => ec.id === selectedOption.value
    );
    if (selectedEmployee) {
      setEmployeeName(selectedEmployee.person_name); // Auto-fill employee name from EC No
    } else {
      setEmployeeName(""); // Clear employee name if no match
    }
  };

  const handleEmployeeNameChange = (e) => {
    setEmployeeName(e.target.value);
    setSelectedEC(null);
  };

  const handleCancelClick = () => {
    navigate("/safety-induction");
  };

  const handleSaveClick = async () => {
    // Reset previous errors
    setValidationErrors({
      category: "",
      ec: "",
      employee_name: "",
      safety_induction_report: "",
    });

    const formData = new FormData();
    const authKey = localStorage.getItem("authKey");

    let isValid = true;

    // Validation
    if (!selectedCategory) {
      setValidationErrors((prev) => ({
        ...prev,
        category: "Category is required",
      }));
      isValid = false;
    } else {
      formData.append("category", selectedCategory.value);
    }

    if (selectedCategory?.value === "Employee" && !selectedEC) {
      setValidationErrors((prev) => ({ ...prev, ec: "EC No is required" }));
      isValid = false;
    } else if (selectedEC) {
      formData.append("actual_man_power_id", selectedEC.value);
    }

    const finalEmployeeName = selectedEmployee || employeeName;
    if (!finalEmployeeName) {
      setValidationErrors((prev) => ({
        ...prev,
        employee_name: "Employee name is required",
      }));
      isValid = false;
    } else {
      formData.append("employee_name", finalEmployeeName);
    }

    if (selectedFiles.length === 0) {
      setValidationErrors((prev) => ({
        ...prev,
        safety_induction_report: "Safety Induction Report is required",
      }));
      isValid = false;
    } else {
      selectedFiles.forEach((file) => {
        formData.append("safety_induction_report", file);
      });
    }

    if (!isValid) return; // If validation failed, don't proceed with form submission.

    try {
      setIsLoading(true);

      const response = await axios.post(
        `${API_URL}/api/add_safety_induction`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Safety Induction added successfully");
        setTimeout(() => {
          navigate("/safety-induction");
        }, 1000);
      }
    } catch (error) {
      showSnackbar("Error saving Safety Induction.", "error");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Safety Induction
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="DepartmentName">
                    Category<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    placeholder="Select a category..."
                    isClearable
                  />
                  {validationErrors.category && (
                    <div className="error-message">
                      {validationErrors.category}
                    </div>
                  )}
                </div>
                {selectedCategory && selectedCategory.value === "Employee" && (
                  <div className="form-group-select">
                    <label htmlFor="ec">
                      EC No <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="ec"
                      value={selectedEC}
                      onChange={handleECChange}
                      options={ecOptions}
                      placeholder="Select an EC..."
                      isClearable
                    />
                    {validationErrors.ec && (
                      <div className="error-message">{validationErrors.ec}</div>
                    )}
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="employee_name">
                    Name of Employee <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="employee_name"
                      value={employeeName}
                      onChange={handleEmployeeNameChange}
                      placeholder="Enter name of employee..."
                    />
                  </div>
                  {validationErrors.employee_name && (
                    <div className="error-message">
                      {validationErrors.employee_name}
                    </div>
                  )}
                </div>

                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="gst_attachments">
                      Safety Induction Report{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => {
                        handleUploadButtonClick(fileInputRef);
                      }}
                      ref={buttonRef}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      id="gst_attachments"
                      ref={fileInputRef}
                      onChange={(e) =>
                        handleFileInputChange(e.target.files, setSelectedFiles)
                      }
                      style={{ display: "none" }}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                      <div className="attach-popup-amc1" ref={popupRef}>
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <span>
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Error Message for Safety Induction Report */}
                    {validationErrors.safety_induction_report && (
                      <div className="error-message">
                        <div>{validationErrors.safety_induction_report}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default SafetyInductionForm;
