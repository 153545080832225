import React from "react";
import { useParams } from "react-router-dom";

const HotWorkTemplate2 = ({ workDetails }) => {
  const { formId } = useParams();
  console.log("ID from URL:", formId);

  return (
    <>
      <div className="hot-template-container">
        <div className="template-container-content">
          <div className="template-container-pera">
            <div className="">
              वर्क क्लीयरेंस से / Work clearance from <span className="work-text">.............</span> बजे, दिनांक /hrs. of date <span className="work-text">.................. तक/To</span> बजे, दिनांक/hrs. of date .............
            </div>
            <div className="">
              (नवीनीकृत न होने तक पारी के लिए वैध / valid for the shift unless renewed).
            </div>
            <div className="">
              किस (विभाग/अनुभाग/ ठेकेदार) को जारी किया गया / Issued to (Department/Section/Contractor) <span className="work-text">.....................................................</span>
            </div>
            <div className="">
              कार्य का वास्तविक स्थान/ क्षेत्र/ इकाई /उपकरण सं इत्यादि / Exact location of work (Area/Unit/Equipment No. etc.) <span className="work-text">.......................................................</span>
            </div>
            <div className="">
              कार्य का ब्यौरा / Description of work: <span className="work-text">..........................................................................................................................</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotWorkTemplate2;
