import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import DatePicker from "react-datepicker";
import API_URL from "../../src/Config";
import { format } from "date-fns";
import Select, { components } from "react-select";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";

function EditAmcForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const { id } = useParams();
  const [msmeChecked, setMsmeChecked] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [hasReminder, setHasReminder] = useState(false);
  const [daysBefore, setDaysBefore] = useState("");
  const [frequencyReminder, setFrequencyReminder] = useState("");
  const [amcData, setAmcData] = useState({
    amc_name: "",
    department_id: "",
    department_name: "",
    user_id: "",
    user_name: "",
    service_provider_name: "",
    service_provider_id: "",
    start_date: null,
    end_date: null,
    po_date: null,
    po_no: "",
    preventive_no: "",
    breakdown_no: "",
    services: "",
    days_before: "",
    has_reminder: "",
    frequency_reminder: "",
    schedule_start_date: "",
  });
  const { theme } = useContext(ThemeContext);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedPODate, setSelectedPODate] = useState(null);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [yesHasReminder, setYesHasReminder] = useState(false);
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAmcData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_amc/${id}`, {
          headers: { Authorization: authKey },
        });
        const data = response.data;
        setAmcData(data);
        setSelectedStartDate(
          data.start_date ? new Date(data.start_date) : null
        );
        setSelectedEndDate(data.end_date ? new Date(data.end_date) : null);
        setSelectedScheduleDate(
          data.schedule_start_date ? new Date(data.schedule_start_date) : null
        );
        setSelectedPODate(data.po_date ? new Date(data.po_date) : null);
        setSelectedUser({ label: data.user_name, value: data.user_id });
        setSelectedDepartment({
          label: data.department_name,
          value: data.department_id,
        });
        setSelectedVendor({
          label: data.service_provider_name,
          value: data.service_provider_id,
        });
        setYesHasReminder(data.Isreminder === 1);
        setHasReminder(data.Isreminder === 0);
        setDaysBefore(data.days_before);
        setFrequencyReminder(data.reminder_frequency);
      } catch (error) {
        console.error("Error fetching AMC data:", error.message);
      }
    };
    fetchAmcData();
  }, [id]);


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   const trimmedValue = value.trim(); // Trim the input value

  //   // Update state for amcData
  //   setAmcData((prevData) => ({
  //     ...prevData,
  //     [name]: trimmedValue,
  //   }));

  //   // Clear error message for this field if value is not empty
  //   if (trimmedValue) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: "",
  //     }));
  //   }
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAmcData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
   
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleRadioChange = (e) => {
    setYesHasReminder(e.target.value === "1");
    setHasReminder(e.target.value === "0");
    // if (e.target.value === "0") {
    //   setDaysBefore("");
    // }
  };

  const handleDaysBeforeChange = (e) => {
    setDaysBefore(e.target.value);
  };
  const handleReminderFrequencyChange = (e) => {
    setFrequencyReminder(e.target.value);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setAmcData((prevState) => ({
      ...prevState,
      start_date: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      start_date: null,
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setAmcData((prevState) => ({
      ...prevState,
      end_date: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      end_date: null,
    }));
  };
  const handlePODateChange = (date) => {
    setSelectedPODate(date);
    setAmcData((prevState) => ({
      ...prevState,
      po_date: date,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      po_date: null,
    }));
  };

  const handleCancelClick = () => {
    // navigate("/amc-configuration");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Construct payload with necessary fields
  //     const payload = {
  //       ...amcData,
  //       start_date: selectedStartDate ? format(selectedStartDate, "yyyy-MM-dd") : "",
  //       end_date: selectedEndDate ? format(selectedEndDate, "yyyy-MM-dd") : "",
  //       po_date: selectedPODate ? format(selectedPODate, "yyyy-MM-dd") : "",
  //       schedule_start_date: selectedScheduleDate ? format(selectedScheduleDate, "yyyy-MM-dd") : "",
  //       has_reminder: yesHasReminder ? "1" : "0", // Determine value based on condition
  //     days_before: yesHasReminder ? daysBefore : "", // Set days_before only if reminder is yes
  //     frequency_reminder: yesHasReminder ? frequencyReminder : "", // Set frequency_reminder only if reminder is yes
  //     };

  //     // Send JSON data
  //     const response = await axios.put(
  //       `${API_URL}/api/update_amc/${id}`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Data updated successfully");
  //       setTimeout(() => {
  //         navigate("/amc-configuration");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error updating AMC:", error.response ? {
  //       status: error.response.status,
  //       data: error.response.data,
  //       headers: error.response.headers,
  //     } : error.message);
  //     setError("An error occurred while updating AMC data");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    const validationErrors = validateForm();
    console.log("Validation Errors:", validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set validation errors to state
      console.log("Errors set:", validationErrors);
      return; // Prevent saving if there are errors
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      console.log("Auth Key:", authKey);

      // Ensure trimmed values in the payload
      const trimmedAmcData = {
        ...amcData,
        amc_name: amcData.amc_name?.trim() || "",
        po_no: amcData.po_no?.trim() || "",
        services: amcData.services?.trim() || "",
        preventive_no: amcData.preventive_no?.trim() || "",
        breakdown_no: amcData.breakdown_no?.trim() || "",
      };
      console.log("Trimmed AMC Data:", trimmedAmcData);

      // Construct the payload with formatted dates and trimmed data
      const payload = {
        ...trimmedAmcData,
        start_date: selectedStartDate
          ? format(selectedStartDate, "yyyy-MM-dd")
          : "",
        end_date: selectedEndDate ? format(selectedEndDate, "yyyy-MM-dd") : "",
        po_date: selectedPODate ? format(selectedPODate, "yyyy-MM-dd") : "",
        schedule_start_date: selectedScheduleDate
          ? format(selectedScheduleDate, "yyyy-MM-dd")
          : "",
        has_reminder: yesHasReminder ? "1" : "0",
        days_before: yesHasReminder ? daysBefore : "",
        frequency_reminder: yesHasReminder ? frequencyReminder : "",
      };
      console.log("Payload:", payload);

      // Make the API call
      const response = await axios.put(
        `${API_URL}/api/update_amc/${id}`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/amc-configuration");
        }, 1000);
      }
    } catch (error) {
      console.error(
        "Error updating AMC:",
        error.response
          ? {
              status: error.response.status,
              data: error.response.data,
              headers: error.response.headers,
            }
          : error.message
      );
      setError("An error occurred while updating AMC data");
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (
      !selectedDepartment ||
      typeof selectedDepartment.value !== "string" ||
      !selectedDepartment.value.trim()
    ) {
      errors.department = "Department is required.";
    }
    if (
      !selectedUser ||
      typeof selectedUser.value !== "string" ||
      !selectedUser.value.trim()
    ) {
      errors.user = "User is required.";
    }
    if (!amcData.amc_name || !amcData.amc_name.trim()) {
      errors.amc_name = "AMC Name is required.";
    }
    if (!selectedVendor) {
      errors.contractor_name = "Service Provider is required.";
    }
    if (!selectedStartDate) {
      errors.start_date = "Start Date is required.";
    }
    if (!selectedEndDate) {
      errors.end_date = "End Date is required.";
    }
    if (!selectedScheduleDate) {
      errors.schedule_start_date = "Schedule Start Date is required.";
    }
    if (!amcData.po_no || !amcData.po_no.trim()) {
      errors.po_no = "PO Number is required.";
    }
    if (!selectedPODate) {
      errors.po_date = "PO Date is required.";
    }
    if (!amcData.preventive_no) {
      errors.preventive_no = "No. of Preventive Visits is required.";
    }
    if (!amcData.breakdown_no) {
      errors.breakdown_no = "No. of Breakdown Visits is required.";
    }

    if (!amcData.services || !amcData.services.trim()) {
      errors.services = "Service Description is required.";
    }

    return errors;
  };

  const handleBackProject = () => {
    navigate("/amc-configuration");
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setAmcData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      department: null,
    }));
  };

  const fetchUserData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_user`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        const options = Array.isArray(data)
          ? data.map((user) => ({
              label: user.Name,
              value: user.ID,
            }))
          : [];
        setUsers(options);
      } else {
        console.error(`Error fetching user data: ${response.statusText}`);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setUsers([]);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    setAmcData((prevFormData) => ({
      ...prevFormData,
      user_name: selectedOption ? selectedOption.label : "",
      user_id: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      user: null,
    }));
  };

  const handleUserMenuOpen = () => {
    setUserMenuIsOpen(true);
  };
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setAmcData((prevFormData) => ({
      ...prevFormData,
      service_provider_name: selectedOption ? selectedOption.label : "",
      service_provider_id: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      contractor_name: null,
    }));
  };
  const fetchVendors = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_vendors_procurement`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      // Assuming response.data is already parsed as JSON
      const options = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendors(options);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);
  const handleScheduleDateChange = (date) => {
    if (date < selectedStartDate || date > selectedEndDate) {
      // Show an error message or handle invalid date
      setErrors((prevErrors) => ({
        ...prevErrors,
        schedule_start_date:
          "Schedule start date must be between start date and end date",
      }));
    } else {
      // Clear any previous errors related to schedule start date
      setErrors((prevErrors) => ({
        ...prevErrors,
        schedule_start_date: null,
      }));

      setSelectedScheduleDate(date);
      setAmcData((prevState) => ({
        ...prevState,
        schedule_start_date: date,
      }));
    }
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Amc
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.department && (
                    <div className="error-message">{errors.department}</div>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="user">
                    User<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="user"
                    options={users}
                    value={selectedUser}
                    onChange={handleUserChange}
                    // components={{ Control: CustomUserControl, Menu: CustomUserMenu }}
                    placeholder="Select User"
                    isSearchable
                    menuIsOpen={userMenuIsOpen}
                    onMenuOpen={handleUserMenuOpen}
                    onMenuClose={() => setUserMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.user && (
                    <div className="error-message">{errors.user}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="amc_name">
                    AMC Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="amc_name"
                      value={amcData.amc_name}
                      onChange={handleInputChange}
                      placeholder="Enter AMC name"
                    />
                  </div>
                  {errors.amc_name && (
                    <div className="error-message">{errors.amc_name}</div>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="contractor_name">
                    Service Provider<span className="text-danger">*</span>
                  </label>

                    <Select
                    id="contractor_name"
                    options={vendors}
                    value={selectedVendor}
                    onChange={handleVendorChange}
                    placeholder="Select Service Provider"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.contractor_name && (
                    <div className="error-message">
                      {errors.contractor_name}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="start_date">
                    Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      name="start_date"
                      id="start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select start date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.start_date && (
                    <div className="error-message">{errors.start_date}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="end_date">
                    End Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      name="end_date"
                      id="end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select end date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.end_date && (
                    <div className="error-message">{errors.end_date}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="schedule_start_date">
                    Schedule Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedScheduleDate}
                      onChange={handleScheduleDateChange}
                      name="schedule_start_date"
                      id="schedule_start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select schedule start date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      minDate={selectedStartDate} // Setting the minimum date for DatePicker
                      maxDate={selectedEndDate} // Setting the maximum date for DatePicker
                    />
                  </div>
                  {errors.schedule_start_date && (
                    <div className="error-message">
                      {errors.schedule_start_date}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="po_no">
                    PO Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="po_no"
                      value={amcData.po_no}
                      onChange={handleInputChange}
                      placeholder="Enter po number"
                    />
                  </div>
                  {errors.po_no && (
                    <div className="error-message">{errors.po_no}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="end_date">
                    PO Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedPODate}
                      onChange={handlePODateChange}
                      name="po_date"
                      id="po_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select po date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.po_date && (
                    <div className="error-message">{errors.po_date}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="preventive_no">
                    No. of Preventive Visits
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="preventive_no"
                      value={amcData.preventive_no}
                      onChange={handleInputChange}
                      placeholder="Enter no of  preventive digit"
                    />
                  </div>
                  {errors.preventive_no && (
                    <div className="error-message">{errors.preventive_no}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="breakdown_no">
                    No. of Breakdown Visits
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="breakdown_no"
                      value={amcData.breakdown_no}
                      onChange={handleInputChange}
                      placeholder="Enter no of breakdown digit"
                    />
                  </div>
                  {errors.breakdown_no && (
                    <div className="error-message">{errors.breakdown_no}</div>
                  )}
                </div>
                <div className="form-group msmme">
                  <label htmlFor="has_reminder">Set Reminder</label>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="has_reminder"
                      value="1"
                      checked={yesHasReminder}
                      onChange={handleRadioChange}
                    />
                    <label>Yes</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="has_reminder"
                      value="0"
                      checked={hasReminder}
                      onChange={handleRadioChange}
                    />
                    <label>No</label>
                  </div>
                </div>
                {yesHasReminder && (
                  <>
                    <div className="form-group ">
                      <label htmlFor="daysBefore">Days Before</label>
                      <div className="input-group">
                        <input
                          type="number"
                          value={daysBefore}
                          onChange={handleDaysBeforeChange}
                          min="1"
                          placeholder="Enter no of days"
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <label htmlFor="frequencyReminder">
                        Reminder Frequency
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          value={frequencyReminder}
                          onChange={handleReminderFrequencyChange}
                          min="1"
                          placeholder="Enter remider frequency in days"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group">
                  <label htmlFor="services">
                    Service Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="services"
                      rows={4}
                      value={amcData.services}
                      onChange={handleInputChange}
                      placeholder="Enter service description"
                    />
                  </div>
                  {errors.services && (
                    <div className="error-message">{errors.services}</div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditAmcForm;
