import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";

function ReminderDetails({ isSidebarExpanded, customerID }) {
    const [reminders, setReminders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const { department_id, due_type } = useParams();

    useEffect(() => {
        fetchData();
    }, [department_id, due_type]);

    const containerRef = useRef(null);


    const fetchData = async () => {
        try {
            setLoading(true);
            const authKey = localStorage.getItem("authKey");

            const response = await axios.get(`${API_URL}/get_reminder_details/${department_id}/${due_type}`, {
                headers: {
                    Authorization: authKey,
                },
            });

            setReminders(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching customers data:", error.message);
            setLoading(false);
        }
    };



    useEffect(() => {
        const delay = setTimeout(() => {
            // handleSearch();
        }, 600);
        return () => clearTimeout(delay);
    }, [searchInput]);

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setSelectedRows(
            newSelectAll ? reminders.map((reminder) => reminder.ID) : []
        );
        setChecked(newSelectAll);
    };

    const handleSelectRow = (customerID) => {
        const updatedSelectedRows = selectedRows.includes(customerID)
            ? selectedRows.filter((id) => id !== customerID)
            : [...selectedRows, customerID];

        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === reminders.length);
        setChecked(updatedSelectedRows.length > 0);
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };


    const handleCloseButtonClick = () => {
        setChecked(false);
        setSelectedRows([]);
    };


    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };


    const handleRefresh = () => {
        fetchData();
    };
    const closePopups = () => {
        setIsDeletePopup(false);
    };

    return (
        <>
            <div
                className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
               
                    <div className="data-list-content" ref={containerRef}>
                        {isChecked ? (
                            <div className="action-header">
                                <div className="action-header-content">
                                    <div className="action-header-content-leftside">
                                        <div className="action-button">
                                         
                                        
                                        </div>
                                    </div>
                                    <div className="action-header-content-rightside">
                                        <button
                                            className="action-header-close-button"
                                            onClick={handleCloseButtonClick}
                                        >
                                            <IoCloseOutline className="action-header-close-icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="data-list-header">
                                <div className="data-list-header-content">
                                    <div className="data-list-heading-content-left-side">
                                        Reminder Details
                                    </div>
                                    <div className="data-list-heading-content-right-side">
                                        <div className="search-bar-container">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                                className="search-bar"
                                            />
                                            <IoSearchOutline className="search-bar-icon" />
                                        </div>

                                        <Link to="/customer-form" className="data-list-new">
                                            <Tooltip title="New Customer" arrow>
                                                <button className="new-button">
                                                    <HiOutlinePlus className="new-plus-icon" /> New
                                                </button>
                                            </Tooltip>
                                        </Link>

                                        <Tooltip title="Refresh List" arrow>
                                            <button
                                                className="refresh-button"
                                                onClick={handleRefresh}
                                            >
                                                <TbRefresh className="refresh-icon" />
                                            </button>
                                        </Tooltip>
                                        {/* <button className="sort-button" onClick={handleSortpopup}>
                                            <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                                        </button>
                                        {isSortPopup && (
                                            <div className="sort-popup-container">
                                                <div className="sort-popup-content">
                                                    <div className="sort-by">
                                                        SORT BY{" "}
                                                        {isAscending ? "(Ascending)" : "(Descending)"}
                                                    </div>
                                                    <div className="sort-by-button"></div>
                                                    <button
                                                        className="delete-item"
                                                        onClick={handleArchiveDeparment}
                                                    >
                                                        Archived Customer
                                                    </button>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="data-list-body" onClick={closePopups}>
                            <div className="data-list-scroll-body">
                                <div className="data-list-table">
                                    {loading ? (
                                        <div className="loader-container">
                                            <div className="loader"></div>
                                            <div>Loading...</div>
                                        </div>
                                    ) : reminders.length > 0 ? (
                                        <table className="table multilevel-data">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </th>
                                                    <th>SR. NO.</th>
                                                    <th>TAG NO</th>
                                                    <th>STATUS</th>
                                                    <th>SERVICE DUE DATE</th>
                                                    <th>LAST SERVICED DATE</th>

                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="data-list-scroll-bodys">
                                                {reminders.map((reminder, index) => (
                                                    <tr key={reminder.ID} className="hoverable-row">
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedRows.includes(reminder.ID)}
                                                                onChange={() => handleSelectRow(reminder.ID)}
                                                            />
                                                        </td>

                                                        <td>{index + 1}</td>
                                                        <td>{reminder.equipment_tag_no || "NA"}</td>
                                                        <td className="status-schedules">
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        reminder.status === "Hold"
                                                                            ? "#D4AC0D"
                                                                            : reminder.status === "Cancelled"
                                                                                ? "#F81700"
                                                                                : reminder.status === "not in tenure"
                                                                                    ? "#313639"
                                                                                    : reminder.status === "overdue"
                                                                                        ? "#C0612B"
                                                                                        : reminder.status === "active"
                                                                                            ? "#2980B9"
                                                                                            : reminder.status === "In Progress"
                                                                                                ? "#df7f26"
                                                                                                : reminder.status === "Completed"
                                                                                                    ? "#2E8B57"
                                                                                                    : "transparent",
                                                                }}
                                                            >
                                                                {reminder.status || "NA"}
                                                            </div>
                                                        </td>
                                                        <td>{reminder.service_due_date}</td>
                                                        <td>{reminder.last_serviced_date || "NA"}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="no-users-container">
                                            <div className="no-users-message">No Reminder Found</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "35px", marginLeft: "20px" }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="success">
                    {/* {snackbarMessage} */}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default ReminderDetails;
