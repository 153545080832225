import React from "react";
import "./Template1.css";

const Template3 = (isSidebarExpanded) => {
  return (
    <>
        <div className="template-container">
            <div className="template-container-header">
            <div className="line1">अनुमति पत्र जारी करने के पहले निम्नलिखित मदों की जाँच की जाए</div>
            <div className="line2">THE FOLLOWING ITEMS SHALL BE CHECKED BEFORE USING THE PERMIT</div>
            <div className="line3">(कृपया संबंधित वाक्यो को चिन्हित करें। (*) चिंन्ह से चिन्हित जाँच सूची मदें प्राप्तकर्ता द्वारा अनुपालित की जाएँ।)</div>
            <div className="line4">( Tick mark in the Appropriate box. Checklist items marked with asterisk (*) shall be complied by receiver)</div>
            
          </div>
        </div>
    </>
  );
};

export default Template3;
