import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select  from "react-select";

function WorkPermitConfig({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [permitType, setPermitType] = useState([]);
  const [selectedPermitType, setSelectedPermitType] = useState(null);

  const [departmentData, setDepartmentData] = useState({
    permit_type_id: "",
    receiver_name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate("/permit-requests");
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Validate required fields
      if (!departmentData.permit_type_id || !departmentData.receiver_name) {
        setErrors({
          permit_type_id: !departmentData.permit_type_id ? "Permit Type is required" : "",
          receiver_name: !departmentData.receiver_name ? "Receiver Name is required" : "",
        });
        setSeverity("error");
        showSnackbar("Please fill all required fields.");
        setIsLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append("permit_type_id", departmentData.permit_type_id);
      formData.append("receiver_name", departmentData.receiver_name);
  
      const response = await axios.post(`${API_URL}/api/add_form`, formData, {
        headers: {
          Authorization: authKey,
        },
      });
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Form added successfully");
        setTimeout(() => {
          navigate("/permit-requests");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving form:", error.message);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message || "An error occurred while saving the form"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const fetchPermitType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_work_permit_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      const permitTypeOptions = response.data.work_permit_types.map((permitType) => ({
        value: permitType.id,
        label: permitType.work_permit_name,
      }));

      setPermitType(permitTypeOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchPermitType();
  }, []);

  const handlePermitTypeChange = (selectedOption) => {
    setSelectedPermitType(selectedOption);
    setDepartmentData((prevState) => ({
      ...prevState,
      permit_type_id: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Cold Work Permit Config
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="form_type">
                    Permit Type<span className="text-danger">*</span>
                  </label>
                 
                    {/* <input
                      type="text"
                      name="form_type"
                      value={departmentData.form_type}
                      onChange={handleInputChange}
                      placeholder="Enter form type"
                    /> */}
                    <Select
                    id="form_type"
                    options={permitType}
                    value={selectedPermitType}
                    onChange={handlePermitTypeChange}
                    placeholder="Select permit type"
                    />
                 
                  {errors.form_type && (
                      <div className="error-message">{errors.form_type}</div>
                    )}
                </div>
                <div className="form-group">
                  <label htmlFor="receiver_name">
                    Receiver Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="receiver_name"
                      value={departmentData.receiver_name}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />
                    
                  </div>
                  {errors.receiver_name && (
                      <div className="error-message">{errors.receiver_name}</div>
                    )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default WorkPermitConfig;
