import React, { useState } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function EmailTemplate({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState({
    to_emails: "",
    subject: "",
    body: "",
    temp_name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleCancelClick = () => {
    navigate("/email-templates");
  };

  const validateInputs = () => {
    const newErrors = {};
    const emailList = emailData.to_emails
      .split(",")
      .map((email) => email.trim());

    if (!emailData.to_emails) {
      newErrors.to_emails = "To emails are required.";
    } else {
      const invalidEmails = emailList.filter(
        (email) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      );
      if (invalidEmails.length) {
        newErrors.to_emails = `Invalid emails: ${invalidEmails.join(", ")}`;
      }
    }

    if (!emailData.subject) {
      newErrors.subject = "Subject is required.";
    }

    if (!emailData.body) {
      newErrors.body = "Body is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validateInputs()) return;

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const payload = {
        ...emailData,
        to_emails: emailData.to_emails.split(",").map((email) => email.trim()),
      };

      const response = await axios.post(
        `${API_URL}/api/add_email_template`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Email template added successfully");
        setTimeout(() => {
          navigate("/email-templates");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving email template:", error.message);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while saving the email template"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Email Configuration Template
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
              <div className="form-group">
                  <label htmlFor="temp_name">
                    Template Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="temp_name"
                      name="temp_name"
                      value={emailData.temp_name}
                      onChange={handleInputChange}
                      placeholder="Enter tempalte name"
                    />
                    {errors.to_emails && (
                      <div className="error-message">{errors.temp_name}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="to_emails">
                    To<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="to_emails"
                      name="to_emails"
                      value={emailData.to_emails}
                      onChange={handleInputChange}
                      placeholder="Enter recipient emails (comma-separated)"
                    />
                    {errors.to_emails && (
                      <div className="error-message">{errors.to_emails}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="subject">
                    Subject<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      id="subject"
                      name="subject"
                      value={emailData.subject}
                      onChange={handleInputChange}
                      placeholder="Enter subject"
                    />
                    {errors.subject && (
                      <div className="error-message">{errors.subject}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="body">
                    Body<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      id="body"
                      name="body"
                      value={emailData.body}
                      onChange={handleInputChange}
                      placeholder="Enter email body"
                    />

                    {errors.body && (
                      <div className="error-message">{errors.body}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EmailTemplate;
