import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import { MdAttachFile } from "react-icons/md";


function ArchivedSafetyInduction({ isSidebarExpanded, departmentID }) {
  const [SafetyInduction, setSafetyInduction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const popupRef = useRef(null);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };
  const handleIconClick = (index) => {
    // setIsPopupOpen(inquiryID);
    setIsPopupOpen(index === isPopupOpen ? null : index);
    setSelectedRowIndex(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_archived_safety_inductions`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setSafetyInduction(response.data.SafetyInductions);
      setTotalItems(response.data.TotalItems);
      // fetchData()
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
      setLoading(false);
    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll
        ? SafetyInduction.map((subDepartment) => subDepartment.id)
        : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (subDepartmentID) => {
    const updatedSelectedRows = selectedRows.includes(subDepartmentID)
      ? selectedRows.filter((id) => id !== subDepartmentID)
      : [...selectedRows, subDepartmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === SafetyInduction.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleRestore = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const apiUrl = `${API_URL}/api/unarchive_safety_induction`;

  //     const response = await fetch(apiUrl, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: authKey,
  //       },
  //       body: JSON.stringify({ ids: selectedRows }),
  //     });

  //     if (response.ok) {
  //       showSnackbar("Sub departments restore successfully");
  //       fetchData();
  //       setSelectedRows([]);
  //       setSelectAll(false);
  //       setChecked(false);
  //     } else {
  //       console.error("Failed to delete departments");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while deleting departments:", error);
  //   }
  // };


  const handleRestore = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/unarchive_safety_induction`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar('Data Restored successfully');
        const updatedCustomers = SafetyInduction.filter(
          (customer) => !selectedRows.includes(customer.id)
        );
        setSafetyInduction(updatedCustomers);
        fetchData()
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete customers");
      }
    } catch (error) {
      console.error("An error occurred while deleting customers:", error);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_archive_safety_inductions`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setSafetyInduction(response.data.Items);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-sub-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleBackCompany = () => {
    navigate("/safety-induction");
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                  <button onClick={handleRestore} className="delete">Unarchive</button>
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    <Tooltip title="Back" arrow>
                      {" "}
                      <IoMdArrowBack
                        className="back-company"
                        onClick={handleBackCompany}
                      />{" "}
                    </Tooltip>
                    Archived Safety Induction
                  </div>

                  <div className="data-list-heading-content-right-side">
                  <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>
                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : SafetyInduction.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>CATEGORY</th>
                          <th>EC NO</th>
                          <th>EMPLOYEE NAME</th>
                          <th>Attachments</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {SafetyInduction.map((department, index) => (
                          <tr key={department.ID} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(department.id)}
                                onChange={() => handleSelectRow(department.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{department.category || "NA"}</td>
                            <td>{department.ec_no || "NA"}</td>
                            <td>{department.employee_name || "NA"}</td>
                            {/* <td>
                              <span
                                className="inquiry-file-btns"
                                onClick={() => handleIconClick(index)}
                                ref={buttonRef}
                              >
                                <MdAttachFile className="inquiry-file-icon" />
                              </span>
                              {isPopupOpen === index && (
                                <div
                                  className="inquiry-attach-popup Whole-popup"
                                  ref={popupRef}
                                >
                                  <div className="attach-popup-contant">
                                    <div className="attachment-header">
                                      <div className="attachment-header-content">
                                        Attachments
                                      </div>
                                      <button
                                        className="fiile-close-btn"
                                        onClick={handleCloseAttachment}
                                      >
                                        <IoCloseOutline className="file-close-icon" />
                                      </button>
                                    </div>
                                    <div
                                      className="attach-popup-contant-body"
                                      key={index}
                                    >
                                      <div className="inquiry-attach-file-containerssss">
                                        <div className="attach-file-showsss">
                                          <div className="text-attachmentss">
                                            {department.files &&
                                            department.files.length > 0 ? (
                                              <div className="attach-popup-content-body">
                                                {department.files.map(
                                                  (file, attachmentIndex) => (
                                                    <div
                                                      className="inquiry-attach-file-container"
                                                      key={attachmentIndex}
                                                    >
                                                      <div className="inquiry-attacth-file-model">
                                                        <div className="attach-file-show">
                                                          <div className="text-attachment">
                                                            {file.filename
                                                              .length > 20
                                                              ? `${file.filename.substring(
                                                                  0,
                                                                  15
                                                                )}...`
                                                              : file.filename}
                                                          </div>
                                                          <div className="text-attachment-file">
                                                            File Size:{" "}
                                                            {file.file_size ||
                                                              "NA"}{" "}
                                                            KB
                                                          </div>
                                                        </div>
                                                        <div className="file-footer">
                                                          <a
                                                            href={`${API_URL}/api/download_attachment/${file.file_id}/${file.file_type}`}
                                                            download
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            Download
                                                          </a>
                                                          <a
                                                            href={`${API_URL}/api/view_attachment/${file.file_id}/${file.file_type}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            View
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="no-attachments">
                                                No attachments found
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="file-delete-btn"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td> */}

                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            <td>
                              <span
                                className="inquiry-file-btns"
                                onClick={() => handleIconClick(index)}
                                ref={buttonRef}
                              >
                                <MdAttachFile className="inquiry-file-icon" />
                              </span>
                              {isPopupOpen === index && (
                                <div
                                  className="inquiry-attach-popup Whole-popup"
                                  ref={popupRef}
                                >
                                  <div className="attach-popup-contant">
                                    <div className="attachment-header">
                                      <div className="attachment-header-content">
                                        Attachments
                                      </div>
                                      <button
                                        className="fiile-close-btn"
                                        onClick={handleCloseAttachment}
                                      >
                                        <IoCloseOutline className="file-close-icon" />
                                      </button>
                                    </div>
                                    <div
                                      className="attach-popup-contant-body"
                                      key={index}
                                    >
                                      <div className="inquiry-attach-file-containerssss">
                                        <div className="attach-file-showsss">
                                          <div className="text-attachmentss">
                                            {department.files &&
                                            department.files.length > 0 ? (
                                              <div className="attach-popup-content-body">
                                                {department.files.map(
                                                  (file, attachmentIndex) => (
                                                    <div
                                                      className="inquiry-attach-file-container"
                                                      key={attachmentIndex}
                                                    >
                                                      <div className="inquiry-attacth-file-model">
                                                        <div className="attach-file-show">
                                                          <div className="text-attachment">
                                                            {file.filename
                                                              .length > 20
                                                              ? `${file.filename.substring(
                                                                  0,
                                                                  15
                                                                )}...`
                                                              : file.filename}
                                                          </div>
                                                          <div className="text-attachment-file">
                                                            File Size:{" "}
                                                            {file.file_size ||
                                                              "NA"}{" "}
                                                            KB
                                                          </div>
                                                        </div>
                                                        <div className="file-footer">
                                                          <a
                                                            href={`${API_URL}/api/download_attachment/${file.file_id}/${file.file_type}`}
                                                            download
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            Download
                                                          </a>
                                                          <a
                                                            href={`${API_URL}/api/view_attachment/${file.file_id}/${file.file_type}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            View
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="no-attachments">
                                                No attachments found
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="file-delete-btn"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-safety-induction/${department.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Archived Safety Induction Found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <Pagination
          showPerPage={itemsPerPage}
          total={totalItems}
          onPaginationChange={handlePaginationChange}
        />

        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ArchivedSafetyInduction;
