import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './PaginationView.css';

const PaginationView = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNavigation = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      onPageChange(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="custom-pagination">
      <button
        className="custom-arrow custom-prev"
        onClick={() => handleNavigation("prev")}
        disabled={currentPage === 1}
      >
        <IoIosArrowBack className="custom-arrow-icon" />
      </button>
      <span className="custom-page-info">
        Page {currentPage} of {totalPages > 0 ? totalPages : 1}
      </span>
      <button
        className="custom-arrow custom-next"
        onClick={() => handleNavigation("next")}
        disabled={currentPage === totalPages || totalPages === 0}
      >
        <IoIosArrowForward className="custom-arrow-icon" />
      </button>
    </div>
  );
};

export default PaginationView;
