import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
import AddDepartmentPopup from "../Popups/AddDepartmentPopup";
import { format } from "date-fns";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
const { Control, Menu } = components;

function StatutoryComplianceForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tagNo, setTagNo] = useState([]);
  const [selectedTagNo, setSelectedTagNo] = useState(null); // State to hold selected TagNo
  const [statutoryData, setStatutoryData] = useState({
    department_name: "",
    equipment_type: "",
    tag_no: "",
    date: "",
    description: "",
  });
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const { theme } = useContext(ThemeContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStatutoryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };
  const handleEquipmentTypeMenuOpen = () => {
    setEquipmentTypeMenuIsOpen(true);
  };
  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectEdequipmentType(selectedOption);
    setStatutoryData((prevFormData) => ({
      ...prevFormData,
      equipment_type: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      equipment_type: "", // Clear the error message for the 'date' field
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const fetchTagNo = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_equipment`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Ensure the data is an array under the "Items" key
      if (response.data && Array.isArray(response.data.Items)) {
        const tagNoOptions = response.data.Items.map((item) => ({
          value: item.TagNo,
          label: item.TagNo,
        }));
        setTagNo(tagNoOptions);
      } else {
        console.error("Unexpected response format:", response.data);
        setTagNo([]);
      }
    } catch (error) {
      console.error("Error fetching TagNo:", error.message);
    }
  };

  useEffect(() => {
    fetchTagNo();
  }, []);

  const handleCancelClick = () => {
    navigate("/statutory-compliance");
  };


  const handleSaveClick = async () => {
    // Validation logic
    const newErrors = {};
    if (!statutoryData.department_name)
      newErrors.department_name = "Department is required";
    if (!statutoryData.equipment_type)
      newErrors.equipment_type = "Equipment Type is required";
    if (!statutoryData.tag_no) newErrors.tag_no = "Tag No is required";
    if (!selectedDate) newErrors.date = "Date is required";
    if (!statutoryData.description)
      newErrors.description = "Description is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent form submission
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const payload = {
        ...statutoryData,
        date: selectedDate ? format(selectedDate, "yyyy-MM-dd") : "",
      };
      const response = await axios.post(
        `${API_URL}/api/add_statutory_compliance`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Statutory compliance entry added successfully");
        setTimeout(() => {
          navigate("/statutory-compliance");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving statutory compliance:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setStatutoryData((prevState) => ({
      ...prevState,
      date: date,
    }));
    setErrors((prevState) => ({
      ...prevState,
      date: "", // Clear the error message for the 'date' field
    }));
  };

  const handleTagNoChange = (selectedOption) => {
    setSelectedTagNo(selectedOption);
    setStatutoryData((prevState) => ({
      ...prevState,
      tag_no: selectedOption.value, // Assuming selectedOption.value is the TagNo value
    }));
    setErrors((prevState) => ({
      ...prevState,
      tag_no: "", // Clear the error message for the 'date' field
    }));
  };

  const closeDepartmentPopup = (newDepartment) => {
    setShowDepartmentPopup(false);
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setStatutoryData((prevFormData) => ({
        ...prevFormData,
        Department: newDepartment.label,
        department_id: newDepartment.value,
        department_name: newDepartment.label,
      }));
    }
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };
  // const handleDepartmentChange = (selectedOption) => {
  //   setDepartments(selectedOption.label);
  //   setSelectedDepartment(selectedOption);
  //   setStatutoryData((prevFormData) => ({
  //     ...prevFormData,
  //     DepartmentName: selectedOption.label,
  //     DepartmentId: selectedOption.value,
  //   }));
  // };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setStatutoryData((prevFormData) => ({
      ...prevFormData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setErrors((prevState) => ({
      ...prevState,
      department_name: "", // Clear the error message for the 'date' field
    }));
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );
  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };
  const handleNewDepartment = (newDepartment) => {
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setStatutoryData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
      showSnackbar("Department added successfully");
    }
  };
  const fetchEquipmentType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Map the API response to the format expected by react-select
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Type");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Type:", error);
    }
  };

  useEffect(() => {
    fetchEquipmentType();
  }, []);

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Statutory Compliance
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.department_name && (
                    <p className="error-message">{errors.department_name}</p>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="equipment_type">
                    Equipment type<span className="text-danger">*</span>
                  </label>
                  {/* <div className="input-group">
                    <input
                      type="text"
                      name="equipment_type"
                      value={statutoryData.equipment_type}
                      onChange={handleInputChange}
                      placeholder="Enter equipment type"
                    />
                  </div> */}
                  <Select
                    id="equipment_type"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Equipment Type"
                    isSearchable
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.equipment_type && (
                    <p className="error-message">{errors.equipment_type}</p>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="tag_no">
                    Tag No.<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="tag_no"
                    options={tagNo} // Directly use the state which is now formatted as [{ value: ..., label: ... }]
                    value={selectedTagNo}
                    onChange={handleTagNoChange}
                    placeholder="Select tag no"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.tag_no && (
                    <p className="error-message">{errors.tag_no}</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="date">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.date && (
                    <p className="error-message">{errors.date}</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Statutory Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="description"
                      rows={4}
                      value={statutoryData.description}
                      onChange={handleInputChange}
                      placeholder=" Statutory Description"
                    />
                  </div>
                  {errors.description && (
                    <p className="error-message">{errors.description}</p>
                  )}
                </div>
              </div>
            </form>
            {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default StatutoryComplianceForm;