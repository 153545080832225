import React, { useState, useEffect, useRef, useCallback } from "react";
import "./AllPreventiveSchedules.css";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import debounce from 'lodash/debounce';
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Pagination from "../Component/Pagination";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import useClickOutside from '../useClickOutside';

function AllAmcSchedules({ isSidebarExpanded }) {
  const [amcschedules, setAmcSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    amc_name: true,
    status: true,
    service_date: true,
    service_provider_name: true,
  });
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const containerRef = useRef(null);
  const buttonRef = useRef(null)
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedAmc = [...amcschedules].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setAmcSchedules(sortedAmc);
  };
  const statusOptions = [
    { value: "all", label: "All" },
    { value: "active", label: "Active" },
    { value: "hold", label: "Hold" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "overdue", label: "Overdue" },
  ];

  const fetchSchedulesByStatus = async (status, currentPage, itemsPerPage) => {
    setLoading(true);
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_amc_schedules`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          status: status,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setAmcSchedules(response.data.schedules || []);
        setTotalItems(response.data.total); // Ensure TotalItems is used here

      } else {
        console.error(
          "Error fetching schedules by status:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching schedules by status:", error.message);
    } finally {
      setLoading(false);
    }
  };


  useClickOutside([containerRef, buttonRef], () => {
    if (isSortPopup) {
      setIsSortPopup(false);
      console.log("Clicked outside, popup closed");
    } else {
      console.log("notworking");
    }
  });


  // useEffect to handle fetching schedules and setting page and itemsPerPage
  useEffect(() => {
    localStorage.setItem("selectedStatus", selectedStatus);
    const storedPage = localStorage.getItem("currentPage");
    const storedItemsPerPage = localStorage.getItem("itemsPerPage");

    const page = storedPage ? parseInt(storedPage, 10) : 0;
    const itemsPerPage = storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : 50;
    setPage(page);
    setItemsPerPage(itemsPerPage);

    fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);
  }, [selectedStatus, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const debouncedSearch = useCallback(
    debounce(async (query, currentPage, itemsPerPage) => {
      if (query == null) {
        console.warn("Query is null or undefined. Skipping search.");
        return; // Prevent further execution if query is null
      }
      const normalizedQuery = query.toLowerCase(); // Use after checking

      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/search_amc_schedules`, {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: normalizedQuery,
            page: currentPage,
            per_page: itemsPerPage,
          },
        });

        if (response.status === 200) {
          setAmcSchedules(response.data.Items || []);
          setTotalItems(response.data.TotalItems);
          setPage(response.data.CurrentPage);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    }, 500),  // 500ms delay for debouncing
    []
  );

  const handleSearchInputChange = (event) => {
    const value = event.target.value.trim(); // Trim whitespace
    setSearchInput(value);
    setSelectedStatus("all");

    if (value) {
      debouncedSearch(value, page, itemsPerPage);
    } else {
      // Optionally reset schedules or handle the case when search input is empty
      fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);
    }
  };

  // Fetch data when the component mounts or on pagination changes
  useEffect(() => {
    if (!searchInput) {
      fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);
    }
  }, [selectedStatus, page, itemsPerPage, searchInput]);

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleRefresh = () => {
    setSearchInput("");
    setSelectedStatus("all");
    fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);

  };
  const closePopups = () => {
    setIsSortPopup(false);
  };

  const handleViewClick = async (schedule_id) => {
    navigate(`/amc-scheduler-view/${schedule_id}`);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedStatus(value);
    localStorage.setItem("selectedScheduleStatus", value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
          <div className="data-list-content" ref={containerRef}>
         
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All AMC Schedules
                  </div>

                  <div className="data-list-heading-content-right-side">
                    <div className="project-dropdown-schedules">
                      <FormControl fullWidth>
                        <InputLabel id="filter-select-label">Amc Status</InputLabel>
                        <Select
                          labelId="filter-select-label"
                          id="filter-select"
                          value={selectedStatus}
                          onChange={handleChange}
                          label="Status"
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("amc_name")}>
                              Name{" "}
                              {sortOrder.amc_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("service_provider_name")
                              }
                            >
                              Coontractor Name{" "}
                              {sortOrders.service_provider_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                              Status{" "}
                              {sortOrders.status === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("service_date")}>
                              Service Date{" "}
                              {sortOrders.service_date === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                          
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
        
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-bodys">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : amcschedules.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th className="center-alignment">SR. NO.</th>
                          <th>NAME</th>
                          <th>CONTRACTOR NAME</th>
                          <th className="center-alignment">SERVICE DATE</th>
                          <th className="center-alignment">STATUS</th>
                          <th>SERVICES</th>
                          <th className="center-alignment">ACTION</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {/* {sortedAmcschedules.map((amcschedule, index) => ( */}

                        {amcschedules.map((amcschedule, index) => (
                          <tr key={index} className="hoverable-row">

                            <td className="center-alignment">{amcschedule.serial_no}</td>
                            <td>{amcschedule.amc_name}</td>
                            <td>{amcschedule.service_provider_name}</td>
                            <td className="center-alignment">
                              {formatDate(amcschedule.service_date || "NA ")}
                            </td>
                            <td className="status-schedules center-alignment">
                              <div
                                style={{
                                  backgroundColor:
                                    amcschedule.status === "Hold"
                                      ? "#D4AC0D"
                                      : amcschedule.status === "Cancelled"
                                        ? "#F81700"
                                        : amcschedule.status === "not in tenure"
                                          ? "#313639"
                                          : amcschedule.status === "overdue"
                                            ? "#C0612B"
                                            : amcschedule.status === "active"
                                              ? "#2980B9"
                                              : amcschedule.status === "Completed"
                                                ? "#2E8B57"
                                                : "transparent",
                                  width: "6vw",
                                }}
                              >

                                {amcschedule.status
                                  ? amcschedule.status.charAt(0).toUpperCase() + amcschedule.status.slice(1).toLowerCase()
                                  : "NA"}
                              </div>
                            </td>

                            <td>{amcschedule.services || "NA "}</td>

                            <td className="center-alignment">
                              <button
                                onClick={() => handleViewClick(amcschedule.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Amc Schedules Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default AllAmcSchedules;