import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PreventiveReports from "./PreventiveReports";
import AmcReports from "./AmcReports";
import BreakdownReports from "./BreakdownReports";
import ProcurmentReports from "./ProcurmentReports";
import { Tabs, Tab } from "@mui/material";

function ReportPage({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(
    tabFromUrl || "preventive-schedule-reports" 
  );

  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
       
          <div className="data-list-content" ref={containerRef}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(event, newValue)}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab label="Preventive reports" value="preventive-schedule-reports" />
              <Tab label="AMC reports" value="amc-reports" />
              <Tab label="Breakdown reports" value="breakdown-reports" />
              <Tab label="Procurement reports" value="procurement-reports" />
            </Tabs>
            <div className="tabs-content">
              {tabValue === "preventive-schedule-reports" && <PreventiveReports />}
              {tabValue === "amc-reports" && <AmcReports />}
              {tabValue === "breakdown-reports" && <BreakdownReports />}
              {tabValue === "procurement-reports" && <ProcurmentReports />}
            </div>
          </div>
    
      </div>

    </>
  );
}

export default ReportPage;
