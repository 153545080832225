import React, { useState, useRef } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";

function DailyProgressReport({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [errors, setErrors] = useState({});
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [progressreport, setProgressreport] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    
    // Use setProgressreport to update the state
    setProgressreport((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  
    // Clear errors for the current field
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", 
    }));
  };
  

  const handleCancelClick = () => {
    navigate("/daily-work-progress");
  };

  const handleSaveClick = async () => {
    // Initialize error state
    let isValid = true;
  
    // Date validation
    if (!selectedDate) {
      setErrors((prevState) => ({
        ...prevState,
        date: "Date is required",
      }));
      isValid = false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        date: "", // Clear the error if valid
      }));
    }
  
    // Description validation
    // if (!progressreport.description) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     description: "Description is required",
    //   }));
    //   isValid = false;
    // } else {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     description: "", // Clear the error if valid
    //   }));
    // }
  
    // Attachment validation
    if (!attachmentFile || attachmentFile.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        attachment: "At least one attachment is required",
      }));
      isValid = false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        attachment: "", // Clear the error if valid
      }));
    }
  
    // If validation fails, return early
    if (!isValid) return;
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Create a FormData object
      const formData = new FormData();
      formData.append("date", selectedDate.toISOString().split("T")[0]); // Format the date as YYYY-MM-DD
      formData.append("description", progressreport.description || "");
  
      // Append each selected file to the FormData
      if (attachmentFile) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }
  
      // Make the POST request
      const response = await axios.post(
        `${API_URL}/api/add_daily_progress_report`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Daily progress report added successfully");
        setTimeout(() => {
          navigate("/daily-work-progress");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving daily progress report:", error.message);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message || "An error occurred while saving the report"
      );
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setProgressreport((prevState) => ({
      ...prevState,
      date: date,
    }));
    setErrors((prevState) => ({
      ...prevState,
      date: "", // Clear the error message for the 'date' field
    }));
  };

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  // const handleFileInputChange = (e) => {
  //   const files = e.target.files;
  //   const newFileDetails = Array.from(files).map((file) => ({
  //     name: file.name,
  //     size: file.size,
  //   }));

  //   setSelectedFiles([...selectedFiles, ...newFileDetails]);

  //   // Set the actual file objects in the state for FormData
  //   setAttachmentFile((prevFiles) => [
  //     ...(prevFiles || []),
  //     ...Array.from(files),
  //   ]);
  // };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    // Update selected files for display
    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...newFileDetails,
    ]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);

    // Clear the error message if files are attached
    if (files.length > 0) {
      setErrors((prevState) => ({
        ...prevState,
        attachment: "", // Clear error message for attachment
      }));
    }
  };



  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Daily Progress
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="date">Date <span className="text-danger">*</span></label>
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.date && <div className="text-danger">{errors.date}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      name="description"
                      // value={progressreport.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                  </div>
                  {errors.description && <div className="text-danger">{errors.description}</div>}

                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    Attachment{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                    ref={buttonRef}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={popupRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {errors.attachment && <div className="text-danger">{errors.attachment}</div>}

                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default DailyProgressReport;
