import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ColdWorkPermitInspectionTab from "../ColdWorkPermit/ColdWorkPermitInspectionTab";

import { Tabs, Tab } from "@mui/material";

function ColdWorkPermitInspection({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [newCounts, setNewCounts] = useState({
    "In Review": 0,
    Approved: 0,
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    pending: 0,
  });

  const [tabCounts, setTabCounts] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });

  // Extracting tab from URL query
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "NewRequests");
  const updateNewCounts = (counts) => {
    console.log("Updated counts:", counts); // Check the counts being passed
    setNewCounts({
      "In Review": counts["In Review"] || 0,
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
    });
  };

  const updateTotalItems = (counts) => {
    console.log("Updated counts:", counts);
    setTabCounts({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
      "Pending Approval": counts["Pending Approval"] || 0,
    });
  };

  const getBlinkingClass = (count) => {
    return count > 0 ? "blinking-news" : "";
  };
  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };
  const getStatusForTab = (tabValue) => {
    switch (tabValue) {
      case "NewRequests":
        return "Pending Approval"; // Draft
      case "Approved":
        return "Approved"; // Submitted
      case "Rejected":
        return "Rejected"; // Submitted
      default:
        return null;
    }
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {/* <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              All  Work Permit Inspection
            </div>

            <div className="data-list-heading-content-right-side"></div>
          </div>
        </div> */}

        <div className="data-list-content" ref={containerRef}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(event, newValue)}
            aria-label="report tabs"
            className="tabs-container"
          >
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">TO Be Approved</span>

                  <span className="tab-count">
                    ({tabCounts["Pending Approval"] || 0})
                  </span>
                </div>
              }
              value="NewRequests"
            />

            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Approved</span>

                  <span className="tab-count">({tabCounts.Approved || 0})</span>
                </div>
              }
              value="Approved"
            />

            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Rejected</span>

                  <span className="tab-count">({tabCounts.Rejected || 0})</span>
                </div>
              }
              value="Rejected"
            />
          </Tabs>
          <div className="header-separater"></div>
          <div className="tabs-content">
            <ColdWorkPermitInspectionTab
              status={getStatusForTab(tabValue)}
              updateNewCounts={updateNewCounts}
              updateTotalItems={updateTotalItems}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ColdWorkPermitInspection;
