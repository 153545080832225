import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { BsInfoCircle } from "react-icons/bs";
import ContractorView from "../Views/ContractorView";
import useClickOutside from "../useClickOutside";
import { MdExpandLess } from "react-icons/md";

function ContractorCompany({ isSidebarExpanded, contractsID }) {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [popupUserID, setPopupUserID] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...contracts].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setContracts(sortedBreakdown);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_contractor_companies`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      setContracts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching contractor company data:", error.message);
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? contracts.map((contract) => contract.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (contractsID) => {
    const updatedSelectedRows = selectedRows.includes(contractsID)
      ? selectedRows.filter((id) => id !== contractsID)
      : [...selectedRows, contractsID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === contracts.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  const handleArchivedContractorCompany = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_contractor_companies`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");

        // Filter out archived customers
        const updatedContracts = contracts.filter(
          (contracts) => !selectedRows.includes(contracts.ID)
        );

        // Recalculate serial numbers
        const customersWithUpdatedSerials = updatedContracts.map(
          (customer, index) => ({
            ...customer,
            serial_no: index + 1, // Assign new serial number based on index
          })
        );

        setContracts(customersWithUpdatedSerials);
        fetchData()
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to archive customers");
      }
    } catch (error) {
      console.error("An error occurred while archiving customers:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveContractorcompany = () => {
    navigate("/archive-contractor-company");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const renderTooltip = (location) => (
    <div>
      <p>
        <strong>Contact Person:</strong> {location.contact_person_name || "NA"}
      </p>
      <p>
        <strong>Contact Number:</strong> {location.contact_no || "NA"}
      </p>
      <p>
        <strong>Email:</strong> {location.email || "NA"}
      </p>
      <p>
        <strong>Address:</strong> {location.address || "NA"}
      </p>
    </div>
  );
  const togglePopup = (customerID) => {
    setPopupUserID(customerID);
    setIsMainContainerVisible(true);
  };
  const handleCloseUserView = () => {
    setIsMainContainerVisible(false); // Close the user view
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="delete" onClick={handleDeletePopup}>
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchivedContractorCompany}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Contractor Company
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search Company..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link
                      to="/contractor-company-form"
                      className="data-list-new"
                    >
                      <Tooltip title="New Contractor Company" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button
                              onClick={() => handleSort("contractor_name")}
                            >
                              Company Name{" "}
                              {sortOrder.contractor_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("start_date")}>
                              Start Date{" "}
                              {sortOrder.start_date === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("end_date")}>
                              End Date{" "}
                              {sortOrder.end_date === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("location")}>
                              Location{" "}
                              {sortOrder.location === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                          <button
                            className="delete-item"
                            onClick={handleArchiveContractorcompany}
                          >
                            Archived Contractor Company
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : contracts.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment">SR. NO.</th>
                          <th>COMPANY NAME</th>
                          <th>CONTRACT START DATE</th>
                          <th>CONTRACT END DATE</th>
                          <th>ADDRESS</th>
                          <th>LOCATIONS</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {contracts.map((contract, index) => (
                          <tr key={contract.id} className="hoverable-row">
                            <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(contract.id)}
                                onChange={() => handleSelectRow(contract.id)}
                              />
                            </td>

                            <td className="center-alignment">{index + 1}</td>
                            {/* <td>{contract.contractor_name || "NA"}</td> */}
                            <td
                              className="view-data"
                              onClick={() => togglePopup(contract.id)}
                            >
                              {contract.contractor_name || "NA"}
                            </td>
                            <td>{formatDate(contract.start_date)}</td>
                            <td>{formatDate(contract.end_date)}</td>
                            <td>{contract.address || "NA"}</td>
                            <td>
                              {contract.locations.map((location, index) => (
                                <React.Fragment key={index}>
                                  {location.location || "NA"}
                                  <Tooltip title={renderTooltip(location)}>
                                    <span>
                                      <BsInfoCircle className="hover-icon" />
                                    </span>
                                  </Tooltip>
                                  {index !== contract.locations.length - 1 &&
                                    ", "}
                                </React.Fragment>
                              ))}
                            </td>
                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-contractor/${contract.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Contactor Company Found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isMainContainerVisible && (
          <div className="main-containers">
            <ContractorView
              contractsID={popupUserID}
              onClose={handleCloseUserView}
            />
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ContractorCompany;
