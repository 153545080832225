import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./EquipmentForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AddDepartmentPopup from "../Popups/AddDepartmentPopup";
import AddEquipmentType from "../Popups/AddEquipmentType";
import AddEquipmentSubType from "../Popups/AddEquipmentSubType";
import AddSubTypeVariant from "../Popups/AddSubTypeVariant";
import AddSubDepartmentPopup from "../Popups/AddSubDepartmentPopup";
import { MdAttachFile } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
const { Control, Menu } = components;

function EquipmentForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedMaintanceDate, setSelectedMaintanceDate] = useState(null);
  const [selectedWarrantyDate, setselectedWarrantyDate] = useState(null);
  const [selectedSubDepartmentId, setSelectedSubDepartmentId] = useState(null);
  const [currentOpenPopup, setCurrentOpenPopup] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(null);
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [EquipmentSubTypeMenuIsOpen, setEquipmentSubTypeMenuIsOpen] =
    useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null); // New state for selected department
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [showequipmentTypePopup, setShowequipmentTypePopup] = useState(false);
  const [selectedEquipmentSubTypeVariants, setSelectEdequipmentTypeVariants] =
    useState(null);
  const [
    EquipmentSubTypeVariantsMenuIsOpen,
    setEquipmentSubTypeVariantsMenuIsOpen,
  ] = useState(false);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [subDepartmentMenuIsOpen, setSubDepartmentMenuIsOpen] = useState(false);
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [showSubTypePopup, setShowSubTypePopup] = useState(false);
  const [showSubDepartmentPopup, setShowSubDepartmentPopup] = useState(false);
  const [showSubTypeVariantPopup, setShowSubTypeVariantPopup] = useState(false);
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const fileInputRef = useRef(null);
  const [equipmentData, setEquipmentData] = useState({
    DepartmentId: "",
    DepartmentName: "",
    EquipmentTypeId: "",
    SubDepartmentName: "",
    SubDepartmentId: "",
    EquipmentType: "",
    EquipmentSubTypeId: "",
    EquipmentSubType: "",
    VariantNameId: "",
    SubTypeVariant: "",
    TagNo: "",
    Attachment: "",
    Make: "",
    Model: "",
    InstallationYear: null,
    WarrantyExp: "",
    FrequencyOfMaintenance: "",
    LastMaintenanceDate: "",
    Location: "",
    Description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEquipmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMaintanaceDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedMaintanceDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        LastMaintenanceDate: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        InstallationYear: year,
      }));
    }
  };

  const handleWarrantyDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setselectedWarrantyDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        WarrantyExp: formattedDate,
      }));
    }
  };

  const handleCancelClick = () => {
    navigate("/equipment");
  };

  const handleSaveClick = async () => {
    let isValid = true;

    if (!isValid) {
      return;
    }
    

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const formData = new FormData();
      formData.append("DepartmentId", equipmentData.DepartmentId);
      formData.append("DepartmentName", equipmentData.DepartmentName);
      formData.append("EquipmentTypeId", equipmentData.EquipmentTypeId);
      formData.append("EquipmentType", equipmentData.EquipmentType);
      formData.append("SubDepartmentId", equipmentData.SubDepartmentId);
      formData.append("SubDepartmentName", equipmentData.SubDepartmentName);
      formData.append("EquipmentSubTypeId", equipmentData.EquipmentSubTypeId);
      formData.append("EquipmentSubType", equipmentData.EquipmentSubType);
      formData.append("VariantNameId", equipmentData.VariantNameId);
      formData.append("SubTypeVariant", equipmentData.SubTypeVariant);
      formData.append("TagNo", equipmentData.TagNo);
      formData.append("Make", equipmentData.Make);
      formData.append("Model", equipmentData.Model);
      formData.append("InstallationYear", equipmentData.InstallationYear);
      formData.append("WarrantyExp", equipmentData.WarrantyExp);
      formData.append(
        "FrequencyOfMaintenance",
        equipmentData.FrequencyOfMaintenance
      );
      formData.append("LastMaintenanceDate", equipmentData.LastMaintenanceDate);
      formData.append("Location", equipmentData.Location);
      formData.append("Description", equipmentData.Description);

      // Append each file to the form data
      attachmentFile.forEach((file) => {
        formData.append("Datasheet", file);
      });

      const response = await axios.post(
        `${API_URL}/api/add_equipment`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data.message);

      if (response.status === 200) {
        showSnackbar("Equipment added successfully");
        setTimeout(() => {
          navigate("/equipment");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving equipment:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleDepartmentMenuOpen = () => {
    closeAllPopups();
    setDepartmentMenuIsOpen(true);
    setCurrentOpenPopup('department');
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );
  const closeDepartmentPopup = () => {
    setShowDepartmentPopup(false);
  };
  
  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const fetchSubDepartments = async () => {
    try {
      if (selectedDepartment) {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_sub_department/${selectedDepartment.value}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const options = data.map((subDepartment) => ({
            label: subDepartment.Name,
            value: subDepartment.id,
          }));
          setSubDepartments(options);
        } else {
          console.error("Failed to fetch sub-departments");
        }
      } else {
        setSubDepartments([]);
      }
    } catch (error) {
      console.error("An error occurred while fetching sub-departments:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchSubDepartments();
      setSelectedSubDepartment(null);
    } else {
      setSubDepartments([]);
      setSelectedSubDepartment(null);
    }
  }, [selectedDepartment]);

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
    fetchEquipmentTypes(selectedOption.value);
    setSelectEdequipmentType(null);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
  };
  

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectEdequipmentType(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    fetchEquipmentSubType(selectedOption.value);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
    fetchSubDepartments(selectedOption.value);
    setSelectedSubDepartment(null);
    setSelectEdequipmentType(null);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
  };

  const handleEquipmentTypeMenuOpen = () => {
    closeAllPopups();
    setEquipmentTypeMenuIsOpen(true);
    setCurrentOpenPopup('equipmentType');
  };

  const closeAllPopups = () => {
    setDepartmentMenuIsOpen(false);
    setSubDepartmentMenuIsOpen(false);
    setEquipmentTypeMenuIsOpen(false);
    setEquipmentSubTypeMenuIsOpen(false);
    setEquipmentSubTypeVariantsMenuIsOpen(false);
  };

  const CustomTypeControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomTypeMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddEquipmentType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Type
      </button>
    </Menu>
  );
  const handleAddEquipmentType = () => {
    setShowequipmentTypePopup(true);
    setEquipmentTypeMenuIsOpen(false);
  };


  const closeEquipmentTypePopup = () => {
    setShowequipmentTypePopup(false);
  
  };

  const fetchEquipmentTypes = async (subDepartmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types_by_sub_department/${subDepartmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Types");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Types:", error);
    }
  };

  useEffect(() => {
    if (selectedSubDepartmentId) {
      fetchEquipmentTypes(selectedSubDepartmentId);
    }
  }, [selectedSubDepartmentId]);

  const handleEquipmentSubTypeMenuOpen = () => {
    closeAllPopups();
    setEquipmentSubTypeMenuIsOpen(true);
    setCurrentOpenPopup('equipmentSubType');
  };

  const CustomSubControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Sub Type
      </button>
    </Menu>
  );
  const handleAddNewSubType = () => {
    setShowSubTypePopup(true);
    setEquipmentSubTypeMenuIsOpen(false);
  };

  const closeSubTypePopup = () => {
    setShowSubTypePopup(false);

  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleTypeSubTypeChange = (selectedOption) => {
    setSelectedEquipmentSubType(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      EquipmentSubType: selectedOption.label,
      EquipmentSubTypeId: selectedOption.value,
    }));
    setSelectEdequipmentTypeVariants(null);
  };

  const fetchEquipmentSubType = async (equipmentTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_sub_types_by_type/${equipmentTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubType) => ({
          label: equipmentSubType.EquipmentSubType,
          value: equipmentSubType.id,
        }));
        setEquipmentSubTypes(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentTypes) {
      fetchEquipmentSubType(selectedEquipmentTypes.value);
    }
  }, [selectedEquipmentTypes]);

  const handleEquipmentSubTypeVariantMenuOpen = () => {
    closeAllPopups();
    setEquipmentSubTypeVariantsMenuIsOpen(true);
    setCurrentOpenPopup('equipmentSubTypeVariants');
  };

  const CustomVariantControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomVariantMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewTypeVariant}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Sub Type Variant
      </button>
    </Menu>
  );

  const handleAddNewTypeVariant = () => {
    setShowSubTypeVariantPopup(true);
    setEquipmentSubTypeVariantsMenuIsOpen(false);
  };

  const closeEquipmentTypeVariantPopup = () => {
    setShowSubTypeVariantPopup(false);
  };

  const closeSubDepartmentPopup = (newSubDepartment) => {
    setShowSubDepartmentPopup(false);
    fetchSubDepartments(); 
    if (newSubDepartment) {
      // If a new sub-department is added
      const updatedSubDepartment = {
        label: newSubDepartment.SubDepartment,
        value: newSubDepartment.id,
      };
      setSelectedSubDepartment(updatedSubDepartment); 
    }
  };

  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectEdequipmentTypeVariants(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      SubTypeVariant: selectedOption.label,
      VariantNameId: selectedOption.value,
    }));
  };

  const fetchEquipmentSubTypeVariants = async (equipmentSubTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_type_variants_by_sub_type/${equipmentSubTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubTypeVariant) => ({
          label: equipmentSubTypeVariant.VariantName,
          value: equipmentSubTypeVariant.id,
        }));
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentSubType) {
      fetchEquipmentSubTypeVariants(selectedEquipmentSubType.value);
    }
  }, [selectedEquipmentSubType]);

  const CustomSubDepartmentControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubDepartmentMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Sub Department
      </button>
    </Menu>
  );
  const handleSubDepartmentMenuOpen = () => {
    closeAllPopups();
    setSubDepartmentMenuIsOpen(true);
    setCurrentOpenPopup('subDepartment');
  };

  const handleAddNewSubDepartment = () => {
    setShowSubDepartmentPopup(true);
    setSubDepartmentMenuIsOpen(false);
  };

  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);

    setOfferDocumentError("");
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleNewEquipmentType = (newEquipment) => {
    setEquipmentTypes((prevEquipmentTypes) => [
      ...prevEquipmentTypes,
      newEquipment,
    ]);
    setSelectEdequipmentType(newEquipment);
    setEquipmentData((prevEquipmentSubTypes) => ({
      ...prevEquipmentSubTypes,
      EquipmentType: newEquipment.label,
      EquipmentTypeId: newEquipment.value,
    }));
    showSnackbar("Equipment type added successsfully");
  };

  const handleNewEquipmentSubType = (newSubEquipment) => {
    setEquipmentSubTypes((prevEquipmentSubTypes) => [
      ...prevEquipmentSubTypes,
      newSubEquipment,
    ]);
    setSelectedEquipmentSubType(newSubEquipment);
    setEquipmentData((prevEquipmentSubTypes) => ({
      ...prevEquipmentSubTypes,
      EquipmentSubType: newSubEquipment.label,
      EquipmentSubTypeId: newSubEquipment.value,
    }));
    showSnackbar("Equipment sub type added successsfully");
  };

  const handleNewDepartment = (newDepartment) => {
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
      showSnackbar("Department added successfully");
    }
  };
 

  const handleNewEquipmentSubTypeVariant = (newSubTypeVariant) => {
    setEquipmentTypeVariants((prevEquipmentTypeVariants) => [
      ...prevEquipmentTypeVariants,
      newSubTypeVariant,
    ]);
    setSelectEdequipmentTypeVariants(newSubTypeVariant);
    setEquipmentData((prevEquipmentTypeVariants) => ({
      ...prevEquipmentTypeVariants,
      SubTypeVariant: newSubTypeVariant.label,
      VariantNameId: newSubTypeVariant.value,
    }));
    showSnackbar("Equipment sub type variant added successsfully");
  };

  const handleNewSubDepartment = (newSubDepartment) => {
    setSubDepartments((prevSubDepartments) => [
      ...prevSubDepartments,
      newSubDepartment,
    ]);
    setSelectedSubDepartment(newSubDepartment);
    setEquipmentData((prevSubDepartments) => ({
      ...prevSubDepartments,
      SubDepartmentName: newSubDepartment.label,
      SubDepartmentId: newSubDepartment.value,
    }));
   
    showSnackbar("Sub Department added successfully")
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Equipment
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="newperson-user-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">
                    Sub Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="subDepartment"
                    options={subDepartments}
                    value={selectedSubDepartment}
                    onChange={handleSubDepartmentChange}
                    components={{
                      Control: CustomSubDepartmentControl,
                      Menu: CustomSubDepartmentMenu,
                    }}
                    placeholder="Select Sub Department"
                    isSearchable
                    menuIsOpen={subDepartmentMenuIsOpen}
                    onMenuOpen={handleSubDepartmentMenuOpen}
                    onMenuClose={() => setSubDepartmentMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentType">
                    Equipment Type<span className="text-danger">*</span>{" "}
                  </label>

                  <Select
                    id="EquipmentType"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    components={{
                      Control: CustomTypeControl,
                      Menu: CustomTypeMenu,
                    }}
                    placeholder="Select Equipment Type"
                    isSearchable
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentSubType">
                    Equipment Sub Type<span className="text-danger">*</span>{" "}
                  </label>

                  <Select
                    id="EquipmentSubType"
                    options={equipmentSubTypes}
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    components={{
                      Control: CustomSubControl,
                      Menu: CustomSubMenu,
                    }}
                    placeholder="Select Sub Type"
                    isSearchable
                    menuIsOpen={EquipmentSubTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentSubTypeMenuOpen}
                    onMenuClose={() => setEquipmentSubTypeMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="ContactNo">
                    Sub Type Variant <span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipmentSubTypeVariant"
                    options={equipmentSubTypeVariants}
                    value={selectedEquipmentSubTypeVariants}
                    onChange={handleTypeSubTypeVariantsChange}
                    components={{
                      Control: CustomVariantControl,
                      Menu: CustomVariantMenu,
                    }}
                    placeholder="Select Sub Type Variants"
                    isSearchable
                    menuIsOpen={EquipmentSubTypeVariantsMenuIsOpen}
                    onMenuOpen={handleEquipmentSubTypeVariantMenuOpen}
                    onMenuClose={() =>
                      setEquipmentSubTypeVariantsMenuIsOpen(false)
                    }
                  />
                </div>
                <div className="form-group-attachment">
                  <label htmlFor="Attachment">
                    Data Sheet<span className="text-danger">*</span>{" "}
                  </label>
                  <div className="input-group-attachment">
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={handleUploadButtonClick}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    {selectedFiles.length > 0 && ( // Conditionally render if files are selected
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                      <div className="attach-popup">
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div className="file-delete-btn">
                                      {/* <span> <MdDeleteOutline className="file-delete-icon" /></span> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="TagNo">
                    Tag No.<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="TagNo"
                      onChange={handleInputChange}
                      placeholder="Enter tag  no"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Make">
                    Make<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Make"
                      onChange={handleInputChange}
                      placeholder="Enter make"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Model">
                    Model<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Model"
                      onChange={handleInputChange}
                      placeholder="Enter model"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Installation">
                    Year Of Installation<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedInstallationDate}
                      onChange={handleInstallationDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="yyyy"
                      placeholderText="Select year"
                      className="datesss"
                      isClearable={false}
                      showYearPicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Warranty">
                    Warranty Expiry<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedWarrantyDate}
                      onChange={handleWarrantyDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a expiry date"
                      className="datesss"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="FrequencyOfMaintenance">
                    Frequency of Maintenance
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="FrequencyOfMaintenance"
                      onChange={handleInputChange}
                      placeholder="Enter frequency"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Maintenance_Date">
                    Last Maintenance Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedMaintanceDate}
                      onChange={handleMaintanaceDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a maintenance date"
                      className="datesss"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Location">
                    Location<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Location"
                      onChange={handleInputChange}
                      placeholder="Enter location"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Description">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="Description"
                      id="Description"
                      cols="35"
                      rows="3"
                      placeholder="Enter description"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
            {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )}
            {showSubDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubDepartmentPopup
                  closePopup={closeSubDepartmentPopup}
                  selectedDepartment={selectedDepartment}
                  parentSelectedDepartment={selectedDepartment}
                  handleNewSubDepartment={handleNewSubDepartment} 
                />
              </div>
            )}
            {showequipmentTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentType
                  closePopup={closeEquipmentTypePopup}
                  setSelectEdequipmentType={setSelectEdequipmentType}
                  handleNewEquipmentType={handleNewEquipmentType}
                  parentSelectedDepartment={selectedDepartment}
                  parentSelectedSubDepartment={selectedSubDepartment}
                />
              </div>
            )}
            {showSubTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentSubType
                  closePopup={closeSubTypePopup}
                  setSelectedEquipmentSubType={setSelectedEquipmentSubType}
                  parentSelectedEquipmentType={selectedEquipmentTypes}
                  handleNewEquipmentSubType={handleNewEquipmentSubType}
                />
              </div>
            )}
            {showSubTypeVariantPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubTypeVariant
                  closePopup={closeEquipmentTypeVariantPopup}
                  setSelectEdequipmentTypeVariants={
                    selectedEquipmentSubTypeVariants
                  }
                  handleNewEquipmentSubTypeVariant={handleNewEquipmentSubTypeVariant}
                  parentSelectedEquipmentSubType={selectedEquipmentSubType}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EquipmentForm;
