import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import "../Responsive/TableResponsive.css";
import { useNavigate } from "react-router-dom";
import "./PRRequest.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { GiBackwardTime } from "react-icons/gi";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import { RiDraftLine } from "react-icons/ri";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";

function PRRequest({ isSidebarExpanded, prRequestID }) {
  const [prRequests, setPrRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [searchInput, setSearchInput] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [sentRequests, setSentRequests] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const fetchTimeout = useRef(null);
  const navigate = useNavigate();

  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  
  const handleEditClick = (id, index) => {
    if (
      prRequests[index].pr_status === "Pending" ||
      prRequests[index].pr_status === "Returned" ||
      prRequests[index].pr_status === "Drafted"
    ) {
      setSelectedRowIndex(index === selectedRowIndex ? null : index);
      navigate(`/edit-pr-request-form/${id}`);
    } else {
      setSeverity("warning");
      setSnackbarMessage(
        "Editing is allowed only for requests with status Pending, Returned, or Drafted."
      );
      setSnackbarOpen(true); // Open the Snackbar
    }
  };
  
  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

    const handleArchivePrRequest = () =>{
      // alert('...test')
      navigate('/archived-pr-request')
    }



  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));
    setIsSortPopup(false);
    
    const sortedVendors = [...prRequests].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setPrRequests(sortedVendors);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_pr_requests`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      console.log("API Response:", response.data); // Log the response to inspect the date formats

      setPrRequests(response.data.pr_requests);
      setTotalItems(response.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching PR requests data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/search_pr_requests`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
      if (response.status === 200) {
        setPrRequests(response.data.pr_requests);
        setTotalItems(response.data.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  const handleClosePopup = () => {
    setIsSortPopup(false);
    setIsPopupOpen(false);
  };

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);
  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? prRequests.map((prRequest) => prRequest.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (prRequestID) => {
    const updatedSelectedRows = selectedRows.includes(prRequestID)
      ? selectedRows.filter((id) => id !== prRequestID)
      : [...selectedRows, prRequestID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === prRequests.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedPrRequest = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = prRequests.filter(
          (department) => !selectedRows.includes(department.ID)
        );
        setPrRequests(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };
  const handleIconClick = (index) => {
    // Toggle the popup open/close based on the current state
    setIsPopupOpen((prev) => (prev === index ? null : index));

    setSelectedRowIndex(false); // Reset the selected row
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

 
  const handleRefresh = () => {
    if (searchInput) {
      console.log('Search input is set:', searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const formatDate = (dateString) => {
    if (!dateString || isNaN(new Date(dateString))) {
      return "NA"; // Or any default value you want to show for invalid dates
    }

    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formatter.format(date);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Drafted":
        return "rgb(153 140 140)";
      case "Pending":
        return "#D4AC0D";
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      case "Returned":
        return "#ff9800";
      default:
        return "black";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Drafted":
        return (
          <RiDraftLine
            style={{
              fontSize: "1.1rem",
              color: "rgb(153 140 140)",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Pending":
        return (
          <MdOutlineWatchLater
            style={{
              fontSize: "1.1rem",
              color: "#D4AC0D",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Approved":
        return (
          <FaRegCheckCircle
            style={{
              fontSize: "1.1rem",
              color: "green",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Rejected":
        return (
          <RxCrossCircled
            style={{
              fontSize: "1.1rem",
              color: "red",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Returned":
        return (
          <GiBackwardTime
            style={{
              fontSize: "1.1rem",
              color: "#ff9800",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      default:
        return null;
    }
  };

  const handleSendRequest = async (prRequestID, IsRequested) => {
    try {
      if (IsRequested === 1 || sentRequests.includes(prRequestID)) {
        setSeverity("warning");
        showSnackbar("Request already sent for this PR request.");
        return;
      }

      const authKey = localStorage.getItem("authKey");
      const response = await axios.post(
        `${API_URL}/api/send_pr_request/${prRequestID}`,
        {}, // Empty data payload since the endpoint does not require additional data
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Request sent successfully");

        // Update the sentRequests state
        setSentRequests([...sentRequests, prRequestID]);
      } else {
        showSnackbar("Error sending request.");
        console.error("Error sending request:", response.statusText);
      }
    } catch (error) {
      showSnackbar("An error occurred while sending the request.");
      console.error("Error sending request:", error.message);
    }
  };

  const handleViewAttachment = (attachment) => {
    window.open(
      `${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`,
      "_blank"
    );
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                     
                    <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchivedPrRequest}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All PR Request
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/pr-request-form" className="data-list-new">
                      <Tooltip title="New PR Request" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button
                              onClick={() => handleSort("department_name")}
                            >
                              Department Name{" "}
                              {sortOrder.department_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("preferred_vendor")}>
                              Vendor{" "}
                              {sortOrder.preferred_vendor === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <button onClick={() => handleSort("pr_type")}>
                              PR Type{" "}
                              {sortOrder.pr_type === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("pr_status")}>
                              Status{" "}
                              {sortOrder.pr_status === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("expected_delivery_date")}>
                              Delivery Date{" "}
                              {sortOrder.expected_delivery_date === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          <button className='delete-item' onClick={handleArchivePrRequest}>Archive All PR Request</button>

                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : prRequests.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment">SR. NO.</th>
                          <th className="center-alignment">PR REQUEST ID</th>
                          <th>DEPARTMENT</th>
                          <th>PREFERRED VENDOR</th>
                          <th>PR TYPE</th>
                          <th className="center-alignment">REQUEST STATUS</th>
                          <th className="center-alignment">DELIVERY DATE</th>
                          <th className="center-alignment">ATTACHMENTS</th>
                          <th className="center-alignment">ACTION</th>

                          <th className="center-alignment"></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {prRequests.map((prRequest, index) => (
                          <tr key={prRequest.id} className="hoverable-row">
                            <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(prRequest.id)}
                                onChange={() => handleSelectRow(prRequest.id)}
                              />
                            </td>

                            <td className="center-alignment">{prRequest.serial_no}</td>
                            <td style={{ color: "blue" }}>
                              {prRequest.random_request_id}
                            </td>
                            <td>{prRequest.department_name || "NA"}</td>
                            <td>{prRequest.preferred_vendor || "NA"}</td>
                            <td>{prRequest.pr_type || "NA"}</td>
                            <td
                              className="request-status center-alignment"
                              style={{
                                color: getStatusColor(prRequest.pr_status),
                              }}

                            >
                              {getStatusIcon(prRequest.pr_status)}{" "}
                              {prRequest.pr_status || "NA"}
                            </td>

                            <td className="center-alignment">
                              {formatDate(
                                prRequest.expected_delivery_date || "NA"
                              )}
                            </td>

                            <td className="center-alignment">
                              <span
                                className="inquiry-file-btns"
                                onClick={() => handleIconClick(index)}
                                ref={buttonRef}
                              >
                                <MdAttachFile className="inquiry-file-icon" />
                              </span>
                              {isPopupOpen === index && (
                                <div
                                  className="inquiry-attach-popup prRequest-popup"
                                  ref={popupRef}
                                >
                                  <div className="attach-popup-contant">
                                    <div className="attachment-header">
                                      <div className="attachment-header-content">
                                        Attachments
                                      </div>
                                      <button
                                        className="fiile-close-btn"
                                        onClick={handleCloseAttachment}
                                      >
                                        <IoCloseOutline className="file-close-icon" />
                                      </button>
                                    </div>
                                    <div
                                      className="attach-popup-contant-body"
                                      key={index}
                                    >
                                      <div className="inquiry-attach-file-containerssss">
                                        <div className="attach-file-showsss">
                                          <div className="text-attachmentss">
                                            {prRequest.attachments &&
                                            prRequest.attachments.length > 0 ? (
                                              <div className="attach-popup-content-body">
                                                {prRequest.attachments.map(
                                                  (
                                                    attachment,
                                                    attachmentIndex
                                                  ) => (
                                                    <div
                                                      className="inquiry-attach-file-container"
                                                      key={attachmentIndex}
                                                    >
                                                      <div className="inquiry-attacth-file-model">
                                                        <div className="attach-file-show">
                                                          <div className="text-attachment">
                                                            {attachment.filename
                                                              .length > 20
                                                              ? `${attachment.filename.substring(
                                                                  0,
                                                                  15
                                                                )}...`
                                                              : attachment.filename}
                                                          </div>
                                                          <div className="text-attachment-file">
                                                            File Size:{" "}
                                                            {attachment.file_size ||
                                                              "NA"}{" "}
                                                            KB
                                                          </div>
                                                        </div>

                                                        <div className="file-footer">
                                                          {/* <div
                                                                className="file-download-attachment file-txt"
                                                                onClick={() =>
                                                                  handleViewAttachment(prRequest)
                                                                }
                                                              >
                                                                Download
                                                              </div> */}
                                                          <a
                                                            href={`${API_URL}/api/download_attachment/${prRequest.file_id}/${prRequest.file_type}`}
                                                            download
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            Download
                                                          </a>
                                                          <div
                                                            className="file-download-attachment file-txt"
                                                            onClick={() =>
                                                              handleViewAttachment(
                                                                prRequest
                                                              )
                                                            }
                                                          >
                                                            View
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="no-attachments">
                                                No attachments found
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="file-delete-btn"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>

                            <td className="center-alignment">
                              <button
                                className="request-button"
                                onClick={() =>
                                  handleSendRequest(
                                    prRequest.id,
                                    prRequest.IsRequested
                                  )
                                }
                                disabled={
                                  sentRequests.includes(prRequest.id) ||
                                  prRequest.IsRequested === 1
                                }
                                style={{
                                  backgroundColor:
                                    sentRequests.includes(prRequest.id) ||
                                    prRequest.IsRequested === 1
                                      ? "rgb(206, 202, 202)"
                                      : "#45a049", // or specify the default background color here
                                }}
                              >
                                {sentRequests.includes(prRequest.id) ||
                                prRequest.IsRequested === 1
                                  ? "Requested"
                                  : "Send Request"}
                              </button>
                            </td>
{/* 
                            <td className="edit-popup-buttons center-alignment">
                              <button
                                className="edit-button-inquiry1"
                           
                                onClick={() => handleEditClick(prRequest.id, index)}
                              >
                                <MdOutlineModeEditOutline className="edit-show-icon" />{" "}
                                Edit
                              </button>
                            </td> */}

<td className="edit-popup-buttons center-alignment">
  <button
    className={`edit-button-inquiry1 ${
      prRequest.pr_status !== "Pending" &&
      prRequest.pr_status !== "Returned" &&
      prRequest.pr_status !== "Drafted"
        ? "disabled-button"
        : ""
    }`}
    onClick={() => handleEditClick(prRequest.id, index)}
    disabled={
      prRequest.pr_status !== "Pending" &&
      prRequest.pr_status !== "Returned" &&
      prRequest.pr_status !== "Drafted"
    }
  >
    <MdOutlineModeEditOutline className="edit-show-icon" /> Edit
  </button>
</td>

                          
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No PR Request Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "75px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PRRequest;
