import React, { useState, useEffect } from "react";
// import "./Template2.css";
import { useParams } from "react-router-dom";

const HotWorkTemplate7 = ({ hazardsPrecautions, isSidebarExpanded }) => {
  const { formId } = useParams();
  return (
    <>
    
        <div className="hot-template-container">
          <div className="template-container-content underline-bottom">
            <div className="template-container-pera">
              <div className="cold-remark">
              <div className="template-heading">सामान्य अनुदेश/General Instruction</div>
              <div className="pera-flex">
              <span style={{ width: "50px", paddingRight: "26px" }}>1.</span>
              <div>
                 कार्य के अनुमती पत्र को स्पष्ट रूप से और सही रूपसे स्पष्ट
                  अक्षरों में भरा जाएगा, ताकी यह सुनिश्चित हो सके कि सभी
                  अनुभागों / उपखंडों में पुरी जानकारी <br/> प्रदान की गई है। गलत संचार
                  से बचने के लिए जो भी संभव हो सके प्रदान किया जाना चाहिए ।{" "}
                  <br /> The work permit shall be filed up carefully and
                  accurately in clear handwriting ensuring that complete
                  information is provided whatever possible <br/> to avoid mis
                  communication.
                  </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>2.</span>
                <div>
                उपयुकत सुरक्षा उपायों और कर्मचारियों के व्यक्तिगत सुरक्षा
                  उपकरण (पीपीई) को संभावित खतरों के सावधानीपुर्वक विश्लेषन और
                  काम शुरू करने से <br /> पहले किये जाने वाले संचालन द्वारा निर्धारित
                  किया जाएग । <br /> Appropriate safeguards and required
                  personnel protective equipment (PPEs) shall be determined by a
                  careful analysis of the potential hazards <br /> and the operation to
                  be performed prior to starting the work.
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>3.</span>
                <div>
                 यदि स्टैंड-बौय मैन की आवश्यकता हो तो प्रक्रिया विभाग /
                  रखरखाव विभाग / टेकेदार / अग्नि विभाग / सुरक्षा विभाग आदि से
                  कोई अतिरिक्त आवश्यकता में उल्लेखित किया <br /> जाएगा। 
                  Requirement of standby personnel from Process/
                  Maintenance/Contractor/Fire/Safety etc. if any shall be
                  mentioned in the additional <br /> requirement.
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>4.</span>
                <div>
                   आग की चेतावनी / सायरन होने पर सभी कार्य तुरंत बंद दिया जाए।{" "}
                  <br />
                  in case of alarm/siren, all work must immediately be stopped.
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>5.</span>
                <div>
                  कार्य निकासी के नवीनीकरण हेतु जारीकर्ता यह सुनिश्चित करेगा
                  की कार्य को जारी रखने के लिए स्थिती संतोषप्रद है। यदि स्थिती
                  बदल गई है तो यह <br /> जरुरी है की नया अनुमती पत्र जारी किया जाए अथवा
                  मौजूदा परमिट में संशोधन किया जाए । <br />
                  For renewal of work clearance, the issuer shall ensure that
                  the conditions are satisfactory for the work to continue. If
                  the conditions <br /> have changed, it may necessary to issue a new
                  permit or amend the existing permit.
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>6.</span>
                <div>
                  ⁠उसी अनुमती पत्र पर क्लीयरेंस का नवीनीकरण। उसको बढ़ाया जाना
                  अधिकतम सात कैलेंडर दिनों तक किया जा सकता है
                  <br />
                  This clearance on the same permit can be renewal/ extended
                  upto a maximum of seven calendar days.
                </div>
                </div>
                
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>7.</span>
                <div>
                  ⁠यह अनुमती पत्र कार्यक्षेत्र पर हमेशा उपलब्ध रहना चाहिए।
                  <br />
                  This permit must be available at work site at all times.
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>8.</span>
                <div>
                 ⁠काम पूरा होने पर, अनुमती पत्र को बंद कर दिया जाएगा और
                  रिकॉर्ड के रूप में रखा जाएगा।
                  <br />
                  On completion of the work, the permit shall be closed and kept
                  as record
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>9.</span>
                <div>
                  संचालन और रखरखाव प्रथाओं के आधार पर अतिरिक्त निर्देश
                  <br />
                  The additional instruction based on operating and maintenance
                  practices
                </div>
                </div>
                <div className="pera-flex">
                <span style={{ width: "50px", paddingRight: "26px" }}>10.</span>
                <div>
                  10. सुरक्षा अधिकारी के परामर्श के बाद ही उसी दिन के कार्य
                  अनुमती पत्र को विस्तारित किया जाता है।
                  <br />
                  In case of permit extension for the same day permit to be
                  extended only after consulting with the safety officer.
                </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
};

export default HotWorkTemplate7;
