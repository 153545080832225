import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./MonthlyReportEmail.css";
import API_URL from "../Config";
import "./DailyProgressEmail.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const EmailConfiguration = ({
  closePopupEmail,
  dailyId,
  onClose,
  reportType,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedFormEmail, setSelectedFormEmail] = useState(null);
  const [selectedTemplateEmail, setSelectedTemplateEmail] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isSending, setIsSending] = React.useState(false);
  const [severity, setSeverity] = useState("success");
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const popupRef = useRef(null);
  // const [emailData, setEmailData] = useState({
  //   fileType: "CSV",
  //   isEnabled: true,
  // });
  const [emailData, setEmailData] = useState({
    reportType: "",
    fromEmail: "",
    template: "",
    sendDate: null,
    fileType: "CSV", // Default value for file type
    isEnabled: false,
  });
  const [formEmails, setFormEmails] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setEmailData((prevState) => ({
      ...prevState,
      sendDate: date,
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  // const handleDateChange = (date) => {
  //   setEmailData((prev) => ({ ...prev, sendDate: date }));
  // };

  const handleEmailChange = (selectedTemplateOption) => {
    setSelectedTemplateEmail(selectedTemplateOption); // Update the selected email in state
  };

  const handleFormEmailChange = (selectedOption) => {
    setSelectedFormEmail(selectedOption); // Update the selected email in state
  };

  // const fetchEmailsConfigLog = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const apiUrl = `${API_URL}/api/get_report_sender_configuration`;
  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: authKey,
  //       },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       // Map the API response to the required format
        
      
  //     } else {
  //       console.error("Failed to fetch email templates");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while fetching email templates:", error);
  //   }
  // };
  // Use the encoded module name
  
  // const fetchEmailsConfigLog = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const apiUrl = `${API_URL}/api/get_report_sender_configuration`;
  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: authKey,
  //       },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       // Map the API response to form fields
  //       const record = data.records[0]; // Assuming the response contains a single record
  //       setEmailData({
  //         reportType: record.report_type,
  //         fromEmail: record.email_config_email,
  //         template: record.template_name,
  //         sendDate: new Date(record.send_date), // Convert send_date to a Date object
  //         fileType: record.format || "CSV", // Default to "CSV" if format is not provided
  //         isEnabled: record.is_off === 0, // Assuming 0 means enabled
  //       });
  //       // Set email options (assuming email list and templates are part of the API response)
  //       setFormEmails([{ value: record.email_config_email, label: record.email_config_email }]);
  //       setEmailTemplates([{ value: record.template_name, label: record.temp_name || "Default Template" }]);
  //     } else {
  //       console.error("Failed to fetch email templates");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while fetching email templates:", error);
  //   }
  // };
  const fetchEmailsConfigLog = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_report_sender_configuration`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const record = data.records[0]; // Assuming the response contains a single record
  
        setEmailData({
          reportType: record.report_type,
          fromEmail: record.email_config_email,
          template: record.template_name,
          sendDate: new Date(record.send_date), // Convert send_date to a Date object
          fileType: record.format || "CSV", // Default to "CSV" if format is not provided
          isEnabled: record.is_off === 0, // Assuming 0 means enabled
        });
  

        setSelectedDate(new Date(record.send_date));
        setSelectedFormEmail({ value: record.email_config_id, label: record.email_config_email });
        setSelectedTemplateEmail({ value: record.email_temp_id, label: record.template_name || "Default Template" });
  
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };
  
  useEffect(() => {
    fetchEmailsConfigLog();
  }, []);

  const fetchEmails = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_templates`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const options = data.templates.map((email) => ({
          label: email.temp_name,
          value: email.id,
        }));

        setEmailTemplates(options); // Update state with formatted options
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };
  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchFormEmails = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_configs`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const options = data.configs.map((email) => {
          const label =
            email.is_default === 1 ? `${email.email} (default)` : email.email;
          return {
            label: label, // Append (default) to the email if it is the default
            value: email.id,
            // isDefault: email.is_default === 1, // Add an `isDefault` flag
          };
        });
  
        setFormEmails(options);
  
        // Automatically select the default email
        const defaultEmail = options.find((option) => option.isDefault);
        if (defaultEmail) {
          setSelectedFormEmail(defaultEmail);
        }
      } else {
        console.error("Failed to fetch email configs");
      }
    } catch (error) {
      console.error("An error occurred while fetching email configs:", error);
    }
  };
  

  useEffect(() => {
    fetchFormEmails();
  }, []);

 
  

  // const handleSubmit = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  
  //     const payload = {
  //       report_type: reportType, // Passed report type (e.g., "AMC Schedule")
  //       email_config_id: selectedFormEmail?.value, // ID from selected "From Email"
  //       email_temp_id: selectedTemplateEmail?.value, // ID from selected template
  //       send_date: selectedDate ? selectedDate.toISOString().split("T")[0] : null, // Format as YYYY-MM-DD
  //       format: emailData.fileType, // Format selected (CSV/Excel)
  //       is_off: emailData.isEnabled ? 0 : 1, // Map toggle switch state
  //     };
  
  //     // Log the payload for debugging
  //     console.log("Payload being sent to API:", payload);
  
  //     // Validate required fields
  //     if (!payload.report_type || !payload.email_config_id || !payload.email_temp_id) {
  //       setErrorMessage("Please fill in all required fields.");
  //       console.error("Validation failed: Missing required fields in payload.");
  //       return;
  //     }
  
  //     const response = await axios.post(
  //       `${API_URL}/api/report_sender_config`,
  //       payload,
  //       {
  //         headers: { Authorization: authKey },
  //       }
  //     );
  
  //     console.log("API response:", response); // Log API response
  
  //     if (response.status === 200) {
  //       setSuccessMessage(response.data.message); // Dynamic success message
  //       setShowSuccessMessage(true);
  //       console.log("API call successful:", response.data);
  //     } else {
  //       setErrorMessage(`Error: ${response.data.message}`);
  //       console.error("API returned error:", response.data.message);
  //     }
  
  //     onClose(); // Close popup after success
  //   } catch (error) {
  //     // Log the error details
  //     console.error("Error during API call:", error);
  
  //     if (error.response) {
  //       console.error("Error response details:", error.response);
  //       if (error.response.status === 400) {
  //         setErrorMessage(error.response.data.message); // Backend error message
  //       } else {
  //         setErrorMessage(`Error: ${error.response.data?.message || "Unknown error"}`);
  //       }
  //     } else {
  //       setErrorMessage("Failed to send email. Please try again."); // Fallback error
  //     }
  //   } finally {
  //     setIsSending(false); // Reset sending state
  //   }
  // };
  
  const handleSubmit = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
  
      const payload = {
        report_type: reportType,
        email_config_id: selectedFormEmail?.value,
        email_temp_id: selectedTemplateEmail?.value,
        send_date: selectedDate ? selectedDate.toISOString().split("T")[0] : null,
        format: emailData.fileType,
        is_off: emailData.isEnabled ? 0 : 1,
      };
  
      console.log("Payload being sent to API:", payload);
  
      if (!payload.report_type || !payload.email_config_id || !payload.email_temp_id) {
        setErrorMessage("Please fill in all required fields.");
        console.error("Validation failed: Missing required fields in payload.");
        return;
      }
  
      const response = await axios.post(
        `${API_URL}/api/report_sender_config`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );
  
      console.log("API response:", response);
        console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Email Send successfully");
        onClose();
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      // setIsLoading(false);
    }
  };
  
     
  
  return (
    <div className="reminder-popup-model" ref={popupRef}>
      <div className="reminder-popup-content">
        <div className="reminder-popup-model-header">
          <div className="reminder-popup-model-content-header">
            <div className="reminder-popup-model-content-header-left-part">
              Report Sender Configuration
            </div>
            <div className="reminder-popup-model-content-header-right-part"></div>
          </div>
          <div className="popup-new-form">
            <form className="popup-form-detail">
              <div className="new-data-form">
                {/* <div className="form-group email-filed">
                  <label htmlFor="to_email">Report Type</label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="report_type"
                      name="report_type"
                      value={reportType} // Display the passed report type
                      readOnly
                    />
                  </div>
                </div> */}
                <div className="form-group-select email-filed">
                  <label htmlFor="department_name">
                    From Email
                  </label>
                  <Select
                    id="department_name"
                    options={formEmails}
                    value={selectedFormEmail}
                    onChange={handleFormEmailChange}
                    placeholder="Select email"
                    isSearchable
                  />
                </div>
                <div className="form-group-select email-filed">
                  <label htmlFor="department_name">
                    Select Template
                  </label>
                  <Select
                      id="email_template_selector"
                      options={emailTemplates}
                      value={selectedTemplateEmail} // By default, this will be null, meaning no option is selected
                      onChange={handleEmailChange}
                      placeholder="Select email template" // Placeholder for when no option is selected
                      isSearchable
                  />
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="to_email">Send Date</label>
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDate}
                      // selected={emailData.sendDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="form-group email-field msmme">
                  <label htmlFor="fileType">Formate</label>
                  <div className="checkbox-input">
                 
                      <input
                        type="radio"
                        name="fileType"
                        value="CSV"
                        checked={emailData.fileType === "CSV"}
                        onChange={(e) =>
                          setEmailData((prev) => ({
                            ...prev,
                            fileType: e.target.value,
                          }))
                        }
                      />
                         <label>
                      CSV
                    </label>
                    </div>
                  
                    <div className="checkbox-input">
                      <input
                        type="radio"
                        name="fileType"
                        value="Excel"
                        checked={emailData.fileType === "Excel"}
                        onChange={(e) =>
                          setEmailData((prev) => ({
                            ...prev,
                            fileType: e.target.value,
                          }))
                        }
                      />
                        <label>
                      Excel
                    </label>
                  </div>
                </div>

                <div className="form-group email-field">
                  <label htmlFor="toggleSwitch">Report</label>
                  <div className="input-group switch-container">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="toggleSwitch"
                        checked={emailData.isEnabled}
                        onChange={(e) =>
                          setEmailData((prev) => ({
                            ...prev,
                            isEnabled: e.target.checked,
                          }))
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footers">
            <div className="button-group-email">
              <div>
                <button
                  className="person-user-save-button"
                  type="button"
                  onClick={handleSubmit}
                  disabled={isSending}
                >
                  {isSending ? "Sending email..." : "Send Email"}
                </button>

                <button className="person-user-cancel-button" onClick={onClose}>
                  Cancel
                </button>
              </div>
          
            </div>
          </div>
        </div>
      </div>
        <Snackbar
             open={snackbarOpen}
             autoHideDuration={6000} 
             onClose={handleSnackbarClose}
             anchorOrigin={{ vertical: "top", horizontal: "right" }}
             style={{ marginTop: "35px", marginLeft: "20px" }}
           >
             <MuiAlert onClose={handleSnackbarClose} severity={severity}>
               {snackbarMessage}
             </MuiAlert>
           </Snackbar>
    </div>
  );
};

export default EmailConfiguration;
