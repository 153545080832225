import React from "react";
import { useParams } from "react-router-dom";

const HotWorkTemplate4 = ({ isSidebarExpanded }) => {
  const { formId } = useParams();
  return (
    <>
        <div className="hot-template-container">
          <div className="template-container-content">
            <div className="template-container-header">
              <div className="hot-work-table">
                <table className="cold-work-tables">
                  <thead>
                    <tr>
                      <th className="permit-center-align permit-1">क्रम सं <br /> Sr.no</th>
                      <th className="permit-center-align permit-2">मद <br />Item</th>
                      <th className="permit-center-align permit-3">किया गया <br /> Done</th>
                      <th className="permit-center-align permit-4">
                        अपेक्षित नहीं <br /> Not Reqd.
                      </th>
                      <th className="permit-center-align permit-1">क्रम सं <br /> Sr.no</th>
                      <th className="permit-center-align permit-2">मद <br/>Item</th>
                      <th className="permit-center-align permit-3">किया गया <br /> Done</th>
                      <th className="permit-center-align permit-4">अपेक्षित नहीं <br /> Not Reqd.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="permit-center-align">1</td>
                      <td>
                        उपकरण/कार्य क्षेत्र की जाँच की गई
                        <br /> Equipment/work area inspected
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">10</td>
                      <td><span className="span-table">गैस टेस्ट एच सी टोक्सिक इत्यादि / Gas test:HCs/Toxic etc </span> <br/> एच सी /HCS..... = .....% एलईएल/LEL <br/>
                      टोक्सिक गैस/Toxic gases=...... % पीपीएम/ppm   </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>

                    <tr>
                      <td className="permit-center-align">2</td>
                      <td>
                        आसपास के क्षेत्र की जाँच, सफाई करली गई है और <br /> उसे ढक दिया
                        गया है। <br /> Surrounding area checked cleaned and covered
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td colspan="2" className="permit-center-align">
                        मचान निर्माण । ऊंचाई पर कार्य <br />
                        Working at height/Scaffolding Erection
                      </td>
                      {/* <td></td> */}
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="permit-center-align">3</td>
                      <td>
                        उपकरण अवरूध वियोजित / बंद / पृथक खोता <br /> गया/Equipment
                        blinded/connected/ <br /> closed/isolated/wedge opened
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">1</td>
                      <td>
                        मचान टैग प्रदान किया गया। <br /> Scaffolding Tag
                        Provided
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>

                    <tr>
                      <td className="permit-center-align">4</td>
                      <td>
                        उपकरण को उचित रूप से निस्तारिक और <br /> आवसादित किया गया। <br />
                        Equipment properly drained and <br /> depressurized
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">2</td>
                      <td>
                        ठकेदार पर्यवेक्षक और रखरखाव पर्यवेक्षक द्वारा <br />
                        हस्ताक्षरित मचान टैग / Scaffolding Tag signed <br /> by
                        contractor, supervisor, and <br /> maintenance supervisor.
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>

                    <tr>
                      <td className="permit-center-align">5</td>
                      <td>
                        उपकरण विधुत्त रूप से पृथक और अनुमती पत्र <br /> कृमांक के
                        द्वारा टैग कर दिया गया है / Equipment <br /> Electrically
                        isolated and tagged vide permit <br /> no. ............
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">3</td>
                      <td>
                        शॉक एब्जॉर्बर के साथ सुरक्षा बेल्ट का प्रयोग किया है।{" "}
                        <br /> /Full body harness with shock absorber <br />{" "}
                        used.
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>
                    <tr>
                      <td className="permit-center-align">6</td>
                      <td>
                        उपकरण को पानी से फ्लश किया गया <br /> Equipment water flushed
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">4</td>
                      <td>
                        मचान क्षेत्र की घेराबन्दी की गयी। <br /> Scaffolding
                        area barricaded
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>
                    <tr>
                      <td className="permit-center-align">7</td>
                      <td>
                        उपकरण को उचित रूप से भाप द्वारा पर्ज किया <br /> किया गया है/
                        Equipment properly <br /> steamed/purged
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">5</td>
                      <td>
                        IS/EN द्वारा प्रमाणित सुरक्षा बेल्ट का प्रयोग किया गया।{" "}
                        <br /> IS/EN certified safety harness used.
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>
                    <tr>
                      <td className="permit-center-align">8</td>
                      <td>
                        उचित वातानुकूलन एवं प्रकाश प्रदान किया गया <br /> /Proper
                        ventilation and lighting provided
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">6</td>
                      <td>
                        सुरक्षा बेल्ट और लाइफलाइन को बांधने के लिए मजबूत <br />
                        स्ट्रक्चर का उपयोग किया है यह सुनिश्चित किया गया <br /> Safety
                        belt life line anchoring to the rigid <br /> structure to be
                        ensured
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>
                    <tr>
                      <td className="permit-center-align">9</td>
                      <td>
                        क्षेत्र की घेराबंदी कर दी गई है एवं टैग दिए है। चेतावनी <br />
                        बोर्ड टैग दे दिए है। <br /> Area cordoned off & caution
                        boards/Tags <br /> provided
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className="permit-center-align">7</td>
                      <td>
                        सुरक्षित रास्ता की अनुपस्थिति में ऊंचाई पर मुवमेंट के <br />
                        लिए वर्टीकल हॉरिजॉन्टल लाइफलाइन का उपयोग <br /> सुनिश्चित किया
                        गया। <br /> In the absence of fixed means of access, <br />
                        provisions of lifelines with fall arresting for <br /> both
                        vertical and horizontal movement is <br /> to be ensured.
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
   
    </>
  );
};

export default HotWorkTemplate4;
