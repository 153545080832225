import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import MuiAlert from '@mui/material/Alert';
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { BsInfoCircle } from "react-icons/bs";

import { useNavigate } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from '@mui/material/Tooltip';
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import Pagination from "../Component/Pagination";

function Customer({ isSidebarExpanded, customerID }) {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);

  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const renderTooltip = (location) => (
    <div>
      <p>
        <strong>Contact Person:</strong> {location.contact_person_name || "NA"}
      </p>
      <p>
        <strong>Contact Number:</strong> {location.contact_no || "NA"}
      </p>
      <p>
        <strong>Email:</strong> {location.email || "NA"}
      </p>
      <p>
        <strong>Address:</strong> {location.address || "NA"}
      </p>
    </div>
  );


  const containerRef = useRef(null);

const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //     });

  //     setCustomers(response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching customers data:", error.message);
  //     setLoading(false);
  //   }
  // };



  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_archived_customers`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setCustomers(response.data.Customers);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
      setLoading(false);
    }
  };

  

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);







  // const handleSearch = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/`, {
  //       headers: {
  //         Authorization: authKey,
  //         "Content-Type": "application/json",
  //       },
  //       params: {
  //         query: searchInput,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setCustomers(response.data);
  //     } else {
  //       console.error("Error fetching search results:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching search results:", error.message);
  //   }
  // };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_customers`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setCustomers(response.data.Customers);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };




  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     handleSearch();
  //   }, 600);
  //   return () => clearTimeout(delay);
  // }, [searchInput]);


  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? customers.map((customer) => customer.ID) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (customerID) => {
    const updatedSelectedRows = selectedRows.includes(customerID)
      ? selectedRows.filter((id) => id !== customerID)
      : [...selectedRows, customerID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === customers.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveCustomer = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/unarchive_customers`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar('Data Restored successfully');
        const updatedCustomers = customers.filter(
          (customer) => !selectedRows.includes(customer.id)
        );
        setCustomers(updatedCustomers);
        // fetchData()
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete customers");
      }
    } catch (error) {
      console.error("An error occurred while deleting customers:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

 

  const handleArchiveDeparment = () => {
    navigate('/archive-department');

  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // const filteredUsers = departments.filter((user) => {
  //   return (
  //     user.Name.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Designation.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.ContactNo.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Department.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  // });

  // const sortedUsers = () => {
  //   if (sortColumn) {
  //     return filteredUsers.slice().sort((a, b) => {
  //       const first = a[sortColumn].toUpperCase();
  //       const second = b[sortColumn].toUpperCase();

  //       if (first < second) {
  //         return sortOrder === "asc" ? -1 : 1;
  //       }
  //       if (first > second) {
  //         return sortOrder === "asc" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return filteredUsers;
  // };
  // const handleSortOrderToggle = () => {
  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  // };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleBackCustomer = () => {
    navigate("/customer");
  };
  return (
    <>
      <div className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                    <button onClick={handleArchiveCustomer} className="delete">Unarchive</button>
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                  <Tooltip title="Back" arrow>
                    {" "}
                    <IoMdArrowBack
                      className="back-company"
                      onClick={handleBackCustomer}
                    />{" "}
                  </Tooltip>
                  Archived Customers
                  </div>
                  <div className="data-list-heading-content-right-side">
                  

                    <Tooltip title="Refresh List" arrow>
                      <button className="refresh-button" onClick={handleRefresh}>
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by" >SORT BY {isAscending ? '(Ascending)' : '(Descending)'}</div>
                          <div className="sort-by-button">
                      
                        </div>
                       
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : customers.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>CUSTOMER NAME</th>
                          <th>ContactNo</th>
                          <th>Address</th>
                         <th>Location</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                      {customers.map((customer, index) => (
                          <tr key={customer.ID} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(customer.id)}
                                onChange={() => handleSelectRow(customer.id)}
                              />
                            </td>
        
                            <td>{customer.serial_no}</td>
                            <td>{customer.customer_name}</td>
                            <td>{customer.contact_no}</td>
                            <td>{customer.address}</td>
                            <td>
                              {customer.locations.map((location, index) => (
                                <React.Fragment key={index}>
                                  {location.location}
                                  <Tooltip title={renderTooltip(location)}>
                                    <span>
                                      <BsInfoCircle className="hover-icon" />
                                    </span>
                                  </Tooltip>
                                  {index !== customer.locations.length - 1 && ', '}
                                </React.Fragment>
                              ))}
                            </td>
                          </tr>

                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Archived Customer Found</div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        )}
        <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
      
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: '35px', marginLeft: '20px' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Customer;
