import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import PaginationView from "../Component/PaginationView";

function UserView({ statutoryID, isSidebarExpanded, onClose }) {
  const [users, setUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState(null);

  const handleBackView = () => {
    onClose();
  };
  const handleClose = () => {
    onClose();
  };
  const handleEdit = () => {
    navigate(`/edit-statutory-compliance/${statutoryID}`);
  };

  //   const togglePopup = (userID) => {
  //     setUserData([userID]);
  //   };

  const togglePopup = async (statutoryID) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_statutory_compliance/${statutoryID}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      setUserData(response.data);
      setSelectedUserID(statutoryID);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    fetchData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDeletePopup(false);
      setIsDeletePopupOpen(false);
      setIsSortPopup(false);
      setSelectedRowIndex(null);
    }
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_statutory_compliances`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? users.map((user) => user.ID) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (statutoryID) => {
    const updatedSelectedRows = selectedRows.includes(statutoryID)
      ? selectedRows.filter((id) => id !== statutoryID)
      : [...selectedRows, statutoryID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === users.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/delete_users`;

      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedUsers = users.filter(
          (user) => !selectedRows.includes(user.ID)
        );
        setUsers(updatedUsers);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete users");
      }
    } catch (error) {
      console.error("An error occurred while deleting users:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const filteredUsers = users.filter((user) => {
    return user.department_name
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const sortedUsers = () => {
    if (sortColumn) {
      return filteredUsers.slice().sort((a, b) => {
        const first = a[sortColumn].toUpperCase();
        const second = b[sortColumn].toUpperCase();

        if (first < second) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (first > second) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredUsers;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_statutory_compliance/${statutoryID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    if (statutoryID) {
      fetchUserData();
    }
  }, [statutoryID]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  return (
    <>
      <div
        className={`main-container page-content${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div
          className={`data-lists-content-container page-contents ${
            isSidebarExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleConfirmDelete}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Compliance List
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <Link to="/userform" className="data-list-new">
                      <Tooltip title="New User" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" />
                        </button>
                      </Tooltip>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body">
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          Sr.no
                        </th>
                        <th>NAME</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {sortedUsers().map((user, index) => (
                        <tr key={user.ID} className="hoverable-row">
                          <td>
                            {index+1}
                          </td>

                          <td
                            onClick={() => togglePopup(user.id)}
                            className={
                              selectedUserID === user.id ? "selected-user" : ""
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {user.department_name || "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-view-popup">
          <div className="data-popup-content">
            <div className="data-view">
              <div className="data-view-contant">
                <div className="data-view-header">
                  <div className="data-view-header-left-side">
                    {userData && (
                      <div className="user-header-title">
                        <div>
                          <Tooltip title="Back" arrow>
                            <IoMdArrowBack
                              className="back-company"
                              onClick={handleBackView}
                            />
                          </Tooltip>
                          {userData.department_name || "NA"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="user-view-header-right-side">
                    <div className="header-title-edit">
                      <button
                        className="edit-header-button"
                        onClick={handleEdit}
                      >
                        <MdOutlineModeEditOutline className="edit-view-icon" />
                        Edit
                      </button>
                      <div className="view-close-button" onClick={handleClose}>
                        <IoCloseOutline />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="user-view-body">
                  <div className="rows">
                    {userData && (
                      <div className="user-view-body-container">
                        <div className="user-view-body-container-content">
                          <div className="text-medium-header">
                            Statutory Compliance Details
                          </div>
                          <div className="text-medium-body">
                            <div className="row">
                              <label htmlFor="">Department</label>
                              <div className="answer">
                                {userData.department_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Equipment Type</label>
                              <div className="answer">
                                {userData.equipment_type || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Tag No</label>
                              <div className="answer">
                                {userData.tag_no || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Date</label>
                              <div className="answer">
                                {formatDate(userData.date || "NA")}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Status</label>
                              <div className="answer">
                                {userData.status || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Statutory Description</label>
                              <div className="answer">
                                {userData.description || "NA"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UserView;
