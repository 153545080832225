import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const HotWorkTemplate6 = ({ signatures, formData }) => {
  return (
    <>
      <div className="hot-template-container">
        <div className="template-container-content">
          <div className="template-container-header">
            <div className="cold-work-table">
              <table className="cold-work-tables">
                <thead>
                  <tr>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6 "
                    >
                      जारीकर्ता / Issuer
                    </th>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6"
                    >
                      प्राप्तकर्ता / Receiver
                    </th>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6"
                    >
                      सुरक्षा आधिकारी / Safety Officer
                    </th>
                  </tr>
                  <tr>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br />
                      Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                      नाम <br /> Name{" "}
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className="permit-row">
                    <td className="permit-center-align">{signatures.name || "NA"}</td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">{signatures.name || "NA"}</td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                  </tr> */}

                  <tr className="permit-row">
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                  </tr>
                  <tr className="permit-row">
                    <th colSpan='6' className="permit-center-align">Approved</th>
                  </tr>
                  <tr>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th></th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                    <th></th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br />
                      Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                    
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotWorkTemplate6;
