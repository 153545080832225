import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import "../Responsive/TableResponsive.css";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { MdAttachFile } from "react-icons/md";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
import DatePicker from "react-datepicker";
function EditBreakdown({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const fileInputRef = useRef(null);
  const [breakdowntData, setBreakdowntData] = useState({
    permit_no: "",
    department_name: "",
    problem_desc: "",
    tag_no: "",
    date: null,
  });
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const permitFileInputRef = useRef(null);
  const [permitAttachmentFile, setPermitAttachmentFile] = useState([]);
  const [permitSelectedFiles, setPermitSelectedFiles] = useState([]);
  const [isPermitPopupOpen, setIsPermitPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const { theme } = useContext(ThemeContext);

  // Fetch Breakdown Data
  useEffect(() => {
    const fetchBreakdownData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_breakdown_schedule/${ID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;

        console.log(responseData); // Log the full response

        setBreakdowntData({
          department_name: responseData.department_name || "",
          problem_desc: responseData.problem_desc || "",
          tag_no: responseData.tag_no || "",
          permit_no: responseData.permit_no || "",
        });
        setSelectedDate(responseData.date ? new Date(responseData.date) : null);
        setSelectedOption({
          value: responseData.equipment_id,
          label: responseData.tag_no,
        });
        setSelectedDepartment({
          value: responseData.department_id,
          label: responseData.department_name,
        });

        // Set attachments
        setAttachments(responseData.attachments || []);
      } catch (error) {
        console.error("Error fetching breakdown data:", error.message);
        setError("An error occurred while fetching breakdown data");
      }
    };
    fetchBreakdownData();
  }, [ID]);

  // Fetch Departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_departments`, {
          headers: {
            Authorization: authKey,
          },
        });
        const departmentsData = response.data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(departmentsData);
      } catch (error) {
        console.error("An error occurred while fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleIconClick = () => {
    setIsPopupOpen(true);
    setIsPermitPopupOpen(false);
  };
  const handleDeletePermitAttach = (indexToDelete) => {
    console.log("Before delete:", permitSelectedFiles); // Debugging

    // Filter out the file at the given index
    const updatedFiles = permitSelectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setPermitSelectedFiles(updatedFiles);

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleClosePermitAttachment();
    }

    console.log("After delete:", updatedFiles); // Debugging
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_equipment`, {
          headers: {
            Authorization: authKey,
          },
        });
        const tagsData = response.data.Items.map((item) => ({
          value: item.TagNo,
          label: item.TagNo,
        }));
        setOptions(tagsData);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    fetchTags();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBreakdowntData({ ...breakdowntData, [name]: value });
  };

  // const handleSaveClick = async () => {
  //   setIsLoading(true); // Start loading
  //   const formData = new FormData();

  //   // Append fields to FormData
  //   formData.append("equipment_id", selectedOption?.value || "");
  //   const formattedDate = selectedDate.toLocaleDateString('en-CA'); // 'en-CA' format is YYYY-MM-DD
  //   formData.append("date", formattedDate);

  //   formData.append("department_id", selectedDepartment?.value || "");
  //   formData.append("department_name", selectedDepartment?.label || "");
  //   formData.append("tag_no", selectedOption?.label || "");
  //   formData.append("problem_desc", breakdowntData.problem_desc);
  //   formData.append("permit_no", breakdowntData.permit_no);

  //   // Ensure attachmentFile is an array before calling forEach
  //   if (Array.isArray(attachmentFile)) {
  //     attachmentFile.forEach((file) => {
  //       formData.append("attachments", file);
  //     });
  //   }

  //   if (Array.isArray(permitAttachmentFile)) {
  //     permitAttachmentFile.forEach((file) => {
  //       formData.append("permit_attachments", file);
  //     });
  //   }

  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.put(
  //       `${API_URL}/api/update_breakdown_schedule/${ID}`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data", // Required for FormData
  //         },
  //       }
  //     );

  //     showSnackbar(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Data updated successfully");
  //       setTimeout(() => {
  //         navigate("/breakdown-schedules");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving breakdown data:", error);
  //     showSnackbar("An error occurred while saving the breakdown data");
  //     setSeverity("error");
  //   } finally {
  //     setIsLoading(false); // Stop loading
  //   }
  // };

  const handleSaveClick = async () => {
    setIsLoading(true); // Start loading
    const formData = new FormData();
    const validationErrors = [];

    // Validation checks
    if (!selectedOption?.value) {
      validationErrors.push("Equipment is required.");
    }

    if (!selectedDate) {
      validationErrors.push("Date is required.");
    }

    if (!selectedDepartment.department_name) {
      validationErrors.department_name = "Department is required.";
    }
    

    if (
      !breakdowntData.problem_desc ||
      breakdowntData.problem_desc.trim() === ""
    ) {
      validationErrors.push("Problem description is required.");
    }

    if (!breakdowntData.permit_no || breakdowntData.permit_no.trim() === "") {
      validationErrors.push("Permit number is required.");
    }

    // File validation (optional)
    if (!attachmentFile || attachmentFile.length === 0) {
      validationErrors.push("At least one attachment file is required.");
    }

    // Show all validation errors
    if (validationErrors.length > 0) {
      showSnackbar(validationErrors.join(" "), "error");
      setIsLoading(false);
      return;
    }

    // Append fields to FormData
    formData.append("equipment_id", selectedOption?.value);
    const formattedDate = selectedDate.toLocaleDateString("en-CA"); // 'en-CA' format is YYYY-MM-DD
    formData.append("date", formattedDate);
    formData.append("department_id", selectedDepartment?.value);
    formData.append("department_name", selectedDepartment?.label);
    formData.append("tag_no", selectedOption?.label);
    formData.append("problem_desc", breakdowntData.problem_desc);
    formData.append("permit_no", breakdowntData.permit_no);

    // Append files to FormData
    if (Array.isArray(attachmentFile)) {
      attachmentFile.forEach((file) => {
        formData.append("attachments", file);
      });
    }

    if (Array.isArray(permitAttachmentFile)) {
      permitAttachmentFile.forEach((file) => {
        formData.append("permit_attachments", file);
      });
    }

    // Make API call
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_breakdown_schedule/${ID}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Data updated successfully", "success");
        setTimeout(() => {
          navigate("/breakdown-schedules");
        }, 1000);
      } else {
        showSnackbar("Failed to update breakdown data.", "error");
      }
    } catch (error) {
      console.error("Error saving breakdown data:", error);
      showSnackbar(
        "An error occurred while saving the breakdown data.",
        "error"
      );
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleBackProject = () => {
    navigate("/breakdown-schedules");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setBreakdowntData((prevState) => ({
      ...prevState,
      date: date,
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCancelClick = () => {
    // navigate("/breakdown-schedules");
    window.history.back();
  };
  // const handleUploadButtonClick = () => {
  //   fileInputRef.current.click();
  // };
  // const handleFileInputChange = (e) => {
  //   const files = e.target.files;
  //   const newFileDetails = Array.from(files).map((file) => ({
  //     name: file.name,
  //     size: file.size,
  //   }));

  //   // Append selected files to the state
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...newFileDetails]);
  //   setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);
  // };

  const handleUploadButtonClick = (isPermit = false) => {
    if (isPermit) {
      permitFileInputRef.current.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e, isPermit = false) => {
    const files = Array.from(e.target.files);
    const newFileDetails = files.map((file) => ({
      name: file.name,
      size: file.size,
    }));

    if (isPermit) {
      setPermitSelectedFiles((prev) => [...prev, ...newFileDetails]);

      // Check if prevFiles is an array before spreading
      setPermitAttachmentFile((prevFiles) =>
        Array.isArray(prevFiles) ? [...prevFiles, ...files] : [...files]
      );
    } else {
      setSelectedFiles((prev) => [...prev, ...newFileDetails]);

      // Check if prevFiles is an array before spreading
      setAttachmentFile((prevFiles) =>
        Array.isArray(prevFiles) ? [...prevFiles, ...files] : [...files]
      );
    }
  };

  const handlePermitIconClick = () => {
    setIsPermitPopupOpen(true);
    setIsPopupOpen(false);
  };
  const handleClosePermitAttachment = () => {
    setIsPermitPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Breakdown
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={setSelectedDepartment}
                    placeholder="Select Department"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="tag_no">
                    Tag Selection<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="tag_no"
                    value={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    placeholder="Select a tag"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {/* {error.department_name && (
                    <div
                      className="error-message"
                      style={{ color: "red", marginTop: "5px" }}
                    >
                      {error.department_name}
                    </div>
                  )} */}
                </div>
                <div className="form-group">
                  <label htmlFor="date">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="permitNo">
                    Permit No <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="permitNo"
                      name="permit_no"
                      className="form-control"
                      value={breakdowntData.permit_no}
                      onChange={handleInputChange}
                      placeholder="Enter permit no"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="PermitAttachment">
                    Permit Attachment <span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={() => handleUploadButtonClick(true)}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="PermitAttachment"
                    type="file"
                    ref={permitFileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileInputChange(e, true)}
                    multiple
                  />
                  {permitSelectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handlePermitIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${permitSelectedFiles.length}`}
                    </span>
                  )}

                  {isPermitPopupOpen && (
                    <div className="attach-popup-amcs breakdown-first-popup">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleClosePermitAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>

                        {/* Render attachments if there are files */}
                        {permitSelectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {permitSelectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size: {(file.size / 1024).toFixed(2)}{" "}
                                      KB
                                    </div>
                                  </div>

                                  {/* Delete button for each file */}
                                  <div
                                    className="file-delete-btn"
                                    onClick={() =>
                                      handleDeletePermitAttach(index)
                                    }
                                  >
                                    <span>
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="problem_desc">
                    Problem Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="problem_desc"
                      rows={4}
                      value={breakdowntData.problem_desc}
                      onChange={handleInputChange}
                      placeholder="Description"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Attachment">
                    Breakdown Attachment <span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={() => handleUploadButtonClick(false)}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="Attachment"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileInputChange(e, false)}
                    multiple
                  />

                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}

                  {isPopupOpen && (
                    <div className="attach-popup-amcss breakdown-second-popup">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="tag_no"></label>
                  {attachments.length > 0 && (
                    <div className="attachments-list">
                      <div className="input-groups">
                        {attachments.map((attachment) => (
                          <div key={attachment.file_id}>
                            <a
                              href={`${API_URL}/${attachment.file_path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {attachment.filename} (Size:{" "}
                              {attachment.file_size} KB)
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditBreakdown;
