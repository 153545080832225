import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import MuiAlert from '@mui/material/Alert';
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from '@mui/material/Tooltip';
import API_URL from "../../src/Config";
import useClickOutside from '../useClickOutside';

function EquipmentTypevariant({ isSidebarExpanded, equipmentSubTypeVariantID }) {
  const [equipmentSubTypeVariants, setEquipmentSubTypeVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const buttonRef = useRef(null)


  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...equipmentSubTypeVariants].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setEquipmentSubTypeVariants(sortedschedules);
  };

  useEffect(() => {
    fetchData();
  }, []);


  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));


  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };


  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_sub_type_variants`, {
        headers: {
          Authorization: authKey,
        },
      });

      setEquipmentSubTypeVariants(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? equipmentSubTypeVariants.map((equipmentSubTypeVariant) => equipmentSubTypeVariant.id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (equipmentSubTypeVariantID) => {
    const updatedSelectedRows = selectedRows.includes(equipmentSubTypeVariantID)
      ? selectedRows.filter((id) => id !== equipmentSubTypeVariantID)
      : [...selectedRows, equipmentSubTypeVariantID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === equipmentSubTypeVariants.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSearch = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_sub_type_variants`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
        },
      });

      if (response.status === 200) {
        setEquipmentSubTypeVariants(response.data);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleSearch();
    }, 600);
    return () => clearTimeout(delay);
  }, [searchInput]);


  const handleArchiveSubVariant = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_sub_type_variant`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ sub_type_variant_ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar('Data archived successfully');
        const updatedSubVariants = equipmentSubTypeVariants.filter(
          (equipmentSubTypeVariant) => !selectedRows.includes(equipmentSubTypeVariant.ID)
        );
        fetchData();
        setEquipmentSubTypeVariants(updatedSubVariants);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete sub variant");
      }
    } catch (error) {
      console.error("An error occurred while deleting sub variant:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

 

  const handleArchiveUser = () => {
    navigate('/archive-equipment-Sub-type-variant');

  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

 

 
  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleRefresh = () => {
    fetchData();
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  return (
    <>
      <div className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
    
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                    <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveSubVariant}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Sub Types Variants
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/equipment-sub-type-variant-form" className="data-list-new">
                      <Tooltip title="New Sub Type Variant" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button className="refresh-button" onClick={handleRefresh}>
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by" onClick={handleSortOrderToggle}>SORT BY {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}</div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("DepartmentName")}>
                            Department{" "}
                              {sortOrder.DepartmentName === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("SubDepartmentName")}>
                            Sub Department{" "}
                              {sortOrder.SubDepartmentName === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("EquipmentType")}>
                            Equipment Type{" "}
                              {sortOrder.EquipmentType === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("EquipmentSubType")}>
                            Equipment Sub Type{" "}
                              {sortOrder.EquipmentSubType === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("VariantName")}>
                            Equipment Type Variant{" "}
                              {sortOrder.VariantName === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("ApplicableOISD")}>
                            Applicable OISD{" "}
                              {sortOrder.ApplicableOISD === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Subsection")}>
                            Subsection{" "}
                              {sortOrder.Subsection === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                           
                          </div>
                          <button className='delete-item' onClick={handleArchiveUser}>Archived Sub Type Variant</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : equipmentSubTypeVariants.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment">SR. NO.</th>
                          <th>DEPARTMENT</th>
                          <th>SUB DEPARTMENT</th>
                          <th>EQUIPMENT TYPE</th>
                          <th>EQUIPMENT SUB TYPE</th>
                          <th>EQUIPMENT SUB TYPE VARIANT</th>
                          <th>APPLICABLE OISD</th>
                          <th>SUBSECTION</th>
                          <th>

                          </th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                     
                          {equipmentSubTypeVariants.map((equipmentSubTypeVariant, index) => (
                          <tr key={equipmentSubTypeVariant.ID} className="hoverable-row">
                            <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(equipmentSubTypeVariant.id)}
                                onChange={() => handleSelectRow(equipmentSubTypeVariant.id)}
                              />
                            </td>
                            <td className="center-alignment">{index + 1}</td>
                            <td>{equipmentSubTypeVariant.DepartmentName || "NA"}</td>
                            <td>{equipmentSubTypeVariant.SubDepartmentName || "NA"}</td>
                            <td>{equipmentSubTypeVariant.EquipmentType || "NA"}</td>
                            <td>{equipmentSubTypeVariant.EquipmentSubType || "NA"}</td>
                            <td>{equipmentSubTypeVariant.VariantName || "NA"}</td>                 
                            <td>{equipmentSubTypeVariant.ApplicableOISD || "NA"}</td>
                            <td>{equipmentSubTypeVariant.Subsection || "NA"}</td>
                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() =>
                                  handleButtonClick(index)
                                }
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-equipment-sub-type-variant/${equipmentSubTypeVariant.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>

                              </div>
                            )}


                          </tr>

                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Sub Types Variants Found</div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
       
        
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: '35px', marginLeft: '20px' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EquipmentTypevariant;
