import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import API_URL from "../../src/Config";
import "./DailyProgressEmail.css";
import Select from "react-select";

const DailyProgressEmail = ({ closePopup, dailyId }) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isSending, setIsSending] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const popupRef = useRef(null);
  const [emailData, setEmailData] = useState({
    to_emails: "",
    subject: "",
    body: "",
    dailyId: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    to_email: "",
    subject: "",
    body: "",
    from_email: "",
  });

  useEffect(() => {
    console.log("Received dailyId in popup:", dailyId);
  }, [dailyId]);

  const handleEmailChange = (selectedOption) => {
    setSelectedEmail(selectedOption);
    
    // Clear the error message when a valid email is selected
    if (selectedOption) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        from_email: "", // Clear the error for the 'from_email' field
      }));
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Clear the error for the corresponding field once the user starts typing
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : prevErrors[name],
    }));
  
    setEmailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchEmailsTemplate = async (moduleName) => {
    if (!moduleName) {
      console.error("Module name is required!");
      return;
    }
  
    try {
      const authKey = localStorage.getItem("authKey");
      const encodedModuleName = encodeURIComponent(moduleName);
      const apiUrl = `${API_URL}/api/get_module_template_details/${encodedModuleName}`;
  
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const emailTemplate = data.records[0]; // Get the first template, if any
  
        setEmailData({
          to_emails: emailTemplate.to_emails || '',
          subject: emailTemplate.subject || '',
          body: emailTemplate.body || '',
        });
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };
  
  
  // Use the encoded module name
  useEffect(() => {
    fetchEmailsTemplate("Daily Progress Report");
  }, []);
  
  const fetchEmails = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_configs`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const options = data.configs.map((email) => {
          const label =
            email.is_default === 1 ? `${email.email} (default)` : email.email;
          return {
            label: label, // Append (default) to the email if it is the default
            value: email.id,
          };
        });

        setEmails(options);
      } else {
        console.error("Failed to fetch email configs");
      }
    } catch (error) {
      console.error("An error occurred while fetching email configs:", error);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!selectedEmail) {
      errors.from_email = "Email is required";
    }
    if (!emailData.to_email) {
      errors.to_email = "Email is required";
    }
    if (!emailData.subject) {
      errors.subject = "Subject is required";
    }
    if (!emailData.body) {
      errors.body = "Body is required";
    }

    setErrorMessages(errors);

    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  // const handleSubmit = async () => {
  //   if (!validateForm()) {
  //     return; // Don't submit if there are validation errors
  //   }

  //   setIsSending(true); // Show "Sending email..." message
  //   setShowSuccessMessage(false); // Hide success message initially
  //   setErrorMessage(""); // Clear any previous error messages

  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const payload = {
  //       config_id: selectedEmail.value,
  //       daily_work_id: dailyId,
  //       subject: emailData.subject,
  //       to_email: emailData.to_emails,
  //       body: emailData.body,
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/api/daily_work/send_email`,
  //       payload,
  //       {
  //         headers: { Authorization: authKey },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSuccessMessage(response.data.message); // Show dynamic success message
  //       setShowSuccessMessage(true);
  //     } else {
  //       setErrorMessage(`Error: ${response.data.message}`);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       setErrorMessage(error.response.data.message); // Display error message from API
  //     } else {
  //       setErrorMessage("Failed to send email. Please try again."); // General fallback error
  //     }
  //     console.error("Error sending email:", error);
  //   } finally {
  //     setIsSending(false); // Hide "Sending email..." message
  //   }
  // };
  const handleSubmit = async () => {
    setIsSending(true); // Show "Sending email..." message
    setShowSuccessMessage(false); // Hide success message initially
    //setErrorMessage(""); // Clear any previous error messages
  
    // Log the form data and payload before making the API call
    console.log("Submitting email with the following data:", {
      fromEmail: selectedEmail,
      toEmails: emailData.to_emails, // This will be a string of emails
      subject: emailData.subject,
      body: emailData.body,
      dailyWorkId: dailyId,
    });
  
    try {
      const authKey = localStorage.getItem("authKey");
  
      // Convert the to_emails string to an array by splitting on commas
      const toEmailsArray = emailData.to_emails.split(',').map(email => email.trim());
  
      const payload = {
        config_id: selectedEmail.value,
        daily_work_id: dailyId,
        subject: emailData.subject,
        to_email: toEmailsArray, // Send the array of emails
        body: emailData.body,
      };
  
      // Log the payload to ensure it looks correct
      console.log("Payload being sent:", payload);
  
      const response = await axios.post(
        `${API_URL}/api/daily_work/send_email`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );
  
      if (response.status === 200) {
        setSuccessMessage(response.data.message); // Show dynamic success message
        setShowSuccessMessage(true);
      } else {
        setErrorMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message); // Display error message from API
      } else {
        setErrorMessage("Failed to send email. Please try again."); // General fallback error
      }
      console.error("Error sending email:", error);
    } finally {
      setIsSending(false); // Hide "Sending email..." message
    }
  };
  

  return (
    <div className="reminder-popup-model" ref={popupRef}>
      <div className="reminder-popup-content">
        <div className="reminder-popup-model-header">
          <div className="reminder-popup-model-content-header">
            <div className="reminder-popup-model-content-header-left-part">
              Send Email
            </div>
            <div className="reminder-popup-model-content-header-right-part"></div>
          </div>
          <div className="popup-new-form">
            <form className="popup-form-detail">
              <div className="new-data-form">
                {isSending && (
                  <div className="sending-message">
                    <div>Please wait, sending email...</div>
                  </div>
                )}
                {showSuccessMessage && (
                  <div className="success-message">
                    <div>{successMessage}</div>
                  </div>
                )}

                {errorMessage && (
                  <div className="error-message-email">
                    <div>{errorMessage}</div>
                  </div>
                )}
                <div className="form-group-select email-filed">
                  <label htmlFor="department_name">
                    From<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={emails}
                    value={selectedEmail}
                    onChange={handleEmailChange}
                    placeholder="Select email"
                    isSearchable
                  />
                  {errorMessages.from_email && (
                    <div className="error-message">
                      {errorMessages.from_email}
                    </div>
                  )}
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="to_emails">
                    To<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      id="to_emails"
                      name="to_emails"
                      value={emailData.to_emails}
                      onChange={handleInputChange}
                      placeholder="Enter recipient email"
                    />
                  </div>
               
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="subject">
                    Subject<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      id="subject"
                      name="subject"
                      value={emailData.subject}
                      onChange={handleInputChange}
                      placeholder="Enter subject"
                    />
                  </div>
                  {errorMessages.subject && (
                    <div className="error-message">{errorMessages.subject}</div>
                  )}
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="body">
                    Body<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      id="body"
                      name="body"
                      value={emailData.body}
                      onChange={handleInputChange}
                      placeholder="Enter email body"
                    />
                  </div>
                  {errorMessages.body && (
                    <div className="error-message">{errorMessages.body}</div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footers">
            <div className="button-group-email">
            <div>
              <button
                className="person-user-save-button"
                type="button"
                onClick={() => {
                  console.log("Send Email button clicked");  // Log when the button is clicked
                  handleSubmit();  // Call the handleSubmit function
                }}
                disabled={isSending}
              
              >
                {isSending ? "Sending email..." : "Send Email"}
              </button>
            
              <button
                className="person-user-cancel-button"
                onClick={closePopup}
              >
                Cancel
              </button>
              </div>
              <div>
              {showSuccessMessage && (
                <div className="success-message">
                  <button className="ok-button" onClick={closePopup}>
                    OK
                  </button>
                </div>
              )}

              {errorMessage && (
                <div className="error-message">
                  <button className="ok-button" onClick={closePopup}>
                    OK
                  </button>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyProgressEmail;
