import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import PaginationView from "../Component/PaginationView";

function AmcView({isSidebarExpanded }) {
  const [users, setUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const { amcID } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handleBackView = () => {
    navigate("/amc-configuration")
  };
  const handleClose = () => {
    navigate("/amc-configuration")
  };
  const handleEdit = () => {
    if (selectedUserID) {
      navigate(`/edit-amc/${selectedUserID}`);
  } else {
      navigate(`/edit-amc/${amcID}`);
  }
  };

  //   const togglePopup = (userID) => {
  //     setUserData([userID]);
  //   };

  const togglePopup = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_amc/${amcID}`, {
        headers: {
          Authorization: authKey,
        },
      });
      setCustomerData(response.data.Items);
      setSelectedUserID(amcID);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    togglePopup();
}, []);


const onPageChange = (newPage) => {
  setCurrentPage(newPage);
};

  const containerRef = useRef(null);

  useEffect(() => {
    fetchData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDeletePopup(false);
      setIsDeletePopupOpen(false);
      setIsSortPopup(false);
      setSelectedRowIndex(null);
    }
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  // const fetchData = async () => {
  //     try {

  //         const authKey = localStorage.getItem("authKey");

  //         const response = await axios.get(`${API_URL}/api/get_customers`, {
  //             headers: {
  //                 Authorization: authKey,
  //             },
  //         });

  //         setUsers(response.data);

  //     } catch (error) {
  //         console.error("Error fetching user data:", error.message);

  //     }
  // };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_amcs`, {
        headers: {
          Authorization: authKey,
        },
        params: {
                page: currentPage,
                per_page: itemsPerPage,
         },
      });

      setUsers(response.data.Items);
      setSelectedUserID(amcID);
      //   setTotalItems(response.data.TotalItems);
    } catch (error) {
      console.error("Error fetching amc schedules data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? users.map((user) => user.ID) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (customerID) => {
    const updatedSelectedRows = selectedRows.includes(customerID)
      ? selectedRows.filter((id) => id !== customerID)
      : [...selectedRows, customerID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === users.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };



  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const filteredUsers = users.filter((user) => {
    return user.amc_name.toLowerCase().includes(searchInput.toLowerCase());
    //   user.Designation.toLowerCase().includes(searchInput.toLowerCase()) ||
    //   user.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
    //   user.ContactNo.toLowerCase().includes(searchInput.toLowerCase()) ||
    //   user.Department.toLowerCase().includes(searchInput.toLowerCase())
  });

  const sortedUsers = () => {
    if (sortColumn) {
      return filteredUsers.slice().sort((a, b) => {
        const first = a[sortColumn].toUpperCase();
        const second = b[sortColumn].toUpperCase();

        if (first < second) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (first > second) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredUsers;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_amc/${amcID}`, {
          headers: {
            Authorization: authKey,
          },
        });

        // Set customerData directly since the response is an object
        setCustomerData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    if (amcID) {
      fetchUserData();
    }
  }, [amcID]);

  return (
    <>
      <div
        className={`main-container page-content${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div
          className={`data-lists-content-container page-contents ${
            isSidebarExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="data-list-content" ref={containerRef}>
          
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Amcs
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <Link to="/userform" className="data-list-new">
                      <Tooltip title="New User" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" />
                        </button>
                      </Tooltip>
                    </Link>
                  </div>
                </div>
              </div>
         
            <div className="data-list-body">
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          Sr.No
                        </th>
                        <th>DEPARTMENT NAME</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                          {users.map((user, index) => (
                        <tr key={user.ID} className="hoverable-row">
                          <td>
                            {user.serial_no}
                          </td>

                          <td
                            onClick={() => fetchData(user.id)}
                            className={
                              selectedUserID === user.id ? "selected-user" : ""
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {user.department_name || "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <PaginationView
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage} // Pass the current page
                    onPageChange={(newPage) => {
                      console.log("Changing page to:", newPage); // Debugging
                      setCurrentPage(newPage); // Update current page
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-view-popup">
          <div className="data-popup-content">
            <div className="data-view">
              <div className="data-view-contant">
                <div className="data-view-header">
                  <div className="data-view-header-left-side">
                    {customerData && (
                      <div className="user-header-title">
                        <div>
                          <Tooltip title="Back" arrow>
                            {" "}
                            <IoMdArrowBack
                              className="back-company"
                              onClick={handleBackView}
                            />{" "}
                          </Tooltip>
                          {customerData.department_name || "NA"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="user-view-header-right-side">
                    <div className="header-title-edit">
                      <button
                        className="edit-header-button"
                        onClick={handleEdit}
                      >
                        <MdOutlineModeEditOutline className="edit-view-icon" />
                        Edit
                      </button>
                      <div className="view-close-button" onClick={handleClose}>
                        <IoCloseOutline />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="user-view-body">
                  <div className="rows">
                    {customerData && (
                      <div className="user-view-body-container">
                        <div className="user-view-body-container-content">
                          <div className="text-medium-header">Amc Details</div>
                          <div className="text-medium-body">
                            <div className="row">
                              <label htmlFor="">Department Name</label>
                              <div className="answer">
                                {customerData.department_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Name</label>
                              <div className="answer">
                                {customerData.amc_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Service Provider</label>
                              <div className="answer">
                                {customerData.service_provider_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">User</label>
                              <div className="answer">
                                {customerData.user_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Status</label>
                              <div className="answer">
                                {customerData.amc_status || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Start Date</label>
                              <div className="answer">
                                {customerData.start_date || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">End date</label>
                              <div className="answer">
                                {customerData.end_date || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Schedule Start Date</label>
                              <div className="answer">
                                {customerData.schedule_start_date || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Service Provider</label>
                              <div className="answer">
                                {customerData.service_provider_name || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">PO Number</label>
                              <div className="answer">
                                {customerData.po_no || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">PO Date</label>
                              <div className="answer">
                                {customerData.po_date || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Days Before</label>
                              <div className="answer">
                                {customerData.days_before || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Reminder Frequency</label>
                              <div className="answer">
                                {customerData.reminder_frequency || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">No. Of Preventive Visits</label>
                              <div className="answer">
                                {customerData.preventive_no || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">No. Of Breakdown Visits</label>
                              <div className="answer">
                                {customerData.breakdown_no || "NA"}
                              </div>
                            </div>
                            <div className="row">
                              <label htmlFor="">Service Description</label>
                              <div className="answer">
                                {customerData.services || "NA"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AmcView;
