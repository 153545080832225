// src/components/Dashboard.js
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import API_URL from "../../src/Config";
import { Doughnut } from "react-chartjs-2";
import Select from "react-select";
import "./Dashboard.css"; // Import the CSS file
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import "../Responsive/DashboardResponsive.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ThemeContext } from "../../src/ThemeContext";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";
// Register the necessary components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = ({ isSidebarExpanded }) => {
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { theme } = useContext(ThemeContext);
  const isDarkTheme = theme === "dark";
  const [loading, setLoading] = useState(true);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isNoDataAvailable, setIsNoDataAvailable] = useState(false);

  const [preventiveData, setPreventiveData] = useState({
    completed: 0,
    scheduled: 0,
  });
  const [amcData, setAMCData] = useState({
    completed: 0,
    scheduled: 0,
  });
  const [inventoryData, setInventoryData] = useState({
    inward: 0,
    outward: 0,
  });

  const [breakdownData, setBreakdownData] = useState({
    totalEntries: 0,
  });
  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Tomorrow", value: "tomorrow" },
    { label: "Next 7 Days", value: "next7days" },
    { label: "Next Month", value: "next_month" },
    { label: "Current Week", value: "current_week" },
    { label: "Previous Month", value: "previous_month" },
    { label: "Previous Week", value: "past_week" },
    { label: "Current Month", value: "current_month" },
    { label: "Custom Date", value: "custom_date" },
  ];

  const [selectedFilter, setSelectedFilter] = useState(
    filterOptions.find((option) => option.value === "today")
  );

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
    // fetchChartData(selectedOption);
    // fetchTableData(selectedOption);
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(date)
      .toLocaleDateString("en-CA", options)
      .replace(/-/g, "/");
  };
  // const fetchChartData = async (
  //   filterOption,
  //   start_date = null,
  //   end_date = null
  // ) => {
  //   const authKey = localStorage.getItem("authKey");

  //   // Build parameters for the API request
  //   const params = {
  //     filter_option: filterOption.value,
  //   };

  //   if (filterOption.value === "custom_date" && start_date && end_date) {
  //     params.start_date = start_date.toISOString().split("T")[0]; // Format to yyyy-MM-dd
  //     params.end_date = end_date.toISOString().split("T")[0];
  //   }

  //   try {
  //     const response = await axios.get(
  //       `${API_URL}/api/todays_due_preventive_schedules`,
  //       {
  //         headers: { Authorization: authKey },
  //         params: params,
  //       }
  //     );

  //     const reportData = response.data.report || {};

  //     // Safely set chart data
  //     setChartData({
  //       active: reportData.active || 0,
  //       cancelled: reportData.cancelled || 0,
  //       completed: reportData.completed || 0,
  //       hold: reportData.hold || 0,
  //       in_progress: reportData.in_progress || 0,
  //       not_in_tenure: reportData.not_in_tenure || 0,
  //       overdue: reportData.overdue || 0,
  //     });
  //   } catch (err) {
  //     console.error("Error fetching chart data:", err);
  //     setError("Failed to fetch chart data.");
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     selectedFilter.value === "custom_date" &&
  //     selectedStartDate &&
  //     selectedEndDate
  //   ) {
  //     fetchChartData(selectedFilter, selectedStartDate, selectedEndDate);
  //   } else {
  //     fetchChartData(selectedFilter);
  //   }
  // }, [selectedFilter, selectedStartDate, selectedEndDate]);

  // const fetchChartData = async (
  //   filter_option,
  //   start_date = null,
  //   end_date = null
  // ) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     // Build parameters for the API request
  //     const params = {};

  //     if (filter_option.value === "custom_date" && start_date && end_date) {
  //       // Format start_date and end_date as yyyy-MM-dd
  //       params.start_date = formatDate(start_date);
  //       params.end_date = formatDate(end_date);
  //     } else {
  //       // Use the value of filter_option for non-custom_date filters
  //       params.filter_option = filter_option.value;
  //     }

  //     const response = await axios.get(
  //       `${API_URL}/api/todays_due_preventive_schedules`,
  //       {
  //         headers: { Authorization: authKey },
  //         params: params,
  //       }
  //     );

  //     const reportData = response.data.report || {};

  //     // Safely set chart data
  //     setChartData({
  //       active: reportData.active || 0,
  //       cancelled: reportData.cancelled || 0,
  //       completed: reportData.completed || 0,
  //       hold: reportData.hold || 0,
  //       in_progress: reportData.in_progress || 0,
  //       not_in_tenure: reportData.not_in_tenure || 0,
  //       overdue: reportData.overdue || 0,
  //     });
  //   } catch (err) {
  //     console.error("Error fetching chart data:", err);
  //     setError("Failed to fetch chart data.");
  //   }
  // };
  const fetchChartData = async (
    filter_option,
    start_date = null,
    end_date = null
  ) => {
    try {
      const authKey = localStorage.getItem("authKey");
  
      // Build parameters for the API request
      const params = {};
  
      if (filter_option.value === "custom_date" && start_date && end_date) {
        // Format start_date and end_date as yyyy-MM-dd
        params.start_date = formatDate(start_date);
        params.end_date = formatDate(end_date);
      } else {
        // Use the value of filter_option for non-custom_date filters
        params.filter_option = filter_option.value;
      }
  
      const response = await axios.get(
        `${API_URL}/api/todays_due_preventive_schedules`,
        {
          headers: { Authorization: authKey },
          params: params,
        }
      );
  
      const reportData = response.data.report || {};
  
      // Check if all data is null or 0
      const isNoDataAvailable =
        !reportData.active &&
        !reportData.cancelled &&
        !reportData.completed &&
        !reportData.hold &&
        !reportData.in_progress &&
        !reportData.not_in_tenure &&
        !reportData.overdue &&
        reportData.total_schedules === 0;
  
      // Set the state based on the fetched data
      setChartData({
        active: reportData.active || 0,
        cancelled: reportData.cancelled || 0,
        completed: reportData.completed || 0,
        hold: reportData.hold || 0,
        in_progress: reportData.in_progress || 0,
        not_in_tenure: reportData.not_in_tenure || 0,
        overdue: reportData.overdue || 0,
        total_schedules: reportData.total_schedules || 0,
      });
  
      // Set the no data flag
      setIsNoDataAvailable(isNoDataAvailable);
  
    } catch (err) {
      console.error("Error fetching chart data:", err);
      setError("Failed to fetch chart data.");
    }
  };
  
  useEffect(() => {
    if (
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      fetchChartData(selectedFilter, selectedStartDate, selectedEndDate);
    } else {
      fetchChartData(selectedFilter);
    }
  }, [selectedFilter, selectedStartDate, selectedEndDate]);

  const fetchTableData = async (
    filter_option,
    start_date = null,
    end_date = null
  ) => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Build parameters for the API request
      const params = {};

      if (filter_option.value === "custom_date" && start_date && end_date) {
        // Format start_date and end_date as yyyy-MM-dd
        params.start_date = formatDate(start_date);
        params.end_date = formatDate(end_date);
      } else {
        params.filter_option = filter_option.value;
      }

      const response = await axios.get(`${API_URL}/api/schedule_report`, {
        headers: { Authorization: authKey },
        params: params, // Pass parameters in the URL query
      });

      // Ensure that tableData is an array, and if not, set it as an empty array
      setTableData(
        Array.isArray(response.data.report) ? response.data.report : []
      );
    } catch (err) {
      setError(err); // Handle errors
    }
  };

  useEffect(() => {
    if (
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      fetchTableData(selectedFilter, selectedStartDate, selectedEndDate);
    } else {
      fetchTableData(selectedFilter);
    }
  }, [selectedFilter, selectedStartDate, selectedEndDate]);

  // const fetchReportData = async (filterOption) => {
  //   const authKey = localStorage.getItem("authKey");

  //   const params = {
  //     filter_option: filterOption.value, // Use the selected filter option
  //   };

  //   try {
  //     const response = await axios.get(`${API_URL}/api/kpi/summary/reports`, {
  //       headers: { Authorization: authKey }, // Correct placement of headers
  //       params: params, // Pass parameters in the URL query
  //     });
  //     const reportData = response.data.report;

  //     // Set the extracted data to state
  //     setPreventiveData({
  //       completed: reportData.preventive_schedules?.completed_schedules || 0,
  //       scheduled: reportData.preventive_schedules?.scheduled || 0,
  //     });
  //     setAMCData({
  //       completed: reportData.amc_schedules?.completed_schedules || 0,
  //       scheduled: reportData.amc_schedules?.scheduled || 0,
  //     });
  //     setInventoryData({
  //       inward: reportData.inventory_data?.total_inwarded_qty || 0,
  //       outward: reportData.inventory_data?.total_outwarded_qty || 0,
  //     });

  //     setBreakdownData({
  //       totalEntries: reportData.breakdown_schedules?.total_entries || 0,
  //     });

  //     setLoading(false);
  //   } catch (err) {
  //     console.error("Error fetching report data:", err);
  //     setError("Failed to fetch data");
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchReportData(selectedFilter);
  // }, [selectedFilter]); // Dependency array includes filterOption if it changes

  const fetchReportData = async (
    filter_option,
    start_date = null,
    end_date = null
  ) => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Build parameters for the API request
      const params = {};

      if (filter_option.value === "custom_date" && start_date && end_date) {
        // Format start_date and end_date as yyyy-MM-dd
        params.start_date = formatDate(start_date);
        params.end_date = formatDate(end_date);
      } else {
        params.filter_option = filter_option.value;
      }

      const response = await axios.get(`${API_URL}/api/kpi/summary/reports`, {
        headers: { Authorization: authKey }, // Correct placement of headers
        params: params, // Pass parameters in the URL query
      });
      const reportData = response.data.report;

      // Set the extracted data to state
      setPreventiveData({
        completed: reportData.preventive_schedules?.completed_schedules || 0,
        scheduled: reportData.preventive_schedules?.scheduled || 0,
      });
      setAMCData({
        completed: reportData.amc_schedules?.completed_schedules || 0,
        scheduled: reportData.amc_schedules?.scheduled || 0,
      });
      setInventoryData({
        inward: reportData.inventory_data?.total_inwarded_qty || 0,
        outward: reportData.inventory_data?.total_outwarded_qty || 0,
      });

      setBreakdownData({
        totalEntries: reportData.breakdown_schedules?.total_entries || 0,
      });

      setLoading(false);
    } catch (err) {
      console.error("Error fetching report data:", err);
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      fetchReportData(selectedFilter, selectedStartDate, selectedEndDate);
    } else {
      fetchReportData(selectedFilter);
    }
  }, [selectedFilter, selectedStartDate, selectedEndDate]);

  const doughnutChartData = {
    labels: [
      "Active",
      "Cancelled",
      "Completed",
      "Hold",
      "In Progress",
      "Not in Tenure",
      "Overdue",
    ],
    datasets: [
      {
        data: [
          chartData?.active || 0,
          chartData?.cancelled || 0,
          chartData?.completed || 0,
          chartData?.hold || 0,
          chartData?.in_progress || 0,
          chartData?.not_in_tenure || 0,
          chartData?.overdue || 0,
        ],
        backgroundColor: [
          "#2980b9", // Active
          "#f81700", // Cancelled
          "#2e8b57", // Completed
          "#d4ac0d", // Hold
          "#f4a462", // In Progress
          "#313639", // Not in Tenure
          "#c0612b", // Overdue
        ],
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          font: {
            size: 14,
            weight: "bold",
          },
          padding: 20,
          color: isDarkTheme ? "#FFFFFF" : "#333",
        },
        onClick: (e, legendItem, legend) => {
          // Override the default behavior to prevent the toggling of the chart segment
          // Do nothing here to prevent hiding/showing the segments
        },
      },
      tooltip: {
        backgroundColor: isDarkTheme ? "#333" : "#f5f5f5",
        bodyColor: isDarkTheme ? "#FFFFFF" : "#000",
        borderColor: "#ddd",
        borderWidth: 1,
        titleColor: isDarkTheme ? "#FFFFFF" : "#000",
        padding: 10,
        zIndex: 99999,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    cutout: "60%",
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    elements: {
      arc: {
        borderRadius: 2,
        spacing: 0,
      },
    },
  };
  

  return (
    <div
      className={`dashboard  ${isSidebarExpanded ? "expanded" : "collapsed"}`}
    >
      <div className="data-form-content">
        <div className="data-form-header1">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">Dashboard</div>
            <div className="data-form-heading-content-right-side">
              <div className="dropdown-container">
                <Select
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  options={filterOptions}
                  // className="project-dropdown"
                />
              </div>
              {selectedFilter.value === "custom_date" && (
                <div className="input-group-datepickers">
                  <DatePicker
                    placeholderText="Select Date Range"
                    className="dates"
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    selectsRange={true}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setSelectedStartDate(start);
                      setSelectedEndDate(end);
                    }}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField {...startProps} variant="standard" />
                        <TextField {...endProps} variant="standard" />
                      </>
                    )}
                    startText="Start Date"
                    endText="End Date"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="dashboard-container">
          <div className="dashboard-container-content">
            <div className="dashboard-container-first-part">
              <div className="total-active-projects-box boxs">
                <div className="text">
                  <div className="heading-content">
                    <div className="heading-total">Preventive Schedules</div>
                  </div>

                  <div className="total-project">
                    <>
                      <div className="total-project-number">
                        {`${preventiveData.completed} / ${preventiveData.scheduled}`}
                      </div>
                      <div className="total-project-line">
                        Completed PM/ Scheduled PM
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="total-invoice-requests-box boxs">
                <div className="text">
                  <div className="heading-content">
                    <div className="heading-total">AMC Schedules</div>
                  </div>

                  <div className="total-project">
                    <>
                      <div className="total-project-number">
                        {`${amcData.completed} / ${amcData.scheduled}`}
                      </div>
                      <div className="total-project-line">
                        Completed AMC / Scheduled AMC
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="total-inquiry-box boxs">
                <div className="text">
                  <div className="heading-content">
                    <div className="heading-total">Inventory Inward</div>
                  </div>

                  <div className="total-project">
                    <>
                      <div className="total-project-number">
                        {inventoryData.inward}
                      </div>
                      <div className="total-project-line">
                        Total Inventory Inward
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div className="total-overdue-box boxs">
                <div className="text">
                  <div className="heading-content">
                    <div className="heading-total">Inventory Consumption</div>
                  </div>

                  <div className="total-project">
                    <>
                      <div className="total-project-number">
                        {inventoryData.outward}
                      </div>
                      <div className="total-project-line">
                        Total Inventory Consumption
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div className="total-overdue-box boxs">
                <div className="text">
                  <div className="heading-content">
                    <div className="heading-total">Total Breakdown </div>
                  </div>

                  <div className="total-project">
                    <>
                      <div className="total-project-number">
                        {breakdownData.totalEntries}
                      </div>
                      <div className="total-project-line">Total Breakdown</div>
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-container-second-part">
              <div className="chart-donut1">
                <div className="chart-donut-container">
                  <div className="chart-donut-heading">
                    <div>Preventive schedules count</div>
                    {/* <div className="heading-refresh"><button className='refresh-button-dashboard' onClick={handleDonutChartRefresh}><TbRefresh className='refresh-icons' /></button></div> */}
                  </div>
                  <div className="chart-donut-body">
                    <div className="chart-donut-body-content">
                      <div className="pie-content1"> Pie Chart </div>
                      <div className="pie-content2"></div>
                    </div>
                    <div
                      className="donut-chart"
                      style={{
                        width: "490px",
                        height: "424px",
                        marginLeft: "15px",
                      }}
                    >
                      {isNoDataAvailable ? (
                            <div className="no-data-message">No Data Available</div>
                          ) : (
                            <>
                      <Doughnut
                        data={doughnutChartData}
                        options={doughnutChartOptions}
                      />
                      <div
                        className="detail-chart"
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          fontWeight: "bold",
                        }}
                      >
                        {chartData ? chartData.total_schedules : 0} <br />{" "}
                        Schedules
                      </div>
                      </>
                      )}
                    </div>

                    {/* <div className="chart-footer">
                      <div className="footer-content1">
                        Trending up by 5.2% this month{" "}
                        <HiMiniArrowTrendingUp className="graph-arrow" />
                      </div>
                      <div className="footer-content2">
                        Showing total projects for the last 6 months
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div className="chart-donut2">
                <div className="chart-donut-container">
                  <div className="chart-donut-heading">
                    <div>Departments & Preventive Schedules Count</div>
                  </div>
                  <div className="dashboard-table">
                    <table>
                      <thead>
                        <tr>
                          <th>SERIAL NO</th>
                          <th>DEPARTMENTS</th>
                          <th>ACTIVE</th>
                          <th>COMPLETED</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.department_name || "NA"}</td>
                            <td className="active-status">
                              {item.active ? item.active : "0"}{" "}
                              {/* Show 0 if active is null */}
                            </td>
                            <td className="complet-status">
                              {item.completed ? item.completed : "0"}{" "}
                              {/* Show 0 if completed is null */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
