import React from "react";
import "./HotWorkPermitTemplate.css";
import HotWorkTemplate1 from "./HotWorkTemplate1";
import HotWorkTemplate2 from "./HotWorkTemplate2";
import HotWorkTemplate3 from "./HotWorkTemplate3";
import HotWorkTemplate4 from "./HotWorkTemplate4";
import HotWorkTemplate5 from "./HotWorkTemplate5";
import HotWorkTemplate6 from "./HotWorkTemplate6";
import HotWorkTemplate7 from "./HotWorkTemplate7";
import HotWorkTemplate8 from "./HotWorkTemplate8";
import HotWorkTemplate9 from "./HotWorkTemplate9";

const HotWorkPermitTemplate = ({isSidebarExpanded }) => {


  return (
    <div className="hot-work-template">
      <div id="section11">
       <HotWorkTemplate1/>
       <HotWorkTemplate2/>
       <HotWorkTemplate3/>
       <HotWorkTemplate4/>
       <HotWorkTemplate5/>
       <HotWorkTemplate6/>
       <HotWorkTemplate7/>
       <HotWorkTemplate8/>
       <HotWorkTemplate9/>
      </div>
    </div>
  );
};

export default HotWorkPermitTemplate;
