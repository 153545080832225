import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { format } from "date-fns";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Form4({ isSidebarExpanded }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { work_permit_id } = useParams();
  console.log("Fetched ID:", work_permit_id);
  const [formData, setFormData] = useState({
    extension_reason: "",
    work_permit_id: "",
  
  });

  useEffect(() => {
    if (work_permit_id) {
      setFormData((prevData) => ({
        ...prevData,
        work_permit_id, // Update work_permit_id in formData
      }));
    }
  }, [work_permit_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  // Restrict the time range from 5 AM to 10 PM
  const filterTime = (time) => {
    const hour = time.getHours();
    return hour >= 5 && hour <= 22;
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCancelClick = () => {
    navigate("/permit-requests?tab=Approved");
  };

  const handleSaveClick = async () => {
    const formattedValidFrom = startDate
      ? format(startDate, "yyyy-MM-dd HH:mm:ss")
      : "";
    const formattedValidTo = endDate
      ? format(endDate, "yyyy-MM-dd HH:mm:ss")
      : "";

    // Prepare payload
    const payload = {
      work_permit_id: formData.work_permit_id, // Use updated work_permit_id
      date_from: formattedValidFrom,
      date_to: formattedValidTo,
      extension_reason: formData.extension_reason, // Get from formData
    };

    console.log("Payload:", payload);

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/renew_permit`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Permit renewed successfully.");
        setTimeout(() => {
          navigate(`/permit-requests`);
        }, 1000);
      } else {
        throw new Error(
          response.data.message || "Failed to renew permit."
        );
      }
    } catch (error) {
      console.error("Error renewing permit:", error);
      setSeverity("error");
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while renewing the permit.";
      showSnackbar(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Clearance renewal
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                {/* <div className="form-group">
                  <label htmlFor="DepartmentName">Date</label>
                  <div className="input-group">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Select start date and time"
                      className="datepicker"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentName">Time</label>
                  <div className="input-group">
                    <DatePicker
                      selected={startTime}
                      onChange={(date) => setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15} // Set time intervals to 15 minutes
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={new Date().setHours(5, 0, 0, 0)} // 5 AM
                      maxTime={new Date().setHours(22, 0, 0, 0)} // 10 PM
                      filterTime={filterTime}
                      placeholderText="Select Time"
                    />
                    &nbsp;-&nbsp;
                    <DatePicker
                      selected={endTime}
                      onChange={(date) => setEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15} // Set time intervals to 15 minutes
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={new Date().setHours(5, 0, 0, 0)} // 5 AM
                      maxTime={new Date().setHours(22, 0, 0, 0)} // 10 PM
                      filterTime={filterTime}
                      placeholderText="Select Time"
                    />
                  </div>
                </div> */}
    <div className="form-group">
                  <label htmlFor="DepartmentName">Start Time & Date</label>
                  <div className="input-group">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                     dateFormat="dd/MM/yyyy hh:mm a"
                      placeholderText="Select start date and time"
                      className="datepicker"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentName">End Time & Date</label>
                  <div className="input-group">
                    <DatePicker
                      id="end-date"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      showTimeSelect
                       dateFormat="dd/MM/yyyy hh:mm a"
                      placeholderText="Select end date and time"
                      className="datepicker"
                      minDate={startDate} // Ensures the end date is after the start date
                    />
                  </div>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="">Extended up to ____ Hrs.</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="DepartmentName"
                      placeholder="Enter total hrs"
                    />
                  </div>
                </div> */}

                <div className="form-group">
                  <label htmlFor="extension_reason">
                    Extension Reason
                  </label>
                  <div className="input-group">
                    <textarea
                     name="extension_reason"
                      type="message"
                      rows={5}
                      cols={7}
                      value={formData.extension_reason}
                      onChange={handleInputChange}
                      className="textarea"
                      placeholder="Enter Extension Reason"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Form4;
