import React, { useState, useEffect } from "react";
// import "./Template2.css";
import { useParams } from "react-router-dom";

const HotWorkTemplate5 = ({ hazardsPrecautions, isSidebarExpanded }) => {
  const { formId } = useParams();
  return (
    <>
      <div className="hot-template-container">
        <div className="template-container-content1">
          <div className="template-container-pera">
            <div className="cold-remark">
              <div className="template-heading">टिप्पणियाँ / Remarks:</div>
              <div>
                1.क्रिया में निम्नलिखित संभावित जोखिम हो सकते है (संभावित
                मुद्दों को चिह्नित करें) ऑक्सीजन की कमी एवं ज्वलनशील पदार्थ,
                पायरोफोरिक आयर्न संक्षारक रसायन पाया, संभावित अन्य | The
                activity has the following expected residual hazards (Tick the
                relevant items): lack of Oxygen/H₂S, Toxic Gases, Combustible
                Cases/Pyrophoric Iron/Corrosive
                Chemicals/Steam-Condensable/others.
              </div>
              <div>
                2.पीपीई मानकों के अतिरिक्त वैधानिक संरक्षणात्मक उपकरण प्रयोग
                किया जाए (हेल्मेट, सुरक्षा: जूते, हाथों के दस्ताने, बायलेर सूट)
                फेस शील्ड / एप्रन/ डस्ट रेस्पिरेटर/ फ्रेश एयर मास्क/ लाइफलाइन /
                सेफ्टी बेल्ट / एयरलाइन/ इयर मफ इत्यादि | Following PPE to be
                used in addition to standards PPEs (Helmet, Safety Shoes, Hand
                gloves, Boiler suit) Face shield/Apron/ Goggles/Dust Respirator/
                Fresh Air mask/Life line/Safety belt (Full body
                harness)/Airline/Earmuff etc.
              </div>{" "}
              <div>3. अन्य कोई सावधानी, यदि कोई है। /Additional precaution, if any: 
                    ....................................................................................
</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotWorkTemplate5;
