import React, { useState, useEffect } from "react";
// import "./Template1.css";
// import "./Template4.css";

const HotWorkTemplate8 = (isSidebarExpanded) => {
  return (
    <>
  
        <div className="hot-template-container">
          <div className="template-container-content">
            <div className="template-container-header">
            <div className='template-heading'>क्लीयरेंस नवीकरण /Clearance renewal </div>
            <div className="cold-remarks">
           
           
            </div>
              <div className="cold-work-table">
                <table className="cold-work-table">
                  <thead>
                    <tr>
                      <th rowSpan="2" className='permit-template-clearance renewal-permit1' >
                        दिनांक <br /> Date
                      </th>
                      <th colspan="2" className='permit-template-clearance '>समय/Time </th>
                      <th className='permit-template-clearance renewal-permit01'>विस्तार <br/> /Extension</th>
                      <th rowSpan="2" className='permit-template-clearance renewal-permit01'>
                        अतिरिक्त <br/> सावधानिया यदि <br/> कोई, अन्याथा <br/> उल्लेख कुध नहीं / <br/>
                        Additional <br/> precautions if <br/> any, Otherwise <br/> mention "NIL"
                      </th>
                      <th rowSpan="2" className='permit-template-clearance renewal-permit2'>
                        जारीकर्ता का नाम, पद <br/>और हस्ताक्षर  <br/>Issuer Name, <br/>
                        Designation & <br/> Signature
                      </th>

                      <th rowSpan="2" className='permit-template-clearance renewal-permit2'>
                        प्राप्तकर्ता नाम एवं <br/> हस्ताक्षर <br/> Receivers Name, <br/> Designation & <br/> Signature
                      </th>
                      <th rowSpan="2"className='permit-template-clearance renewal-permit2' >
                        सुरक्षा आधिकारी <br/>  नाम और हस्ताक्षर <br/> Safety Officer <br/> Name & <br/>
                        Signature
                      </th>
                    </tr>
                    <tr>
                      <th className='permit-template-clearance renewal-permit1'>
                        से <br />
                        From
                      </th>
                      <th className='permit-template-clearance renewal-permit1'>
                        तक <br />
                        to
                      </th>
                      <th className='permit-template-clearance'>
                        तक बढ़ा दिया <br/> गया है। <br/> Extended up <br/> to
                        __Hrs.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="permit-row-end">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default HotWorkTemplate8;
