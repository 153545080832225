import React, { useState, useEffect, useRef } from "react";
import "./HRView.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

function HRView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poData, setPoData] = useState({ po_no: "" });
  const [rows, setRows] = useState([]);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [selectedFiles3, setSelectedFiles3] = useState([]);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [selectedFiles4, setSelectedFiles4] = useState([]);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [selectedFiles5, setSelectedFiles5] = useState([]);
  const [isPopupOpen6, setIsPopupOpen6] = useState(false);
  const [selectedFiles6, setSelectedFiles6] = useState([]);
  const [isPopupOpen7, setIsPopupOpen7] = useState(false);
  const [selectedFiles7, setSelectedFiles7] = useState([]);
  const [isPopupOpen8, setIsPopupOpen8] = useState(false);
  const [selectedFiles8, setSelectedFiles8] = useState([]);
  const [isPopupOpen9, setIsPopupOpen9] = useState(false);
  const [selectedFiles9, setSelectedFiles9] = useState([]);
  const [isPopupOpen10, setIsPopupOpen10] = useState(false);
  const [selectedFiles10, setSelectedFiles10] = useState([]);
  const [isPopupOpen11, setIsPopupOpen11] = useState(false);
  const [selectedFiles11, setSelectedFiles11] = useState([]);
  const [isPopupOpen12, setIsPopupOpen12] = useState(false);
  const [selectedFiles12, setSelectedFiles12] = useState([]);
  const [isPopupOpen13, setIsPopupOpen13] = useState(false);
  const [selectedFiles13, setSelectedFiles13] = useState([]);
  const [isPopupOpen14, setIsPopupOpen14] = useState(false);
  const [selectedFiles14, setSelectedFiles14] = useState([]);
  const [isPopupOpen15, setIsPopupOpen15] = useState(false);
  const [selectedFiles15, setSelectedFiles15] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(null);

  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleIconClick2 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen2(true);
    setIsPopupOpen(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment2 = () => {
    setIsPopupOpen2(false);
  };

  const handleIconClick3 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen3(true);
    setIsPopupOpen2(false);
    setIsPopupOpen(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment3 = () => {
    setIsPopupOpen3(false);
  };

  const handleIconClick4 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen4(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment4 = () => {
    setIsPopupOpen4(false);
  };

  const handleIconClick5 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen5(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment5 = () => {
    setIsPopupOpen5(false);
  };
  const handleIconClick6 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen6(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment6 = () => {
    setIsPopupOpen6(false);
  };

  const handleIconClick7 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen7(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment7 = () => {
    setIsPopupOpen7(false);
  };

  const handleIconClick8 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen8(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment8 = () => {
    setIsPopupOpen8(false);
  };
  const handleIconClick9 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen9(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment9 = () => {
    setIsPopupOpen9(false);
  };
  const handleIconClick10 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen10(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment10 = () => {
    setIsPopupOpen10(false);
  };
  const handleIconClick11 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen11(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment11 = () => {
    setIsPopupOpen11(false);
  };
  const handleIconClick12 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen12(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment12 = () => {
    setIsPopupOpen12(false);
  };
  const handleIconClick13 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen13(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen(false);
    setIsPopupOpen14(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment13 = () => {
    setIsPopupOpen13(false);
  };
  const handleIconClick14 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen14(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen(false);
    setIsPopupOpen15(false);
  };
  const handleCloseAttachment14 = () => {
    setIsPopupOpen14(false);
  };
  const handleIconClick15 = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen15(true);
    setIsPopupOpen2(false);
    setIsPopupOpen3(false);
    setIsPopupOpen4(false);
    setIsPopupOpen5(false);
    setIsPopupOpen6(false);
    setIsPopupOpen7(false);
    setIsPopupOpen8(false);
    setIsPopupOpen9(false);
    setIsPopupOpen10(false);
    setIsPopupOpen11(false);
    setIsPopupOpen12(false);
    setIsPopupOpen13(false);
    setIsPopupOpen14(false);
    setIsPopupOpen(false);
  };
  const handleCloseAttachment15 = () => {
    setIsPopupOpen15(false);
  };
  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_human_resources/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const files = response.data.files;

      // Set the state with filtered files based on file_type
      setSelectedFiles(
        files.filter((file) => file.file_type === "wage_register")
      );
      setSelectedFiles2(
        files.filter((file) => file.file_type === "loan_recoveries")
      );
      setSelectedFiles3(
        files.filter((file) => file.file_type === "attendance")
      );
      setSelectedFiles4(files.filter((file) => file.file_type === "overtime"));
      setSelectedFiles5(
        files.filter((file) => file.file_type === "bank_statement")
      );
      setSelectedFiles6(files.filter((file) => file.file_type === "bank_seal"));
      setSelectedFiles7(
        files.filter((file) => file.file_type === "wage_slip_copy")
      );
      setSelectedFiles8(
        files.filter((file) => file.file_type === "tax_receipt")
      );
      setSelectedFiles9(
        files.filter((file) => file.file_type === "bonus_register")
      );
      setSelectedFiles10(
        files.filter((file) => file.file_type === "leave_register")
      );
      setSelectedFiles11(
        files.filter((file) => file.file_type === "pf_payment_confirmation")
      );
      setSelectedFiles12(
        files.filter((file) => file.file_type === "pf_combined_challan")
      );
      setSelectedFiles13(files.filter((file) => file.file_type === "pf_ecr"));
      setSelectedFiles14(
        files.filter((file) => file.file_type === "esi_payment")
      );
      setSelectedFiles15(
        files.filter((file) => file.file_type === "esi_payment")
      );
    } catch (error) {
      console.error("Error fetching HR data:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleBackProject = () => {
    navigate("/hr-modules");
  };

  return (
    <div
      className={`data-form-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              <Tooltip title="Back" arrow>
                <IoMdArrowBack
                  className="back-company"
                  onClick={handleBackProject}
                />
              </Tooltip>
              HR Uploded Attachments
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          <div className="form-detail">
            <div className="new-data-form">
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="wage_register">
                  <span className="hr-srNO">1.</span>
                  Form B - Wage register duly certified by Engineer In-Charge
                  (EIC) with Emp No
                </label>
                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick}
                  style={{
                    backgroundColor: isPopupOpen ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles.length}`}
                </span>
                {isPopupOpen && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={() => setIsPopupOpen(false)}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename.length > 20
                                      ? `${file.filename.substring(0, 15)}...`
                                      : file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:{" "}
                                    {(file.file_size / 1024).toFixed(2)} KB
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group-attachmentss hr-view">
                <label htmlFor="loan_recoveries">
                  <span className="hr-srNO">2.</span>
                  Form C - Register of Loan Recoveries for the corresponding
                  month duly certified by EIC
                </label>
                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick2}
                  style={{
                    backgroundColor: isPopupOpen2 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles2.length}`}
                </span>
                {isPopupOpen2 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={() => setIsPopupOpen2(false)}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>

                      {selectedFiles2.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles2.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group-attachmentss hr-view">
                <label htmlFor="attendance">
                  <span className="hr-srNO">3.</span>
                  Form D - Attendance Register
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick3}
                  style={{
                    backgroundColor: isPopupOpen3 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles3.length}`}
                </span>

                {isPopupOpen3 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment3}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>

                      {/* Show 'No attachments found' if selectedFiles3 is empty */}
                      {selectedFiles3.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles3.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:{" "}
                                    {(file.file_size / 1024).toFixed(2)} KB
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group-attachmentss hr-view">
                <label htmlFor="overtime">
                  <span className="hr-srNO">4.</span>
                  IX - Overtime Register
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick4}
                  style={{
                    backgroundColor: isPopupOpen4 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles4.length}`}
                </span>

                {isPopupOpen4 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment4}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles4.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles4.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="bank_statement">
                  <span className="hr-srNO">5.</span>
                  Bank Statement in respect of disbursement of wages for the
                  corresponding month with Contractor
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick5}
                  style={{
                    backgroundColor: isPopupOpen5 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles5.length}`}
                </span>

                {isPopupOpen5 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment5}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>

                      {selectedFiles5.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles5.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="bank_seal">
                  <span className="hr-srNO">6.</span>
                  Bank seal and sign
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick6}
                  style={{
                    backgroundColor: isPopupOpen6 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles6.length}`}
                </span>

                {isPopupOpen6 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment6}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>

                      {selectedFiles6.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles6.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="wage_slip_copy">
                  <span className="hr-srNO">7.</span>
                  Form XIX - Copy of Wage Slip for the corresponding month with
                  seal and sign of Contractor
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick7}
                  style={{
                    backgroundColor: isPopupOpen7 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles7.length}`}
                </span>

                {isPopupOpen7 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment6}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles7.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles7.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="tax_receipt">
                  <span className="hr-srNO">8.</span>
                  Professional Tax receipt for the corresponding month
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick8}
                  style={{
                    backgroundColor: isPopupOpen8 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles8.length}`}
                </span>

                {isPopupOpen8 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment8}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles8.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles8.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="bonus_register">
                  <span className="hr-srNO">9.</span>
                  Form C - Bonus Register, to be paid on quarterly basis
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick9}
                  style={{
                    backgroundColor: isPopupOpen9 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles9.length}`}
                </span>

                {isPopupOpen9 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment9}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles9.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles9.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="leave_register">
                  <span className="hr-srNO">10.</span>
                  Form 14 - Leave with wages Register, to be paid on quarterly
                  basis
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick10}
                  style={{
                    backgroundColor: isPopupOpen10 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles10.length}`}
                </span>

                {isPopupOpen10 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment9}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles10.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles10.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="pf_payment_confirmation">
                  <span className="hr-srNO">11.</span>
                  PF Payment Confirmation Receipt for the corresponding month
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick11}
                  style={{
                    backgroundColor: isPopupOpen11 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles11.length}`}
                </span>

                {isPopupOpen11 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment11}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles11.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles11.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="pf_combined_challan">
                  <span className="hr-srNO">12.</span>
                  PF Combined Challan for the corresponding month
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick12}
                  style={{
                    backgroundColor: isPopupOpen12 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles12.length}`}
                </span>

                {isPopupOpen12 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment12}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles12.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles12.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="pf_ecr">
                  <span className="hr-srNO">13.</span>
                  PF ECR (electronic challan cum returns) for the corresponding
                  month
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick13}
                  style={{
                    backgroundColor: isPopupOpen13 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles13.length}`}
                </span>

                {isPopupOpen13 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment13}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles13.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles13.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="esi_payment">
                  <span className="hr-srNO">14.</span>
                  ESI Payment Confirmation Challan for the corresponding month
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick14}
                  style={{
                    backgroundColor: isPopupOpen14 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles14.length}`}
                </span>

                {isPopupOpen14 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment14}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>
                      {selectedFiles14.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles14.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group-attachmentss hr-view">
                <label htmlFor="esi_payment">
                  <span className="hr-srNO">15.</span>
                  ESI Contribution Statement for the corresponding month
                </label>

                <span
                  className="inquiry-file-btn"
                  onClick={handleIconClick15}
                  style={{
                    backgroundColor: isPopupOpen15 ? "green" : undefined,
                  }}
                >
                  <MdAttachFile className="inquiry-file-icon" />{" "}
                  {`${selectedFiles15.length}`}
                </span>

                {isPopupOpen15 && (
                  <div className="attach-popup-attachments">
                    <div className="attach-popup-contant">
                      <div className="attachment-header">
                        <div className="attachment-header-content">
                          Attachments
                        </div>
                        <button
                          className="fiile-close-btn"
                          onClick={handleCloseAttachment15}
                        >
                          <IoCloseOutline className="file-close-icon" />
                        </button>
                      </div>

                      {selectedFiles15.length === 0 ? (
                        <div className="no-attachments-message">
                          No attachments found
                        </div>
                      ) : (
                        <div className="attach-scrroll-body">
                          {selectedFiles15.map((file, index) => (
                            <div
                              className="attach-popup-contant-body"
                              key={index}
                            >
                              <div className="attach-hr-file-container">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {file.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size:(
                                    {(file.file_size / 1024).toFixed(2)} KB)
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HRView;
